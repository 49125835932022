import React from "react";
import { FaShareAlt } from 'react-icons/fa';
import { AiOutlineLink } from 'react-icons/ai';
import { BsTwitter } from 'react-icons/bs';
import { RiWhatsappFill } from 'react-icons/ri';
import { GrFormClose } from 'react-icons/gr';
import {
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const MyShareButton = ({ itemNumber }) => {
  const [ssmmVisible, setSsMmVisible] = useState(false);
  const [shareVisible, setShareVisible] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  

  const handleDisplay = (e) => {
    e.preventDefault();
    setShareVisible(false);
    setSsMmVisible(true);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setIsCopied(false);
    console.log("Estoy adentro del cerrar SSMM");
    setSsMmVisible(false);
    setShareVisible(true);
  }
  return (
    <div className="wraper">
        <div className="share-icon" onClick={(e) => handleDisplay(e)} style={{ visibility: ssmmVisible ? 'hidden' : 'visible' }}>
          <FaShareAlt></FaShareAlt>
        </div>
        <div className="socia-media gap-3" style={{ visibility: shareVisible ? 'hidden' : 'visible' }}>
          <div onClick={(e) => handleClose(e)} className="closeButton button">
            <GrFormClose />
          </div>

          <CopyToClipboard text={`https://fancymonas.io/items/${itemNumber}`}
            className="button"
            onCopy={() => setIsCopied(true)}
            style={( isCopied && { backgroundColor: 'black', borderRadius: '50%', color: 'white' })}
          >
            <AiOutlineLink />
          </CopyToClipboard>

          <TwitterShareButton
            className="button"
            title={`Fancy Mona #${itemNumber} by Edgardo Giménez`}
            url={`¡Hey! 👀🐒 Esta es mi #FancyMona #${itemNumber} 🙉 producida algorítmicamente por Edgardo Giménez: https://fancymonas.io/items/${itemNumber}`}
            
          >
            <BsTwitter />
          </TwitterShareButton>

          <WhatsappShareButton className="button" 
          url={`¡Hey! 👀🐒 Esta es mi #FancyMona #${itemNumber} 🙉 producida algorítmicamente por Edgardo Giménez: https://fancymonas.io/items/${itemNumber}`}
          >
            <RiWhatsappFill />
          </WhatsappShareButton>
        </div>
        </div>
        )
}


export default MyShareButton;
