import React from "react";

function CashIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 65 46"
    >
      <g fill={color}>
        <path d="M64.647 24.56c0 4.552.012 9.103-.004 13.654-.012 3.672-2.146 6.186-5.728 6.743-1.27.197-2.554.304-3.83.452-3.253.376-6.503.711-9.793.549-4.327-.212-8.521-1.075-12.64-2.399-3.478-1.118-6.985-2.086-10.642-2.428a41.98 41.98 0 00-8.96.136c-1.764.214-3.527.446-5.296.608C3.886 42.233.776 39.23.76 35.117.74 30.043.756 24.97.756 19.897c0-4.054-.014-8.108.004-12.162.016-3.63 2.158-6.156 5.696-6.693C8.142.787 9.847.652 11.539.44c3.609-.448 7.217-.596 10.848-.245 3.745.362 7.37 1.246 10.931 2.414 4.883 1.603 9.856 2.59 15.015 2.425 2.949-.094 5.868-.52 8.793-.865 4.237-.502 7.489 2.327 7.515 6.666.028 4.576.006 9.15.006 13.726zM5.024 21.419c0 4.62-.006 9.244.004 13.865.002 1.447 1.006 2.432 2.343 2.296 1.744-.177 3.487-.376 5.227-.582a46.102 46.102 0 0111.264.025c3.426.434 6.734 1.342 10 2.422 5.188 1.714 10.489 2.588 15.945 2.172 2.866-.217 5.722-.569 8.576-.918 1.396-.171 1.99-.987 1.996-2.52.039-9.056.075-18.109.112-27.164 0-.189.008-.38-.006-.57-.087-1.24-1.112-2.158-2.329-2.036-1.744.174-3.487.376-5.227.58-3.333.39-6.665.45-10.01.127-3.84-.37-7.542-1.332-11.189-2.543-5.254-1.743-10.623-2.625-16.152-2.196-2.818.218-5.627.57-8.436.907-1.493.179-2.118.954-2.12 2.481-.004 4.551 0 9.102 0 13.652l.002.002z"></path>
        <path d="M43.357 23.026c-.043 5.98-4.838 10.806-10.698 10.771-5.874-.035-10.637-4.923-10.596-10.876.038-5.93 4.888-10.75 10.77-10.702 5.788.047 10.567 4.952 10.524 10.805v.002zm-10.692 6.45c3.566.013 6.41-2.853 6.426-6.475.017-3.55-2.854-6.457-6.373-6.457-3.53 0-6.371 2.864-6.39 6.438-.02 3.565 2.828 6.482 6.34 6.494h-.003z"></path>
        <path d="M15.001 8.685c1.7-.045 2.698.806 2.78 2.042.076 1.165-.755 2.119-2.04 2.269-1.458.17-2.92.313-4.383.436-1.077.09-2.069-.748-2.235-1.832-.17-1.12.531-2.232 1.667-2.413 1.518-.243 3.055-.366 4.213-.5l-.002-.002z"></path>
        <path d="M50.442 37.303c-1.742.056-2.744-.787-2.817-2.056-.069-1.178.789-2.109 2.126-2.259 1.412-.16 2.827-.294 4.243-.423 1.075-.099 2.1.711 2.274 1.78.19 1.168-.503 2.282-1.673 2.47-1.495.241-3.008.36-4.153.49v-.002z"></path>
      </g>
    </svg>
  );
}

export default CashIcon;
