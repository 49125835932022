import React from "react";
import { useTranslation } from "react-i18next";

export default function CheckoutCheckboxes({ termsCheckbox, setTermsCheckbox }) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <div className="checkout-checkboxes d-flex flex-column">
        <div className="checkout-checkboxes-item d-flex flex-row align-items-center">
          <input className="checkbox" type="checkbox" id="checkbox-news" checked={ termsCheckbox } onChange={ () => setTermsCheckbox(!termsCheckbox) }/>
          <span className="checkmark d-flex flex-row justify-content-center align-items-center"></span>
          <label className="checkbox-label" htmlFor="checkbox">Acepto los términos y condiciones</label>
        </div>
      </div>
    </>
  )

}
