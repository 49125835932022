import ReactDOM from 'react-dom';

function ModalContainer({ children }) {

  return ReactDOM.createPortal(
    children,
    document.getElementById('modal')

  );
}

export default ModalContainer;