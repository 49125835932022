import ImageDetail from "../../components/fancy-checkout/ImageDetail";
import { Counter } from "../../components/fancy-checkout/Counter";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/Cart";
import { Tooltip } from "../../components/shared/Tooltip";
import ComercialForm from "../../components/comercial-checkout/ComercialForm";
import TotalDetail from "../../components/comercial-checkout/TotalDetail";
import HeadingHr from "../../components/shared/HeadingHr";
import RevealTemplate from "../../templates/RevealTemplate";
import HistoryIcon from "../../components/icons/HistoryIcon";
import routes from "../../routes/routes";
import LogOutIcon from "../../components/icons/LogOutIcon";
import { useNavigate } from "react-router-dom";
import ListIcon from "../../components/icons/ListIcon";
import ArrowTop from "../../components/icons/ArrowTop";
import User from "../../components/icons/User";
import CustomDropdown from "../../components/shared/Dropdown";
import "./ComercialCheckout.scss";
import ModalHistorySales from "../../components/modals/ModalHistorySales";
import { getHistorySales } from "../../services/axiosService";

const ComercialCheckout = () => {
  const [historySalesShowModal, setHistorySalesShowModal] = useState(false);
  const [historySales, setHistorySales] = useState({});

  useEffect(() => {
    const requestHistorySales = async () => {
      await getHistorySales()
        .then((response) => {
          setHistorySales(response.data);
        })
        .catch((error) => {});
    };
    requestHistorySales();
  }, []);

  const navigate = useNavigate();
  const {
    addItem,
    removeItem,
    count,
    addDisplay,
    removeDisplay,
    countDisplay,
    multipleDisplay,
    isPrinted,
    isMultiple,
    priceDisplay,
    user,
  } = useContext(CartContext);
  const [individualChecked, setIndividualChecked] = useState(false);
  const [multipleChecked, setMultipleChecked] = useState(false);
  const [newTotal, setNewTotal] = useState(0);
  const [generalTotal, setNewGeneralTotal] = useState(0);

  const handleCheckboxChange = (checkboxName) => {
    if (checkboxName === "individual") {
      setIndividualChecked(true);
      setMultipleChecked(false);
    } else if (checkboxName === "multiple") {
      multipleDisplay();
      setIndividualChecked(false);
      setMultipleChecked(true);
    }
  };

  useEffect(() => {
    switch (count) {
      case 1:
        setNewTotal(1500);
        break;
      case 2:
        setNewTotal(2800);
        break;
      case 3:
        setNewTotal(4200);
        break;
      case 4:
        setNewTotal(5200);
        break;
      case 5:
        setNewTotal(6500);
        break;
      case 6:
        setNewTotal(7800);
        break;
      case 7:
        setNewTotal(8400);
        break;
      case 8:
        setNewTotal(9000);
        break;
      case 9:
        setNewTotal(9600);
        break;
      case 10:
        setNewTotal(10000);
        break;
      default:
        break;
    }
  }, [count]);

  useEffect(() => {
    const calculateTotalPrice = newTotal + priceDisplay;
    setNewGeneralTotal(calculateTotalPrice);
  }, [newTotal, countDisplay]);

  const logout = () => {
    localStorage.removeItem("token");
    navigate(routes.sellers_login);
  };

  return (
    <div id="comercialCheckout">
      <ModalHistorySales
        modal={historySalesShowModal}
        setModal={setHistorySalesShowModal}
        title={"Historial de ventas"}
        subtitle={"Resumen histórico Fancy Monas vendidas"}
      >
        <div id="table">
          <div class="seller-header">
            <div>Vendedor</div>
          </div>
          <div class="client-header">
            <div>Cliente</div>
          </div>
          {historySales?.sales?.map((item, index) => {
            return (
              <>
                <div class="seller-col">{item.sellerEmail}</div>
                <div class="client-col">
                  <div class="primary-data">
                    <div class="name">{item.name ?? ''}</div>
                    <div class="email">{item.userEmail ?? ''}</div>
                    <div class="phone">{item.phone ?? ''}</div>
                    <div class="artworks">
                      {item.artworks?.map((fancymona, index) => {
                        return (
                          <div class="fancymona">
                            <img
                              src={fancymona.compressedImageUrl}
                              alt="mona"
                            />
                            <span>#{fancymona.number}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div class="secondary-data">

                    { item?.currency?.length > 0 && item?.signal?.length > 0
                      ? <div class="signal">{item.currency ?? ''} {item.signal ?? ''}</div>
                      : <div class="signal">-</div>
                    }
                    
                    <div class="payment_method">Metodo de pago: {item.paymentMethod}</div>

                    { item.additionalIndications.length > 0
                      ? <div class="comment">Comentario: {item.additionalIndications ?? ''}</div>
                      : <div class="comment">-</div>
                    }
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </ModalHistorySales>

      <RevealTemplate
        heading={
          <div className="heading-dropdown">
            <HeadingHr heading={"Cantidad de monas"} />
            <CustomDropdown
              text={user?.email ?? "Opciones"}
              startIcon={<User width={15} height={15} color={"#89CEF8"} />}
              endIcon={<ArrowTop width={20} height={20} color={"#000"} />}
              items={[
                {
                  name: (
                    <div className="d-flex align-items-center custom-dropdown-gap">
                      <ListIcon width={15} height={15} color={"#000"} /> Cola de
                      revelado
                    </div>
                  ),
                  click: () => navigate(routes.revelated_list),
                },
                {
                  name: (
                    <div className="d-flex align-items-center">
                      <HistoryIcon width={15} height={15} color={"#000"} />{" "}
                      Historial de ventas
                    </div>
                  ),
                  click: () => setHistorySalesShowModal(true),
                },
                {
                  name: (
                    <div className="d-flex align-items-center custom-dropdown-gap">
                      <LogOutIcon width={15} height={15} color={"#dc3545"} />{" "}
                      <span className="text-danger">Desconectar</span>
                    </div>
                  ),
                  click: logout,
                },
              ]}
            />
          </div>
        }
        footer={
          <>
            <TotalDetail
              qtyMona={count ?? ""}
              totalMona={newTotal ?? ""}
              qtyDisplay={countDisplay ?? ""}
              totalDisplay={priceDisplay ?? ""}
              total={generalTotal ?? ""}
            />
            <ComercialForm
              contextValues={{
                count,
                isPrinted,
                isMultiple,
                countDisplay,
              }}
            />
          </>
        }
      >
        <div className="selection-monas">
          <div className="selection-monas-container">
            <span className="title">Obra digital</span>
            <ImageDetail
              src={"/images/fancy_checkout/digital-art-checkout.svg"}
            />
            <Counter
              addItem={count < 10 ? addItem : null}
              removeItem={removeItem}
              count={count < 10 ? count : 10}
              disabledAdd={count >= 10}
              className="counter"
            />
          </div>
          <div className="selection-monas-container display-mona">
            <span className="title">Display visualizador</span>
            <ImageDetail src={"/images/fancy_checkout/display.png"} />
            <Counter
              addItem={addDisplay}
              removeItem={removeDisplay}
              count={countDisplay}
              className="counter"
            />
            <div className="content-counter">
              <label>
                <input
                  type="checkbox"
                  disabled={countDisplay <= 0}
                  checked={
                    (countDisplay >= 1 && !multipleChecked) || individualChecked
                  }
                  onChange={() => handleCheckboxChange("individual")}
                />{" "}
                INDIVIDUAL
              </label>
              {" | "}
              <label>
                <input
                  type="checkbox"
                  disabled={countDisplay <= 0}
                  checked={multipleChecked}
                  onChange={() => handleCheckboxChange("multiple")}
                />{" "}
                MÚLTIPLE
              </label>
              <Tooltip
                className="d-flex"
                placement="right"
                text="INDIVIDUAL: Una pantalla por Fancy Mona comprada. MÚLTIPLE: Una única pantalla con todas las Fancy Monas compradas."
              />
            </div>
          </div>
        </div>
      </RevealTemplate>
    </div>
  );
};

export default ComercialCheckout;
