import { Label } from "../shared/Label";
import { Input, InputErrorMessage, TextArea } from "../shared/Input";
import { ErrorMessage, Field, useFormikContext } from "formik";
import ModalTerms from "../modals/ModalTerms";
import { useState } from "react";
import ContentTermsConditions from "../comercial-checkout/ContentTerms&Conditions";

const PersonalInfo = ({ edit }) => {
  const [termsModal, setTermsModal] = useState(false);
  const { values, handleChange } = useFormikContext();
  return (
    <div className="row gy-3 gx-3 mt-3 form-width">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="p-1">
          <Label htmlFor="address" className="form-label">
            Dirección *
          </Label>
          <Input
            type="text"
            name="address"
            value={values?.address}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div className="p-1">
          <Label htmlFor="addressNumber" className="form-label">
            Piso *
          </Label>
          <Input
            type="text"
            name="addressNumber"
            value={values?.addressNumber}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <div className="p-1">
          <Label htmlFor="deptNumber" className="form-label">
            Depto *
          </Label>
          <Input
            type="text"
            name="deptNumber"
            value={values?.deptNumber}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div className="p-1">
          <Label htmlFor="neighborhood" className="form-label">
            Ciudad *
          </Label>
          <Input
            type="text"
            name="neighborhood"
            value={values?.neighborhood}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <div className="p-1">
          <Label htmlFor="state" className="form-label">
            Província *
          </Label>
          <Input
            type="text"
            name="state"
            value={values?.state}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-5 col-sm-5 col-md-5 col-lg-2 col-xl-2">
        <div className="p-1">
          <Label htmlFor="postalCode" className="form-label">
            CP *
          </Label>
          <Input
            type="text"
            name="postalCode"
            value={values?.postalCode}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-7 col-sm-7 col-md-7 col-lg-12 col-xl-12">
        <div className="p-1">
          <Label htmlFor="country" className="form-label">
            País *
          </Label>
          <Input
            type="text"
            name="country"
            value={values?.country}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="p-1">
          <Label htmlFor="additionalIndications" className="form-label">
            Comentarios adicionales
          </Label>
          <TextArea
            type="text"
            name="additionalIndications"
            value={values?.additionalIndications}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="p-1">
          <label>
            <Field type="checkbox" name="terms" /> Acepto los{" "}
            <strong
              onClick={() => setTermsModal(true)}
              className="text-decoration-underline"
            >
              términos y condiciones
            </strong>
          </label>
        </div>
        <InputErrorMessage>
          <ErrorMessage name="terms" />
        </InputErrorMessage>
      </div>
      <ModalTerms
        termsModal={termsModal}
        setTermsModal={setTermsModal}
        title={"Términos y condiciones de Fancy Monas"}
        subtitle={
          "Al finalizar la lectura acepta los términos y condiciones para poder continuar"
        }
        isClosable={() => setTermsModal(!termsModal)}
      >
        <ContentTermsConditions />
      </ModalTerms>
    </div>
  );
};

export default PersonalInfo;
