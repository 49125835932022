import { useState } from 'react';

const FilterDataCRUD = () => {

    const [checkboxValues, setCheckboxValues] = useState({
        'Fondo': [],
        'Pelaje': [],
        'Ojos': [],
        'Boca': [],
        'Accesorios': [],
        'Traje': [],
        'Sombrero': [],
        'order': []
    });

    const handleCheckboxChange = (event, group) => {
      const { type, value, checked } = event.target;
      setCheckboxValues((prevCheckboxValues) => {
        const updatedValues = { ...prevCheckboxValues };

        if (type === 'checkbox') {
          const groupValues = [...updatedValues[group]];
          if (checked) {
            groupValues.push(value);
          } else {
            const index = groupValues.indexOf(value);
            if (index !== -1) {
              groupValues.splice(index, 1);
            }
          }
          updatedValues[group] = groupValues;
        } else if (type === 'radio') {
          updatedValues[group] = [value]; // Solo un valor para los botones de radio
        }

        return updatedValues;
      });
    };

    const btnDeleteFilterHandle = (event) => {
        const group = event.target.getAttribute('data-group');
        const value = event.target.getAttribute('data-value');

        setCheckboxValues((prevCheckboxValues) => {
          const updatedValues = { ...prevCheckboxValues };
          const groupValues = [...updatedValues[group]];

          const index = groupValues.indexOf(value);
          if (index !== -1) {
            groupValues.splice(index, 1);
          }

          updatedValues[group] = groupValues;
          return updatedValues;
        });
      };


      const btnDeleteAllFiltersHandle = (event) => {
        setCheckboxValues((prevCheckboxValues) => {
          return {
            'Fondo': [],
            'Pelaje': [],
            'Ojos': [],
            'Boca': [],
            'Aros': [],
            'Traje': [],
            'Sombrero': [],
            'order': []
          };
        });
      };

    return {
        checkboxValues,
        handleCheckboxChange,
        btnDeleteFilterHandle,
        btnDeleteAllFiltersHandle
    }
}

export default FilterDataCRUD;