import React from "react";

function CheckNok({ width = "20", height = "20", color = "#890707" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5355 6.46424C13.145 6.07372 12.5118 6.07372 12.1213 6.46424L10 8.58557L7.87868 6.46424C7.48816 6.07372 6.85499 6.07372 6.46447 6.46424C6.07394 6.85477 6.07394 7.48793 6.46447 7.87846L8.58579 9.99978L6.46447 12.1211C6.07394 12.5116 6.07394 13.1448 6.46447 13.5353C6.85499 13.9258 7.48816 13.9258 7.87868 13.5353L10 11.414L12.1213 13.5353C12.5118 13.9258 13.145 13.9258 13.5355 13.5353C13.9261 13.1448 13.9261 12.5116 13.5355 12.1211L11.4142 9.99978L13.5355 7.87846C13.9261 7.48793 13.9261 6.85477 13.5355 6.46424Z"
        fill="white"
      />
    </svg>
  );
}

export default CheckNok;
