import React from "react";

const ImageDetail = ({ src }) => {
  return (
    <div className="imgCard">
      <div className="digital-art">
        <img
          src="/images/fancy_checkout/icon-digital-art.png"
          alt="digital-art"
          className="icon"
        />
        <img
          src="/images/fancy_checkout/name-digital-art.png"
          alt="digital-art"
          className="icon-name"
        />
      </div>
      <div className="author-name">
        <span>BY EDGARDO GIMENEZ</span>
      </div>
      <img className="image-display" src={src} alt="digital-art" />
    </div>
  );
};

export default ImageDetail;
