import React from "react";

function CartIcon(props) {
  const { fillColor = 'white', width=800, height=800 } = props;

  const iconStyle = {
    fill: 'none',
    stroke: fillColor,
    "stroke-linecap": 'round',
    "stroke-linejoin": 'round',
    "stroke-width": '2px',
  };

  return (
    <svg width={width} height={height} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <style>{`
          .cls-1 {
            ${Object.entries(iconStyle)
              .map(([prop, value]) => `${prop}:${value};`)
              .join('')}
          }
        `}</style>
      </defs>
      <g data-name="Layer 30" id="Layer_30">
        <path className="cls-1" d="M12.52,13.59,63,13.39l-7.1,28H20.23l-8.86-32H1.25"/>
        <circle className="cls-1" cx="23.83" cy="52.17" r="3.92"/>
        <circle className="cls-1" cx="53.1" cy="52.17" r="3.92"/>
        <line className="cls-1" x1="17.65" x2="58.29" y1="32.1" y2="32.1"/>
        <line className="cls-1" x1="15" x2="60.7" y1="22.56" y2="22.56"/>
        <line className="cls-1" x1="24.15" x2="24.15" y1="13.54" y2="41.41"/>
        <line className="cls-1" x1="15.83" x2="15.83" y1="13.54" y2="25.54"/>
        <line className="cls-1" x1="32.41" x2="32.41" y1="13.54" y2="41.41"/>
        <line className="cls-1" x1="40.66" x2="40.66" y1="13.54" y2="41.41"/>
        <line className="cls-1" x1="48.92" x2="48.92" y1="13.54" y2="41.41"/>
        <line className="cls-1" x1="57.18" x2="57.18" y1="13.54" y2="36.47"/>
        <path className="cls-1" d="M20.23,41.41V45.7a2.55,2.55,0,0,0,2.55,2.55H53.24"/>
      </g>
    </svg>
  );
}

export default CartIcon;

