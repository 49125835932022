import React from "react";

function Asterisk(props) {

  return (
    <svg width="8" height="8" viewBox="-6 -6 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.752 4.64V3.776H2.112L3.216 3.92L3.296 3.744L2.4 3.072L1.44 2.096L2.048 1.504L3.008 2.464L3.696 3.344L3.872 3.264L3.728 2.16V0.799999H4.592V2.16L4.448 3.264L4.624 3.344L5.312 2.464L6.272 1.504L6.88 2.096L5.92 3.072L5.024 3.744L5.104 3.92L6.208 3.776H7.568V4.64H6.208L5.104 4.512L5.024 4.672L5.92 5.36L6.88 6.32L6.272 6.928L5.312 5.968L4.624 5.072L4.448 5.152L4.592 6.256V7.616H3.728V6.256L3.872 5.152L3.696 5.072L3.008 5.968L2.048 6.928L1.44 6.32L2.4 5.36L3.296 4.672L3.216 4.512L2.112 4.64H0.752Z" fill="#121B21"/>
    </svg>
  );
}

export default Asterisk;