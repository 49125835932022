import React from "react";

function ListIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 16 14"
    >
      <g fill={color}>
        <path d="M.004 1.874c.039-.035.028-.085.042-.131.16-.512.658-.829 1.184-.75a1.068 1.068 0 01-.021 2.119c-.498.074-1-.25-1.16-.747-.013-.05-.031-.1-.049-.146v-.345h.004z"></path>
        <path d="M.008 11.778c.06-.188.12-.373.256-.526.33-.377.85-.484 1.29-.263.442.22.673.704.573 1.187-.1.473-.519.822-1.017.843-.476.021-.924-.31-1.063-.782-.01-.04-.004-.085-.04-.117v-.345.003z"></path>
        <path d="M.004 6.809c.039-.029.028-.075.042-.114a1.08 1.08 0 011.21-.757c.508.081.899.547.892 1.066-.007.523-.402.982-.91 1.053A1.08 1.08 0 01.042 7.3c-.011-.04 0-.086-.043-.114v-.374l.004-.003z"></path>
        <path d="M9.842 7.798H4.597c-.224 0-.438-.04-.608-.192-.253-.228-.338-.512-.246-.832.096-.324.324-.512.662-.562.067-.01.135-.01.202-.01h10.49c.48 0 .797.23.882.646a.794.794 0 01-.643.932c-.093.014-.185.014-.281.014H9.842v.004z"></path>
        <path d="M9.846 2.786h-5.29c-.53 0-.886-.38-.837-.885a.791.791 0 01.737-.704c.046-.004.092 0 .138 0h10.522c.463 0 .78.238.868.65a.795.795 0 01-.708.943c-.07.007-.145.004-.216.004H9.843l.003-.008z"></path>
        <path d="M9.858 11.215h5.291c.41 0 .719.217.818.569a.8.8 0 01-.718 1.024c-.057.004-.114 0-.17 0H4.633c-.131 0-.26 0-.384-.046a.8.8 0 01-.526-.89c.053-.373.388-.657.793-.657h5.341z"></path>
      </g>
    </svg>
  );
}

export default ListIcon;
