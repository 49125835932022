import { useState } from "react";
import * as Yup from "yup";
import RedeemComponent from "../../components/redeem/RedeemComponent";
import RedeemForm from "../../components/redeem/RedeemForm";
import CheckoutError from "../../components/checkout/CheckoutError";
import Spinner from "../../components/shared/Spinner";

export default function RedeemPage() {
  var initialCredentials = {
    email: "",
    phone: "",
    name: "",
    surname: "",
    country: "",
    zip: "",
    province: "",
    city: "",
    address: "",
    number: "",
    other: "",
    isSubmitting: false,
    errorMessage: null,
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email no válido")
      .required("El email es requerido"),
    phone: Yup.string().required("El teléfono es requerido"),
    name: Yup.string().required("El nombre es requerido"),
    surname: Yup.string().required("El apellido es requerido"),
    country: Yup.string().required("El país es requerido"),
    zip: Yup.string().required("El código postal es requerido"),
    province: Yup.string().required("La provincia es requerida"),
    city: Yup.string().required("La ciudad es requerida"),
    address: Yup.string().required("La dirección es requerida"),
    number: Yup.string().required("El número es requerido"),
    other: Yup.string(),
  });

  const [data, setData] = useState(initialCredentials);
  const [codes, setCodes] = useState([]);
  const [printedEnabled, setPrintedEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [redeemScreen, setRedeemScreen] = useState(0);
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const [validatedNumber1, setValidatedNumber1] = useState(false);
  const [validatedNumber2, setValidatedNumber2] = useState(false);
  const [newsCheckbox, setNewsCheckbox] = useState(false);

  return (
    <div id="redeem-page">
      {isLoading ? (
        <Spinner />
      ) : error ? (
        <CheckoutError setError={setError} />
      ) : !isLoading && redeemScreen === 0 ? (
        <RedeemComponent
          codes={codes}
          setCodes={setCodes}
          setPrintedEnabled={setPrintedEnabled}
          setIsLoading={setIsLoading}
          redeemScreen={redeemScreen}
          setRedeemScreen={setRedeemScreen}
          setData={setData}
          initialCredentials={initialCredentials}
          setError={setError}
        />
      ) : (
        !isLoading &&
        redeemScreen === 1 && (
          <RedeemForm
            codes={codes}
            printedEnabled={printedEnabled}
            setIsLoading={setIsLoading}
            setRedeemScreen={setRedeemScreen}
            data={data}
            setData={setData}
            loginSchema={loginSchema}
            printedScreen={redeemScreen}
            setPrintedScreen={setRedeemScreen}
            termsCheckbox={termsCheckbox}
            setTermsCheckbox={setTermsCheckbox}
            newsCheckbox={newsCheckbox}
            setNewsCheckbox={setNewsCheckbox}
            validatedNumber1={validatedNumber1}
            setValidatedNumber1={setValidatedNumber1}
            validatedNumber2={validatedNumber2}
            setValidatedNumber2={setValidatedNumber2}
            initialCredentials={initialCredentials}
            setError={setError}
            setCodes={setCodes}
          />
        )
      )}
    </div>
  );
}
