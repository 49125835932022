import styles from "./styles/var.module.scss";

export const theme = {
  colors: {
    pink: "#e7accf",
    "dark-pink": "#cd97bf",
    "grey-form": "#d9d9d9",
    grey: "#f0f0f0",
    "dark-grey": "#e0e0e0",
    "grey-submit": "#959ba7",
    "grey-generic": "#dcdcdc",
    white: "#ffffff",
    black: "#000000",
    "red-error": "#890707",
    "red-error-background": "#fff1f9",
    green: "#609579",
    purple: "#9b8ec3",
    "dark-purple": "#8d6fd1",
    "purple-landing": "#726b9b",
    "purple-generic": "#8852e2",
    marfil: "#fcf0f0",
    "grey-redeem": "#f3f3f3",
    "dark-green": "#548068",
  },
  font: {
    franklin: styles["roboto-font"],
    roboto: styles["roboto-font"],
    "roboto-condensed": styles["roboto-font"],
  },
};

console.log(styles.primaryfont);
