import Resume from "../../components/fancy-checkout/Resume";
import { useState } from "react";
import { Form, Formik } from "formik";
import Step1 from "../../components/fancy-checkout/Step1";
import Step2 from "./Step2";
import Step3 from "../../components/fancy-checkout/Step3";
import Step4 from "../../components/fancy-checkout/Step4";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { checkoutValidation } from "../../utilities/checkoutValidation";
import ResumeCardMobile from "../../components/fancy-checkout/ResumeCardMobile";
import "../../styles/fancy-checkout.scss";
import routes from "../../routes/routes";

const ROUTES_FANCY_CHECKOUT = {
  INIT: "/fancy-checkout/inicio",
  DATA: "/fancy-checkout/data",
  RESUME: "/fancy-checkout/resume",
  PAY: "/fancy-checkout/pay",
};
const URL_PARAMS = {
  INIT: "inicio",
  DATA: "data",
  RESUME: "resume",
  PAY: "pay",
};

const FancyCheckout = () => {
  const location = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const validateSubmitRoutes = location.pathname !== ROUTES_FANCY_CHECKOUT.INIT;
  const buttonTextPayment =
    location.pathname === ROUTES_FANCY_CHECKOUT.PAY ? "Pagar" : "Siguiente";
  const [initialValues] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    addressNumber: "",
    neighborhood: "",
    deptNumber: "",
    state: "",
    postalCode: "",
    country: "",
    additionalIndications: "",
    terms: false,
  });

  const handleVerifyClick = async (errors, submitForm, isValid) => {
    switch (slug) {
      case URL_PARAMS.INIT:
        navigate(`${routes.fancy_checkout}/${URL_PARAMS.DATA}`);
        break;
      case URL_PARAMS.DATA:
        await submitForm();
        if (Object.keys(errors).length === 0 && isValid) {
          navigate(`${routes.fancy_checkout}/${URL_PARAMS.RESUME}`);
        } else {
          navigate(`${routes.fancy_checkout}/${URL_PARAMS.DATA}`);
        }
        break;
      case URL_PARAMS.RESUME:
        if (Object.keys(errors).length === 0) {
          navigate(`${routes.fancy_checkout}/${URL_PARAMS.PAY}`);
        } else {
          navigate(`${routes.fancy_checkout}/${URL_PARAMS.RESUME}`);
        }
        break;
      default:
        navigate(`${routes.fancy_checkout}/${URL_PARAMS.INIT}`);
    }
  };
  const getComponentByParam = (param) => {
    switch (param) {
      case URL_PARAMS.INIT:
        return <Step1 />;
      case URL_PARAMS.DATA:
        return <Step2 />;
      case URL_PARAMS.RESUME:
        return <Step3 />;
      case URL_PARAMS.PAY:
        return <Step4 />;
      default:
        return <Step1 />;
    }
  };

  const handleSubmit = (e) => {
    console.log("Submitting...");
  };

  const cancel = () => {
    navigate(`${routes.fancy_checkout}/inicio`);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validateSubmitRoutes ? checkoutValidation : null}
      onSubmit={(values) => handleSubmit(values)}
      validateOnMount={false}
    >
      {({ errors, dirty, submitForm, isValid }) => (
        <Form>
          <section>
            <div
              className={`${
                slug !== URL_PARAMS.PAY
                  ? "fancy-checkout"
                  : "fancy-checkout pay"
              }`}
            >
              {getComponentByParam(slug)}
              {location.pathname !== ROUTES_FANCY_CHECKOUT.PAY && (
                <>
                  <div className="checkout__resume">
                    <Resume
                      type={validateSubmitRoutes ? "submit" : "button"}
                      next={() =>
                        handleVerifyClick(errors, submitForm, isValid)
                      }
                      disabled={
                        (slug === URL_PARAMS.DATA && !dirty) ||
                        (slug === URL_PARAMS.RESUME && !dirty)
                      }
                      cancel={cancel}
                      nextLabel={buttonTextPayment}
                      cancelLabel={"Atrás"}
                    />
                  </div>
                  <div className="checkout-resume-mobile">
                    <ResumeCardMobile
                      type={validateSubmitRoutes ? "submit" : "button"}
                      next={() =>
                        handleVerifyClick(errors, submitForm, isValid)
                      }
                      disabled={
                        (slug === URL_PARAMS.DATA && !dirty) ||
                        (slug === URL_PARAMS.RESUME && !dirty)
                      }
                      cancel={cancel}
                      nextLabel={buttonTextPayment}
                      cancelLabel={"Atrás"}
                    />
                  </div>
                </>
              )}
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default FancyCheckout;
