import { useContext, useEffect, useState } from "react";
import { postStartCreditCard } from "../../services/axiosService";
import { useFormikContext } from "formik";
import { CartContext } from "../../context/Cart";
import { Button } from "../shared/Button";
import { FadeLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import MethodPayment from "../icons/MethodPayment";
import PaypalIcon from "../icons/PaypalIcon";
import ModalTerms from "../modals/ModalTerms";
import TransferIcon from "../icons/TransferIcon";
import ZelleIcon from "../icons/ZelleIcon";
import CriptoIcon from "../icons/CriptoIcon";
import "../../styles/fancy-checkout.scss";

const Step4 = () => {
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const { values } = useFormikContext();
  const { count, isMultiple, isPrinted, countDisplay } =
    useContext(CartContext);
  const [getData, setGetData] = useState();
  useEffect(() => {
    setGetData({
      quantity: count,
      includesPrinted: true,
      email: values.email,
      phone: values.phone.toString(), // CONCATENAR CON LASTNAME
      directSale: false,
      shippingInfo: {
        name: values.name,
        country: values.country,
        postalCode: values.postalCode,
        state: values.state,
        neighborhood: values.neighborhood,
        address: values.address,
        addressNumber: values.addressNumber,
        deptNumber: values.deptNumber,
        contactPhone: values.phone.toString(),
        multiplePrinted: isMultiple,
        quantityDisplays: countDisplay ?? 0,
        additionalIndications: values.additionalIndications,
      },
    });
  }, [values, isMultiple, count, isPrinted]);

  const handleSubmit = async () => {
    localStorage.setItem("numMonas", count);
    const notify = (text) => toast.error(text);
    setLoader(true);
    try {
      const response = await postStartCreditCard(getData);
      localStorage.setItem("token", response.data.token);

      if (response.status === 422) {
        notify("Algo ha ido mal, prueba más tarde");
      }

      if (response.status === 200) {
        window.location.replace(response.data.url);
      }
    } catch (error) {
      notify(error.message);
      setLoader(false);
    }
  };

  return (
    <div id="step4">
      <div className="checkout-payment container">
        <div className="checkout-payment-text">
          <span className="checkout-payment-title">
            Elige el método de pago a utilizar
          </span>
          <span className="checkout-payment-subtitle">
            Para finalizar la compra deberás seleccionar una opción
          </span>
        </div>
        <div className="checkout-payment-cards">
          <div
            className={`card-method ${selected && "selected"}`}
            onClick={() => setSelected(!selected)}
          >
            <div className={"card-method-info"}>
              <PaypalIcon width={35} height={35} />
              <span>PayPal</span>
              <caption>(Se redigirá a PayPal)</caption>
              {selected && (
                <Button color="primary" onClick={handleSubmit}>
                  Pagar
                </Button>
              )}
              {loader && <FadeLoader color="#000" />}
            </div>
          </div>
          <div
            className="card-method"
            onClick={() => {
              setOpenModal(true);
              setSelected(false);
            }}
          >
            <div className="card-method-info">
              <MethodPayment width={35} height={35} />
              <span>Otros métodos de pago</span>
            </div>
          </div>
        </div>
      </div>
      <ModalTerms
        termsModal={openModal}
        setTermsModal={setOpenModal}
        title={"Otros medios de pago"}
        subtitle={
          "Aquí verás las opciones que tenemos disponibles para que puedas efectuar el pago."
        }
        isClosable={() => setOpenModal(!openModal)}
      >
        <div className="modal-method-payment">
          <div className="block1">
            <h3>Importante</h3>
            <span>
              Una vez efectuado el pago,{" "}
              <strong>envíanos el comprobante</strong> a
              <a href="mailto:hello@fancymonas.io" className="text-primary">
                hello@fancymonas.io
              </a>{" "}
              para que podamos enviarte instrucciones para obtener tu obra.
            </span>
          </div>
          <div className="block2">
            <div className="transfer-option">
              <TransferIcon width={40} height={40} />
              <div className="d-flex flex-column">
                <span className="title mb-2">Transferencia en USD</span>
                <p>
                  JPMorgan Chase Bank, N.A.
                  <br />
                  Número de cuenta <strong>786165107</strong>
                  <br />
                  Número de Ruta <strong>267084131</strong>
                  <br />
                  Swift Code for Chase Bank is: <strong>Chasus33</strong>
                  <br />
                  ABA/Routing: <strong>021000021</strong>
                </p>
              </div>
            </div>
            <div className="zelle-option">
              <ZelleIcon width={40} />
              <a href="mailto:hello@artbag.io">hello@artbag.io</a>
            </div>
          </div>
          <div className="block3">
            <div className="cripto-option">
              <CriptoIcon width={40} height={40} />
              <div className="d-flex flex-column">
                <span className="title mb-2">Cryptomonedas / USDT</span>
                <p>
                  Red Tron
                  <br />
                  Dirección de wallet:{" "}
                  <strong>TLhjGLEHM4CG1GueQTRwwkm5BmyZtzJqsH</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="block4">
            <h3>
              <strong>¿Querés pagar en Pesos ARS?</strong>
            </h3>
            <span>Contáctate con nosotros para coordinar el pago. </span>
            <a className="mt-3" href="mailto:hello@fancymonas.io">
              hello@fancymonas.io
            </a>
          </div>
        </div>
      </ModalTerms>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </div>
  );
};

export default Step4;
