import { useEffect, useState } from "react";
import { createContext } from "react";

export const CartContext = createContext();

const CartContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(1);
  const [qtyMonas, setQty] = useState(1);
  const [isPrinted, setIsPrinted] = useState(true);
  const [isMultiple, setIsMultiple] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const basePrice = 1500;
  const [price, setPrice] = useState(basePrice);
  const [numberMonas, setNumberMonas] = useState();

  /* set user */
  const pushUser = (user) => {
    setUser({
      token: user?.accessToken ?? "",
      email: user?.userEmail ?? "",
    });
  };

  /* DISPLAY ITEM */
  const [countDisplay, setCountDisplay] = useState(0);
  const basePriceDisplay = 500;
  const [priceDisplay, setPriceDisplay] = useState(0);

  const addItem = () => {
    setCount(count + 1);
    setPrice(price + basePrice);
  };

  const removeItem = () => {
    if (count === 1) {
      return setCount(count);
    } else {
      setCount(count - 1);
      setPrice(price - basePrice);
    }
  };

  /* PRICE DISPLAY */
  const addDisplay = () => {
    setCountDisplay(countDisplay + 1);
    setPriceDisplay(priceDisplay + basePriceDisplay);
  };

  const removeDisplay = () => {
    if (countDisplay === 0) {
      return setCountDisplay(countDisplay);
    } else {
      setCountDisplay(countDisplay - 1);
      setPriceDisplay(priceDisplay - basePriceDisplay);
    }
  };

  const multipleDisplay = () => {
    setIsMultiple(!isMultiple);
  };

  const isPrintedActive = () => {
    setIsPrinted(!isPrinted);
  };

  const getPaymentMethod = (data) => {
    setPaymentMethod({
      url: data?.url ?? "",
      artworkSaleId: data?.artworkSaleId ?? "",
    });
  };

  const resetCart = () => {
    setCount(1);
    setCountDisplay(0);
    setIsMultiple(false);
    setIsPrinted(true);
    setQty(1);
    setTotal(0);
    setPriceDisplay(0);
    setPaymentMethod({
      url: "",
      artworkSaleId: "",
    });
  };

  useEffect(() => {
    setTotal(priceDisplay + price);
  }, [price, priceDisplay]);

  const saveNumberMonas = (num) => {
    setNumberMonas(num);
  };

  return (
    <CartContext.Provider
      value={{
        price,
        addItem,
        removeItem,
        count,
        addDisplay,
        removeDisplay,
        priceDisplay,
        countDisplay,
        total,
        qtyMonas,
        isMultiple,
        multipleDisplay,
        isPrintedActive,
        isPrinted,
        pushUser,
        user,
        getPaymentMethod,
        paymentMethod,
        resetCart,
        saveNumberMonas,
        numberMonas,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
