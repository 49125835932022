import CashIcon from "../../components/icons/CashIcon";
import "./tc.scss";

const CashMethod = () => {
  return (
    <div className="tc-en-pasos">
      <div className="content">
        <CashIcon width={64} height={"auto"} color={"#86CAF4"} />
        <h1>Pago en efectivo</h1>
        <p className="mt-2">
          Una vez efectuado el pago presencial en efectivo avanza para comenzar
          con el revelado de Fancy Monas
        </p>
      </div>
      <div className="icons-payments mt-5"></div>
    </div>
  );
};

export default CashMethod;
