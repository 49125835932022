import React from "react";

function CriptoIcon({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 50 50"
    >
      <g fill="#2368AC">
        <path d="M23.545 50c-.91-.168-1.836-.204-2.749-.364-5.48-.968-10.147-3.46-13.966-7.502C3.153 38.247.987 33.612.237 28.322-1.365 17.01 5.288 5.761 15.982 1.682c13.965-5.328 29.29 2.465 33.168 16.867.394 1.464.652 2.951.765 4.463.013.174.056.346.085.519v2.93c-.029.157-.072.312-.082.47a24.618 24.618 0 01-.5 3.421c-2.06 9.768-10.24 17.611-20.106 19.266-.909.152-1.826.257-2.739.382h-3.028zM24.96 2.136C12.232 2.197 2.077 12.448 2.177 25.237c.097 12.616 10.426 22.79 23.17 22.609 12.543-.178 22.578-10.421 22.508-23.04-.07-12.496-10.396-22.729-22.895-22.67z"></path>
        <path d="M24.748 6.376c9.396 0 17.188 6.66 18.625 15.69A18.552 18.552 0 0127.92 43.383c-4.885.779-9.362-.255-13.45-3.025-.503-.34-.7-.771-.568-1.236.128-.447.433-.719.892-.797.386-.067.686.134.986.337 2.123 1.428 4.44 2.363 6.987 2.677 4.807.593 9.14-.631 12.812-3.753 5.114-4.349 6.95-9.978 5.378-16.457-1.578-6.507-5.867-10.635-12.38-12.18-9.092-2.16-18.239 4.022-19.787 13.22-.735 4.373.154 8.401 2.589 12.094.182.276.352.556.306.9-.064.483-.335.81-.81.93-.504.128-.892-.082-1.175-.493a18.4 18.4 0 01-2.657-5.719C4.203 19.301 10.916 8.68 21.71 6.671a18.659 18.659 0 013.036-.296l.001.001z"></path>
        <path d="M28.472 14.875c0 .065.008.132 0 .196-.116.82.164 1.318.932 1.786 2.314 1.412 2.928 4.498 1.482 6.82-.421.675-.398.729.241 1.214 1.7 1.292 2.42 3.012 1.993 5.102-.432 2.112-1.783 3.413-3.881 3.915-.704.168-.763.223-.767.94-.003.456.014.913-.016 1.367-.04.612-.463 1.006-1.038 1.012-.596.007-1.034-.396-1.07-1.03-.029-.504-.01-1.009-.016-1.514-.005-.414-.2-.628-.633-.625-.992.008-1.984.005-2.977 0-.426-.001-.63.209-.63.626-.002.488.007.978-.005 1.466-.015.65-.452 1.108-1.05 1.115-.589.008-1.056-.447-1.083-1.09-.021-.504-.008-1.01-.007-1.514 0-.4-.193-.603-.596-.602-.472 0-.944.01-1.415-.004-.72-.022-1.202-.464-1.2-1.08 0-.623.483-1.048 1.207-1.056.44-.005.879.007 1.317-.004.505-.011.686-.184.686-.673.004-4.166 0-8.334.004-12.5 0-.457-.228-.653-.659-.657-.472-.004-.944.005-1.415-.004-.658-.013-1.116-.43-1.14-1.023-.024-.578.427-1.063 1.07-1.104.502-.031 1.008-.016 1.513-.012.424.004.632-.193.628-.62-.004-.473-.009-.945.003-1.417.017-.705.481-1.19 1.104-1.17.615.02 1.025.483 1.035 1.18.006.472-.002.944.003 1.415.004.39.195.608.599.608 1.008 0 2.017-.002 3.026.003.438.003.614-.234.616-.64.003-.456-.007-.913.006-1.368.02-.717.42-1.151 1.047-1.16.65-.01 1.06.43 1.085 1.172.01.309.001.618.002.927l-.001.003zm-2.925 11.2c-.927 0-1.853.006-2.78-.003-.441-.004-.676.188-.677.636-.002 1.529-.002 3.058 0 4.588 0 .396.215.615.601.617 1.886.005 3.774.053 5.657-.024a2.853 2.853 0 002.757-3.008c-.08-1.632-1.335-2.799-3.023-2.804-.845-.002-1.691 0-2.536 0l.001-.002zm-3.457-5.07c0 .732-.004 1.463.001 2.195.004.559.171.724.73.726 1.234.004 2.47.005 3.704 0 1.67-.008 2.958-1.236 2.985-2.836.029-1.653-1.18-2.939-2.877-2.993-1.282-.042-2.566-.017-3.85-.01-.516.005-.685.192-.69.724-.007.732-.002 1.462-.002 2.194h-.001z"></path>
      </g>
    </svg>
  );
}

export default CriptoIcon;
