import React from "react";

function ZelleIcon({ width, height, color = "#7611DB" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 42 18"
    >
      <g fill={color}>
        <path d="M21.21 8.376c.005 1.677-.007 3.354.05 5.03.01.315.028.633.17.924.37.754 1.012 1.131 1.787 1.3.472.104.957.067 1.437.052a2.04 2.04 0 00.691-.13c.268-.107.34-.26.236-.531-.06-.16-.135-.314-.196-.474a5.893 5.893 0 01-.27-1.03c-.04-.218-.138-.298-.356-.31-.186-.012-.373-.014-.56-.024-.214-.01-.276-.067-.312-.288-.076-.47-.068-.947-.07-1.42-.013-2.744-.01-5.487-.023-8.231-.003-.569.003-1.139-.03-1.708-.01-.185-.02-.254-.27-.259-.686-.01-1.371-.023-2.056.01-.137.007-.196.07-.199.191-.007.308-.004.61-.004.918-.003 1.699-.022 5.093-.022 5.098v.882h-.002z"></path>
        <path d="M12.863 11.145h3.6c.88-.003 2.06-.006 3.238-.044.14-.005.202-.07.236-.189a2.25 2.25 0 00.079-.588 6.692 6.692 0 00-.187-1.677c-.263-1.095-.78-2.035-1.676-2.726-.742-.574-1.581-.914-2.517-.973-.62-.039-1.242-.065-1.858.082-1.642.39-2.8 1.367-3.454 2.943-.454 1.098-.522 2.25-.364 3.417.2 1.488.913 2.666 2.156 3.487.769.507 1.613.813 2.548.802.436-.004.873.002 1.309-.004a3.13 3.13 0 00.464-.042c.477-.078.927-.245 1.365-.446a5.463 5.463 0 001.707-1.212c.226-.24.23-.401-.004-.594-.392-.322-.744-.686-1.099-1.049-.289-.296-.377-.295-.69-.018-.159.14-.316.28-.494.393-.617.395-1.301.51-2.017.5a3.511 3.511 0 01-1.153-.185c-.78-.282-1.153-.907-1.354-1.671-.053-.202-.042-.205.165-.205v-.001zm-.108-2.282c.234-.881.813-1.421 1.666-1.633.75-.185 1.469-.073 2.107.399.432.32.65.777.78 1.287.03.117-.037.112-.114.112H12.88c-.164 0-.17-.003-.126-.165z"></path>
        <path d="M.094 13.254a.432.432 0 00-.094.284c.005.6.002 1.203.002 1.803 0 .13.065.194.197.194 1.21 0 2.422.002 3.633-.003.12 0 .157.028.157.156-.007.718 0 1.437-.006 2.156 0 .119.035.156.151.156.708-.005 1.416-.005 2.124 0 .119 0 .149-.038.148-.156-.006-.723 0-1.446-.006-2.17 0-.114.032-.143.142-.14.476.005.953.002 1.429.002.787 0 1.575-.003 2.363.003.127 0 .162-.044.158-.164a.67.67 0 00-.163-.434 6.155 6.155 0 01-1.126-1.844c-.025-.065-.062-.1-.139-.1-.16 0-5.007-.045-5.286-.045.013-.075.053-.107.081-.145 2.082-2.724 4.163-5.45 6.247-8.172a.43.43 0 00.093-.284c-.004-.552 0-1.103-.002-1.655 0-.215.04-.214-.206-.214-1.144 0-2.288-.002-3.432.002-.131 0-.162-.038-.16-.166.006-.723 0-1.447.006-2.17C6.405.026 6.37 0 6.255 0 5.548.006 4.84.006 4.132 0c-.118 0-.152.032-.15.152.005.718.002 1.437.002 2.156 0 .174 0 .174-.177.173-1.14-.01-2.279-.019-3.419-.033-.118-.002-.15.035-.15.153.005.737.004 1.473.004 2.21 0 .13.066.196.198.196h5.984c-.04.058-4.867 6.348-6.332 8.25l.002-.003z"></path>
        <path d="M40.497 7.66c-.53-1.119-1.332-1.937-2.481-2.393a4.97 4.97 0 00-1.758-.334c-.437-.007-.88-.027-1.305.058-1.502.3-2.675 1.098-3.39 2.491-.797 1.554-.892 3.19-.373 4.848.462 1.476 1.44 2.481 2.87 3.032 1.136.437 2.307.43 3.472.236 1.13-.187 2.102-.733 2.913-1.56.32-.329.32-.43-.027-.726a8.952 8.952 0 01-1.002-.975 1.127 1.127 0 00-.104-.104c-.165-.147-.277-.158-.454-.031-.105.075-.201.164-.299.249-.317.279-.676.48-1.079.603-.55.167-1.113.18-1.678.137a2.6 2.6 0 01-.937-.247c-.68-.328-1.018-.91-1.184-1.626-.03-.125 0-.141.113-.141 1.157.004 2.315.002 3.472.002 1.153 0 2.306-.003 3.459.003.137 0 .196-.054.221-.175.033-.154.054-.31.057-.469a9.274 9.274 0 00-.05-1.136c-.063-.606-.194-1.188-.457-1.742zm-2.375 1.371h-2.135c-.712 0-1.424-.001-2.136.003-.115 0-.143-.024-.114-.142a2.286 2.286 0 011.875-1.703c.704-.098 1.357.023 1.93.462.384.293.587.718.743 1.165.062.176.018.215-.163.215z"></path>
        <path d="M28.998 1.645c-.007-.334-.043-.373-.373-.373-.641 0-1.282-.025-1.922.024-.224.017-.265 0-.274.23-.013.339-.003.7-.004 1.04-.003 1.64-.001 3.282-.001 4.923 0 1.695-.003 3.39.002 5.086 0 .388-.002.78.065 1.162.18 1.024.844 1.595 1.777 1.864.52.149 1.07.103 1.607.074.322-.018.632-.09.926-.232.451-.217.5-.376.222-.805-.278-.43-.547-.866-.776-1.327-.036-.075-.083-.103-.166-.104-.213-.002-.427.012-.64-.02-.212-.032-.286-.09-.335-.303-.044-.19-.046-.386-.049-.58-.019-1.32-.029-2.64-.03-3.96-.002-2.234.021-4.467-.03-6.7v.001z"></path>
      </g>
    </svg>
  );
}

export default ZelleIcon;
