import React from "react";

function MethodPayment({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 40 46"
    >
      <path fill="#F5F5F5" d="M0 0H40V46H0z"></path>
      <g>
        <path
          fill="#fff"
          d="M0 0H1440V773H0z"
          transform="translate(-908 -369)"
        ></path>
        <g>
          <g>
            <rect
              width="306"
              height="200"
              x="-135"
              y="-56"
              fill="#F9FCFF"
              stroke="#AED3FF"
              strokeMiterlimit="0"
              rx="6"
            ></rect>
          </g>
          <g fill="#2368AC" clipPath="url(#clip0_780_14576)">
            <path d="M21.297 5.85c-1.242-1.191-2.318-2.246-3.424-3.27-.308-.286-.32-.45-.014-.741A26.86 26.86 0 0019.413.197c.245-.278.4-.25.653-.006 2.285 2.204 4.578 4.403 6.881 6.59.274.26.23.407-.005.655-2.194 2.296-4.38 4.6-6.558 6.915-.25.266-.399.251-.642.001a25.78 25.78 0 00-1.633-1.563c-.341-.294-.274-.465.006-.751 1.003-1.027 1.978-2.082 3.109-3.28-.89 0-1.604-.034-2.315.006-8.275.461-15.12 7.097-15.911 15.414-.89 9.36 5.672 17.602 14.949 18.776 9.506 1.203 18.297-5.98 19.011-15.587.439-5.91-1.644-10.807-6.105-14.67-.353-.306-.347-.496-.068-.815.317-.36.594-.755.888-1.134.576-.742.594-.764 1.303-.11 6.138 5.674 8.436 12.646 6.167 20.716-2.276 8.097-7.857 12.969-16.107 14.372C12.267 47.558 2.042 40.04.274 29.23-1.546 18.113 5.888 7.767 16.947 6.023c1.37-.216 2.755-.235 4.35-.17V5.85z"></path>
            <path d="M20.387 16.713h.137c1.345 0 1.361 0 1.37 1.32.004.394.116.546.512.633 1.556.338 2.815 1.118 3.628 2.553.227.402.165.577-.254.742-.57.226-1.122.504-1.671.777-.293.145-.474.17-.735-.138-1.136-1.337-3.927-1.726-5.434-.806-.488.298-.753.715-.713 1.306.04.599.444.873.939 1.049 1.235.443 2.552.437 3.823.68.673.128 1.336.275 1.977.518 1.637.619 2.654 1.741 2.806 3.534.15 1.753-.447 3.19-1.892 4.229-.755.544-1.606.858-2.516 1.001-.365.057-.486.198-.471.571.022.498.164 1.172-.092 1.455-.336.373-1.028.118-1.563.125-1.414.022-1.438.008-1.433-1.405.002-.487-.193-.603-.62-.678-2.032-.364-3.65-1.343-4.643-3.239-.226-.431-.17-.622.302-.786.665-.231 1.302-.54 1.95-.817.193-.083.359-.19.509.09.685 1.276 1.88 1.728 3.202 1.94.917.146 1.818.023 2.686-.298.847-.316 1.317-.933 1.287-1.672-.033-.8-.615-1.131-1.251-1.374-1.25-.476-2.589-.48-3.886-.712a9.511 9.511 0 01-2.026-.587c-2.891-1.22-2.864-5.12-.986-6.714.86-.73 1.85-1.16 2.941-1.357.387-.07.53-.206.537-.614.022-1.324.039-1.324 1.38-1.324h.204l-.004-.002z"></path>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_780_14576">
          <path fill="#fff" d="M0 0H40V46H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default MethodPayment;
