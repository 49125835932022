import React from "react";

function CheckOk(props) {

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#66D687"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7424 6.84731C13.1029 6.42889 13.7343 6.38193 14.1527 6.74241C14.5389 7.07516 14.6087 7.63877 14.3336 8.05261L14.2576 8.15273L9.94992 13.1527C9.60838 13.5492 9.026 13.6106 8.6117 13.3143L8.51186 13.2328L5.81955 10.7328C5.41484 10.357 5.39141 9.72428 5.76721 9.31957C6.11411 8.94599 6.67992 8.89728 7.08323 9.18758L7.18046 9.26722L9.111 11.061L12.7424 6.84731Z" fill="white"/>
    </svg>
  );
}

export default CheckOk;