import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Swiper,
  SwiperSlide,
} from "../../../node_modules/swiper/react/swiper-react.js";
import "../../../node_modules/swiper/swiper.scss";
import "../../../node_modules/swiper/modules/navigation/navigation.scss"; // Navigation module
import "../../../node_modules/swiper/modules/effect-cards/effect-cards.scss"; // Pagination module
import "../../../node_modules/swiper/modules/effect-creative/effect-creative.scss"; // Pagination module
import "../../../node_modules/swiper/modules/autoplay/autoplay.scss"; // Pagination module

import Pavilion from "../../assets/landing/pavilion.png";
import Reloj from "../../assets/landing/reloj-watch.png";
import Telefono from "../../assets/landing/telefono-exclusive.png";
import Tv from "../../assets/landing/tv-exclusive.png";
import MonkeyIconDark from "../../components/icons/MonkeyIconDark";
import Blocked from "../../components/icons/Blocked";
import SliderBullet from "../../components/icons/SliderBullet";

import { Pagination, EffectCreative, Autoplay, Navigation } from "swiper";

import Firma from "../../components/icons/Firma";
import FirmaMobile from "../../components/icons/FirmaMobile";
import InstagramDark from "../../components/icons/InstagramDark";
import TwitterDark from "../../components/icons/TwitterDark";
import OpenMona from "../../components/icons/OpenMona";
import Login from "../../components/shared/Login";
/* import monas from "./monas.json"; */
import monas from "./monas-new.json";
import news from "./news.json";
import {
  getProfile,
  suscribeToWhilest,
  getExclusiveContent,
  login,
  getRandomArtworks,
} from "../../services/axiosService";
import ModalContainer from "../../components/modals/ModalContainer";
import Whitelist from "../../components/shared/Whitelist";
import ModalZoom from "../../components/shared/ModalZoom";
import { AppContext } from "../../App";
import { Fragment } from "react";
import Mona1 from "../../assets/landing/Mona1.png";
import Mona2 from "../../assets/landing/Mona2.png";
import Mona3 from "../../assets/landing/Mona3.png";
import MyShareButton from "../../components/share/ShareButtonAll";
import Navbar from "../../components/shared/Navbar.jsx";
import NavbarTop from "../../components/shared/NavbarTop.jsx";
import MainFooter from "../../components/shared/MainFooter.jsx";

const TREAT_TYPES = {
  Sombrero: "hat",
  Traje: "suit",
  Ojos: "eyes",
};
const DEFAULT_TREAT_TYPE = TREAT_TYPES.Sombrero;

const TREAT_TYPE_TO_NAME = {
  Fondo: "FONDO",
  Pelaje: "PELAJE",
  Ojos: "OJOS",
  Boca: "BOCA",
  Accesorios: "ACCESORIOS",
  Traje: "TRAJE",
  Sombrero: "SOMBRERO",
  Mirada: "MIRADA",
};

const ACCESORIES_LIST = ["Anteojos Courreges"];

export default function ItemsPage() {
  const [{ imageUrl, metadata, isOwner }, setItem] = useState({});
  const [treatTypeSelected, setTreatTypeSelected] =
    useState(DEFAULT_TREAT_TYPE);
  const [treatContent, setTreatContent] = useState({});
  const [email, setEmail] = useState("");
  const [titleTreat, setTitleTreat] = useState("");
  const { termsModal, setTermsModal, token } = React.useContext(AppContext);
  const { zoomModal, setZoomModal } = React.useContext(AppContext);
  const { loginModal, setLoginModal } = React.useContext(AppContext);

  const { itemNumber } = useParams();
  const navigate = useNavigate();

  const [validEmail, setValidEmail] = useState(true);
  const [randomArtworks, setRandomArtworks] = useState([]);

  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const styleSuccessBorder = {
    border: "2px solid #5CC574",
  };
  const styleSuccessInput = {
    "background-color": "rgb(92, 197, 116,0.2)",
  };
  const styleSuccessIcon = {
    color: "#5CC574",
    "font-size": "22px",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "min-width": "39%",
  };
  const styleSuccessLabel = {
    color: "#5CC574",
  };

  useEffect(() => {
    const getProfileAsync = async () => {
      try {
        const formattedNumber = itemNumber.startsWith("0")
          ? itemNumber.slice(1)
          : itemNumber;

        const convertedNumber = Number(formattedNumber);
        if (!convertedNumber) {
          navigate("not-found");
        } else {
          const { data: itemProfile } = await getProfile(formattedNumber);

          setItem(itemProfile);
          const itemKey = Object.keys(itemProfile.metadata).find(
            (key) => TREAT_TYPES[key] === treatTypeSelected
          );
          if (monas[treatTypeSelected][itemProfile.metadata[itemKey]]) {
            setTreatContent(
              monas[treatTypeSelected][itemProfile.metadata[itemKey]]
            );
            setTitleTreat(itemProfile.metadata[itemKey]);
          } else {
            setTreatContent({});
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    };
    const getRandArtwork = async () => {
      try {
        const { data: rArtworks } = await getRandomArtworks();
        setRandomArtworks(rArtworks);
      } catch (err) {
        console.log("err", err);
      }
    };
    getRandArtwork();
    getProfileAsync();
  }, [itemNumber, token]);

  const onClickReference = async (newTreatTypeSelected) => {
    const formattedNumber = itemNumber.startsWith("0")
      ? itemNumber.slice(1)
      : itemNumber;
    const { data: itemProfile } = await getProfile(formattedNumber);

    setTreatTypeSelected(newTreatTypeSelected);

    const itemKey = Object.keys(metadata).find(
      (key) => TREAT_TYPES[key] === newTreatTypeSelected
    );

    if (monas[newTreatTypeSelected][metadata[itemKey]]) {
      setTreatContent(monas[newTreatTypeSelected][metadata[itemKey]]);
      setTitleTreat(itemProfile.metadata[itemKey]);
    }
  };

  const validateEmail = (email) => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    setValidEmail(regex.test(email));
    return regex.test(email);
  };

  let handlSubscribeWhitelist = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setLoading(true);
      await suscribeToWhilest(email)
        .then(() => {
          setLoading(false);
          setSubscribed(true);
        })
        .catch((error) => console.log(error));
      setTimeout(() => {
        setSubscribed(false);
      }, 4000);
    } else {
      return;
    }
  };

  let handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  let handleOpenMonas = (e) => {
    setZoomModal(true);
  };

  let itemmona = parseInt(itemNumber) + 1248;

  return (
    <Fragment>
      {<ModalContainer>{termsModal ? <Whitelist /> : null}</ModalContainer>}
      {
        <ModalContainer>
          {zoomModal ? (
            <ModalZoom
              getExclusiveContent={() =>
                getExclusiveContent(itemNumber, "original", token)
              }
              imageUrl={imageUrl}
            />
          ) : null}
        </ModalContainer>
      }
      {
        <ModalContainer>
          {loginModal ? <Login handleLogin={login} /> : null}
        </ModalContainer>
      }
      <div className="margin-container">
        <Navbar />
        <NavbarTop />
        <div className="container-fluid mt-5 pt-5 perfil-monas" id="start">
          {/* <NavbarSticky isLogged={!!token} /> */}
          <div className="wrapper-item-page">
            <section
              id="item-section"
              className="d-flex flex-column align-items-center container-lg container-md container-sm container-xs container-xl"
            >
              <div className="item-section-intro row">
                <div className="col-sm-12 col-lg-6">
                  <div className="card card-fancy img-fluid">
                    {imageUrl && (
                      <img
                        className="item-image rounded card-img img-fluid"
                        src={imageUrl}
                        alt="card"
                      />
                    )}
                    <div className="card-img-overlay img-fluid">
                      {isOwner && (
                        <h5 className="card-title text-end">
                          <span
                            className="openmona"
                            onClick={(e) => handleOpenMonas(e)}
                          >
                            <OpenMona />{" "}
                          </span>
                        </h5>
                      )}
                      <p className="card-text text-end item-text d-flex justify-content-between">
                        {isOwner ? (
                          <MyShareButton
                            itemNumber={itemNumber}
                          ></MyShareButton>
                        ) : (
                          <div />
                        )}
                        <span className="badge item-badge text-bg-light px-3 rounded-pill">
                          #{itemNumber}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 item-section-column">
                  <div className="h-100 d-flex flex-column justify-content-between">
                    <div className="d-flex flex-column ">
                      <h3 className="mb-3 mt-5 iconos-emoji">🍌</h3>
                      <h1 className="mb-3 titulo-item">Fancy Mona</h1>
                      <h2 className="mb-3 subtitulo-item">#{itemNumber}</h2>
                      <p className="text-start item-firma is-no-small">
                        Powered by{" "}
                        <a
                          href="http://artbag.io/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <u>artbag.io</u>
                        </a>
                      </p>
                    </div>
                    <div className="d-flex align-items-center gap- mt-3 is-small justify-content-between">
                      <p className="item-firma is-small">
                        Powered by{" "}
                        <a
                          href="http://artbag.io/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <u>artbag.io</u>
                        </a>
                      </p>
                      <p className="is-small">
                        <FirmaMobile />
                      </p>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="d-flex mt-5 flex-sm-wrap">
                        <p className="item-redes-texto d-flex align-items-center gap-2 text-black">
                          Seguir a FAMO en redes
                          <a
                            className="rounded item-redes-icono"
                            target="_blank"
                            href="https://www.instagram.com/fancymonas/"
                            rel="noreferrer"
                          >
                            <InstagramDark />
                          </a>
                          <a
                            className=" rounded item-redes-icono "
                            target="_blank"
                            href="https://twitter.com/FancyMonas"
                            rel="noreferrer"
                          >
                            <TwitterDark />
                          </a>
                        </p>
                      </div>
                      <div className="d-flex gap-2 mb-3 is-no-small">
                        <p className="is-no-small">
                          <Firma />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              id="item-fancy-section"
              className="d-flex flex-column align-items-center container-lg container-md container-sm container-xs container-xl"
            >
              <div className="item-fancy row ">
                <div className="col-12">
                  <h1 className="mb-5 titulo-item-fancy">
                    Sobre esta Fancy Mona{" "}
                    <span className="iconos-emoji-textos">🐵</span>
                  </h1>
                  {metadata && (
                    <div className="card">
                      <div className="boxes-fancy-card">
                        {Object.keys(metadata).map((key, index) => (
                          <div
                            key={index}
                            className="box-fancy-card d-flex flex-column justify-content-center align-items-center"
                          >
                            <h5 className="card-title  titulo-box-fancy-card">
                              {metadata[key]}
                            </h5>
                            <p className="card-text  subtitulo-box-fancy-card">
                              {TREAT_TYPE_TO_NAME[key]}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="card-body row boxDown-fancy">
                        <div className="col-sm-12 col-lg-7">
                          <h5 className="titulo-boxDown-fancy-card text-start">
                            Descripción
                          </h5>
                          <p className="subtitulo-boxDown-fancy-card text-start">
                            Esta es una Fancy Mona original y única de Edgardo
                            Giménez, quien utilizó un algoritmo diseñado
                            específicamente para combinar diferentes accesorios,
                            expresiones y vestimentas logrando así hacer única a
                            cada Fancy Mona.
                          </p>
                        </div>
                        <div className="col-sm-12 col-lg-5 item-fancy-button">
                          <a
                            href={`https://artbag.io/es/gallery/${itemmona}`}
                            target="_blank"
                            className="btn btn-dark btn-lg btn-block button-vender mt-5"
                            type="button"
                            rel="noreferrer"
                          >
                            {isOwner
                              ? "Ver mi Fancy Mona NFT"
                              : "Ver Fancy Mona NFT"}
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>

            {/* REFERENCIAS HISTORICAS */}

            {Object.keys(treatContent).length !== 0 && (
              <section
                id="historic-section"
                className="d-flex flex-column align-items-center new container-lg container-md container-sm container-xs container-xl"
              >
                <div className="historic row pb-5">
                  <div className="col-sm-12 col-lg-6">
                    <h1 className="mb-3 titulo-ref-historica">
                      Referencias históricas{" "}
                      <span className="iconos-emoji-textos">🔎</span>
                    </h1>
                    <p className="lead-ref-historica col-sm-12 col-lg-10">
                      Descubre las referencias que fueron parte del surgimiento
                      de las Fancy Monas. Un recorrido por la obra de Edgardo
                      Giménez.
                    </p>
                    <div className="treeButtons-ref-historica d-md-flex justify-content-md-start py-3 col-lg-11 col-sm-12 mb-3">
                      <div
                        className="btn-group mb-2 box-button-ref-historica"
                        role="group"
                      >
                        {ACCESORIES_LIST.includes(metadata?.Ojos) && (
                          <>
                            <button
                              type="button"
                              onClick={() => onClickReference(TREAT_TYPES.Ojos)}
                              className={`button-ref-historica btn ${
                                treatTypeSelected === TREAT_TYPES.Ojos
                                  ? "btn-dark"
                                  : "btn-outline-dark"
                              } rounded-pill px-3`}
                            >
                              {`ACCESORIOS ${
                                treatTypeSelected === TREAT_TYPES.Ojos
                                  ? "🙉"
                                  : ""
                              }`}
                            </button>
                            <div className="linea-ref-historica"></div>
                          </>
                        )}
                        <button
                          type="button"
                          onClick={() => onClickReference(TREAT_TYPES.Sombrero)}
                          className={`button-ref-historica btn ${
                            treatTypeSelected === TREAT_TYPES.Sombrero
                              ? "btn-dark"
                              : "btn-outline-dark"
                          } rounded-pill`}
                        >
                          {`SOMBREROS Y PEINADOS ${
                            treatTypeSelected === TREAT_TYPES.Sombrero
                              ? "🙉"
                              : ""
                          }`}
                        </button>
                        <div className="linea-ref-historica"></div>
                        <button
                          type="button"
                          onClick={() => onClickReference(TREAT_TYPES.Traje)}
                          className={`button-ref-historica btn ${
                            treatTypeSelected === TREAT_TYPES.Traje
                              ? "btn-dark"
                              : "btn-outline-dark"
                          } rounded-pill px-3`}
                        >
                          {`TRAJES Y VESTIDOS ${
                            treatTypeSelected === TREAT_TYPES.Traje ? "🙉" : ""
                          }`}
                        </button>
                      </div>
                    </div>
                    <div className="is-small historic-carousel-ok historic-carousel my-4">
                      {treatContent && (
                        <Swiper
                          grabCursor={true}
                          pagination={{
                            clickable: true,
                          }}
                          autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                          }}
                          loop={true}
                          navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                          }}
                          modules={[
                            Navigation,
                            Pagination,
                            EffectCreative,
                            Autoplay,
                          ]}
                          className="mySwiper-items"
                        >
                          {treatContent?.images?.mobile?.map((img, id) => (
                            <SwiperSlide key={id}>
                              <img
                                className="carousel-image"
                                src={img.image}
                                alt="card"
                              />
                              <p className="parrafo-foto-historic">
                                {img.text}
                              </p>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      )}
                    </div>
                    {titleTreat && (
                      <h3 className="mb-3 subtitulo-ref-historica">
                        {titleTreat}
                      </h3>
                    )}
                    <div className="overlay-historic desktop">
                      <div className="overflow-parrafo">
                        <p className="sublead-ref-historica col-sm-12 col-lg-11 text-wrap">
                          {treatContent?.text}
                        </p>
                        <p id="parrafo-fin">-</p>
                      </div>
                    </div>
                    <p className="sublead-ref-historica col-12 tablets-mobile text-wrap">
                      {treatContent?.text}
                    </p>
                  </div>
                  <div className="is-no-small col-sm-12 col-lg-6">
                    <div className="historic-carousel-ok historic-carousel">
                      <Swiper
                        navigation={true}
                        grabCursor={true}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 4000, disableOnInteraction: false }}
                        loop={true}
                        modules={[
                          Navigation,
                          Pagination,
                          EffectCreative,
                          Autoplay,
                        ]}
                        className="mySwiper-items"
                      >
                        {treatContent?.images?.desktop?.map((img, id) => (
                          <SwiperSlide key={id}>
                            <img
                              className="carousel-image"
                              src={img.image}
                              alt="card"
                            />
                            <p className="parrafo-foto-historic">{img.text}</p>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {isOwner && (
              <section
                id="exclusive-section"
                className="d-flex flex-column align-items-center container-fluid"
              >
                <div className="exclusive">
                  <div className="col-12 exclusive-section-title">
                    <span className="badge rounded-pill text-bg-light exclusive-span border border-dark">
                      CONTENIDO EXCLUSIVO
                    </span>
                    <h1 className="mb-3 titulo-exclusive">
                      Dónde ver tu FAMO{" "}
                      <spam className="iconos-emoji-textos">💫</spam>
                    </h1>
                    <p className="lead-exclusive mb-3 ">
                      Descarga wallpapers de tu obra y ambienta tus espacios de
                      una manera totalmente innovadora.
                    </p>
                  </div>
                  <div className="col-12 columna-exclusive">
                    <Swiper
                      cssMode={true}
                      navigation={true}
                      pagination={{ clickable: true }}
                      modules={[Navigation, Pagination]}
                      className="mySwiper-items-exclusive"
                    >
                      <SwiperSlide>
                        <div className="d-flex justify-content-center align-content-center align-items-center flex-column">
                          <div className="col-12 text-center">
                            <img
                              className="imagen-exclusive "
                              src={Reloj}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-10 col-lg-3 text-center">
                            <h4 className="mb-3 titulo-slider-exclusive">
                              ¿TU SMARTWATCH SE VOLVIÓ ABURRIDO?
                            </h4>
                            <p className="lead-exclusive mb-3 ">
                              Descargate la obra y usala de fondo de pantalla en
                              tu smartwatch
                            </p>
                            <button
                              onClick={() =>
                                getExclusiveContent(
                                  itemNumber,
                                  "watchVersion",
                                  token
                                )
                              }
                              className="btn btn-dark btn-lg btn-block button-vender mb-5 w-100"
                              type="button"
                            >
                              Descargar
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="d-flex justify-content-center align-content-center align-items-center flex-column">
                          <div className=" col-12 text-center">
                            <img
                              className="imagen-exclusive "
                              src={Telefono}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-10 col-lg-3 text-center">
                            <h4 className="mb-3 titulo-slider-exclusive">
                              DISFRUTÁ TU FANCY EN TU SMARTPHONE
                            </h4>
                            <p className="lead-exclusive mb-3 ">
                              Descargate la obra y usala de fondo de pantalla en
                              tu smartphone
                            </p>
                            <button
                              onClick={() =>
                                getExclusiveContent(
                                  itemNumber,
                                  "phoneVersion",
                                  token
                                )
                              }
                              className="btn btn-dark btn-lg btn-block button-vender mb-5 w-100"
                              type="button"
                            >
                              Descargar
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="d-flex justify-content-center align-content-center align-items-center flex-column">
                          <div className="col-12 text-center">
                            <img
                              className="imagen-exclusive "
                              src={Tv}
                              alt=""
                            />
                          </div>
                          <div className="col-sm-10 col-lg-3 text-center">
                            <h4 className="mb-3 titulo-slider-exclusive">
                              LAS MONAS AHORA PUEDEN VIVIR EN TU CASA!
                            </h4>
                            <p className="lead-exclusive mb-3 ">
                              Descargate la obra y usala de fondo de pantalla en
                              tu Smart TV
                            </p>
                            <button
                              onClick={() =>
                                getExclusiveContent(
                                  itemNumber,
                                  "tvVersion",
                                  token
                                )
                              }
                              className="btn btn-dark btn-lg btn-block button-vender mb-5 w-100"
                              type="button"
                            >
                              Descargar
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </section>
                            )}

            {!isOwner && (
              <section
                id="exclusive-section"
                className="d-flex flex-column align-items-center container-fluid"
              >
                <div className="exclusive">
                  <div className="col-12 exclusive-section-title">
                    <span className="badge rounded-pill text-bg-light exclusive-span border border-dark">
                      CONTENIDO EXCLUSIVO
                    </span>
                    <h1 className="mb-3 titulo-exclusive">
                      Dónde ver tu FAMO{" "}
                      <spam className="iconos-emoji-textos">💫</spam>
                    </h1>
                    <p className="lead-exclusive mb-3 ">
                      Descarga wallpapers de tu obra y ambienta tus espacios de
                      una manera totalmente innovadora.
                    </p>
                  </div>
                  <div className="col-12 columna-exclusive">
                    <div className="is-small">
                      <div className="d-flex justify-content-center align-content-center align-items-center flex-column">
                        <div className="col-12 text-center">
                          <img
                            className="imagen-exclusive "
                            src={Telefono}
                            alt=""
                          />
                        </div>
                        <div className="col-sm-10 col-lg-3 text-center">
                          <SliderBullet />
                          <h4 className="mb-3 mt-3 titulo-slider-exclusive">
                            DISFRUTÁ TU FANCY EN TU SMARTPHONE
                          </h4>
                          <p className="lead-exclusive mb-3 ">
                            Descargate la obra y usala de fondo de pantalla en
                            tu smartphone
                          </p>
                          <button
                            className="btn btn-dark btn-lg btn-block button-vender mb-5 w-100"
                            type="button"
                            disabled
                          >
                            Descargar
                          </button>
                        </div>
                        <div className="overlay-exclusive">
                          <Blocked />
                          <p className="lead-overlay-exclusive mb-2 ">
                            Contenido exclusivo que se desbloquea al comprar
                            Fancy Monas.
                            <br /> Quieres ver mas?
                          </p>
                          <Link
                            className="button-checkout-dos"
                            onClick={() => setTermsModal(true)}
                          >
                            🐵 Quiero mi Fancy Mona
                          </Link>
                          <p className="enlace-overlay-exclusive mb-3 ">
                            <u onClick={() => setLoginModal(true)}>
                              Iniciar sesion
                            </u>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="is-no-small">
                      <div className="d-flex justify-content-center align-content-center align-items-center flex-column">
                        <div className="col-12 text-center">
                          <img
                            className="imagen-exclusive "
                            src={Reloj}
                            alt=""
                          />
                        </div>
                        <div className="col-sm-10 col-lg-3 text-center">
                          <SliderBullet />
                          <h4 className="mb-3 mt-3 titulo-slider-exclusive">
                            ¿TU SMARTWATCH SE VOLVIÓ ABURRIDO?
                          </h4>
                          <p className="lead-exclusive mb-3 ">
                            Descargate la obra y usala de fondo de pantalla en
                            tu smartwatch
                          </p>
                          <button
                            className="btn btn-dark btn-lg btn-block button-vender mb-5 w-100"
                            type="button"
                            disabled
                          >
                            Descargar
                          </button>
                        </div>
                        <div className="overlay-exclusive">
                          <Blocked />
                          <p className="lead-overlay-exclusive mb-2 ">
                            Contenido exclusivo que se desbloquea al comprar
                            Fancy Monas.
                            <br /> Quieres ver mas?
                          </p>
                          <Link
                            className="button-checkout-dos"
                            onClick={() => setTermsModal(true)}
                          >
                            🐵 Quiero mi Fancy Mona
                          </Link>
                          <p className="enlace-overlay-exclusive mb-3">
                            <u onClick={() => setLoginModal(true)}>
                              Iniciar sesion
                            </u>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/* <section
              id="whitelist-section"
              className="d-flex flex-column align-items-center container-lg container-md container-sm container-xs container-xl"
            >
              <div className="whitelist row ">
                <div className="col-sm-12 col-lg-7 whitelist-column">
                  <div className="whitelist-colrev">
                    <span className="badge rounded-pill text-bg-light whitelist-span border border-dark">
                      WHITELIST
                    </span>
                    <h1 className="mb-3 titulo-ref-historica">
                      Guarda tu lugar para el próximo lanzamiento de FaMo{" "}
                      <spam className="iconos-emoji-textos">💎</spam>
                    </h1>
                    <p className="lead-ref-historica col-sm-12 col-lg-10 whitelist-p">
                      Para ingresar a la lista de espera de Fancy Monas by
                      Edgardo Giménez déjanos tu correo electrónico y pronto te
                      enviaremos novedades!
                    </p>
                    <form onSubmit={(e) => handlSubscribeWhitelist(e)}>
                      <div
                        className="input-group whitelist-group"
                        style={subscribed ? styleSuccessBorder : {}}
                      >
                        <input
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={subscribed ? styleSuccessInput : {}}
                          name="email"
                          type="text"
                          className="form-control whitelist-input"
                          placeholder="Correo electrónico"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          autoComplete="off"
                        />
                        <button
                          className="btn btn-dark whitelist-button"
                          type="submit"
                          id="button-addon2"
                          style={subscribed ? styleSuccessIcon : {}}
                        >
                          {loading ? (
                            <LoadingAnimation />
                          ) : subscribed ? (
                            <FaCheck />
                          ) : (
                            "Inscribirme en la whitelist"
                          )}
                        </button>
                      </div>
                    </form>
                    {subscribed ? (
                      <p style={styleSuccessLabel}>
                        Email registrado correctamente
                      </p>
                    ) : null}
                    {!validEmail && (
                      <div
                        className="alert alert-danger whitelist-alert text-center"
                        role="alert"
                      >
                        Debe ingresar un email en formato adecuado.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-lg-5 column-historic">
                  <div className="historic-carousel-ok historic-carousel">
                    <div className="fondocuka d-flex justify-content-center align-items-center">
                      <p className="cukarda px-4">SOLD OUT</p>
                    </div>
                    <img
                      src={SiluetaMonas}
                      alt="SiluetaMona"
                      className="whitelist-image"
                    />
                  </div>
                </div>
              </div>
            </section> */}

            {/* ¿Querés saber más de FAMO? */}
            <section
              id="project-section"
              className="d-flex flex-column align-items-center container-lg container-md container-sm container-xs container-xl"
            >
              <div className="project row">
                <div className="col-sm-12 col-lg-7">
                  <div className="project-cont mb-5">
                    <span className="badge rounded-pill text-bg-light project-span border border-dark">
                      EL PROYECTO
                    </span>
                    <h1 className="mb-4 titulo-ref-historica">
                      ¿Querés saber más de FAMO?
                    </h1>
                    <a href="/deck_proyecto.pdf" target="_blank">
                      <button
                        className="btn btn-dark project-button my-2"
                        type="submit"
                        id="button-addon2"
                      >
                        Descargar el deck
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-5 d-flex gap-2 align-items-end justify-content-center justify-content-lg-end mg-monas-siluet-deck">
                  <img src={Mona1} alt="Mona1" className="Mona1" />
                  <img src={Mona2} alt="Mona2" className="Mona2" />
                  <img src={Mona3} alt="Mona3" className="Mona3 ms-3" />
                </div>
              </div>
            </section>

            <section
              id="timeline-section"
              className="d-flex flex-column align-items-center container-lg container-md container-sm container-xs container-xl"
            >
              <div className="timeline-bg tm-section d-flex flex-column justify-content-center align-items-center">
                <div className="timeline d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
                  <div className="timeline-left d-flex flex-column align-items-lg-start">
                    <h2 className="text-black">Novedades</h2>
                    <div className="timeline-left-container">
                      <div className="timeline-line"></div>
                      <div className="timeline-left-item">
                        <div className="d-flex flex-row align-items-center text-black">
                          <MonkeyIconDark />
                          Lanzamiento ARTEBA💥
                        </div>
                        <p className="text-black">
                          1, 2 Y 3 de Septiembre 2023, en la sección principal
                          A36
                        </p>
                      </div>
                      <div className="timeline-left-item">
                        <div className="d-none d-sm-flex flex-row align-items-center text-black">
                          <MonkeyIconDark />
                          Alianza Stanley ✨
                        </div>
                        <div className="d-flex d-sm-none flex-row align-items-center text-black">
                          <MonkeyIconDark />
                          Alianza Stanley
                        </div>
                        <p className="text-black">
                          Los primeros compradores se llevarán un termo
                          personalizado con su Fancy Mona
                        </p>
                      </div>
                      <div className="timeline-left-item">
                        <div className="d-flex flex-row align-items-center text-black">
                          <MonkeyIconDark />
                          Pre Sale 🍌
                        </div>
                        <p className="text-black">
                          80 monas seleccionadas por Edgardo Giménez.
                        </p>
                      </div>
                      <div className="timeline-left-item">
                        <div className="d-flex flex-row align-items-center text-black">
                          <MonkeyIconDark />
                          Lanzamiento 🙈
                        </div>
                        <p className="text-black">
                          7 - 10 de enero en Vik Pavillion en el marco de{" "}
                          <a
                            onClick={() => setTermsModal(true)}
                            rel="noopener noreferrer"
                            className="text-black"
                          >
                            Este Arte
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                    <Link
                      className="is-no-small"
                      onClick={() => setTermsModal(true)}
                    >
                      🐵 Quiero mi Fancy Mona
                    </Link>
                  </div>
                  <img src={Pavilion} alt="pavilion" />
                  <Link
                    className="is-small button-checkout"
                    onClick={() => setTermsModal(true)}
                  >
                    🐵 Quiero mi Fancy Mona
                  </Link>
                </div>
              </div>
            </section>
            <section
              id="noticias-section"
              className="d-flex flex-column align-items-center container-fluid"
            >
              <div className="noticias">
                <h1 className="mb-3 titulo-exclusive">
                  Noticias <spam className="iconos-emoji-textos">📢</spam>
                </h1>
                <p className="lead-exclusive mb-5 ">
                  Medios locales e internacionales hablar del evento, enterate
                  que dijeron del lanzamiento de Fancy Monas.
                </p>
                <div className="card-group gap-3">
                  <Swiper
                    pagination={{
                      clickable: true,
                    }}
                    spaceBetween={32}
                    className="mySwiper-collection mySwiper-collection-noticias"
                    modules={[Pagination]}
                    breakpoints={{
                      360: {
                        slidesPerView: 1.2,
                      },
                      425: {
                        slidesPerView: 1.3,
                      },
                      470: {
                        slidesPerView: 1.5,
                      },
                      500: {
                        slidesPerView: 1.6,
                      },
                      660: {
                        slidesPerView: 2.1,
                      },
                      718: {
                        slidesPerView: 2.2,
                      },
                      768: {
                        slidesPerView: 2.3,
                      },
                      860: {
                        slidesPerView: 2.6,
                      },
                      960: {
                        slidesPerView: 2.9,
                      },
                      1024: {
                        slidesPerView: 3.1,
                      },
                      1100: {
                        slidesPerView: 3.5,
                      },
                      1280: {
                        slidesPerView: 4.1,
                      },
                      1360: {
                        slidesPerView: 4.6,
                      },
                      1440: {
                        slidesPerView: 4.6,
                      },
                    }}
                  >
                    {news.map((content, index) => (
                      <SwiperSlide key={index}>
                        <div className="card cardContainer">
                          <a
                            href={content.url}
                            target="_blank"
                            className="href-t"
                            rel="noreferrer"
                          >
                            <div
                              className="card-img-top"
                              style={{
                                backgroundImage: `url(${content.imageURL})`,
                              }}
                            ></div>
                          </a>
                          <div className="card-body">
                            <a
                              href={content.url}
                              target="_blank"
                              className="href-t"
                              rel="noreferrer"
                            >
                              <h5 className="card-title cardTitle">
                                {content.titulo}
                              </h5>
                            </a>
                            <p className="card-text cardText">
                              {content.copete}
                            </p>
                          </div>
                          <div className="card-footer cardFooter py-3 d-flex justify-content-around align-items-center align-content-center">
                            <img
                              src={content.icon}
                              className="cardImageFooter"
                              alt="icon"
                            />
                            <p className="footerDate">{content.fecha}</p>
                            <div className="dot"></div>
                            <p className="footerSource">{content.medio}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </section>
            <section
              id="collection-aleatoria-section"
              className="d-flex flex-column align-items-center container-fluid"
            >
              <div className="collection-aleatoria">
                <div className="col-12 text-center">
                  <h1 className="mb-3 titulo-collection-aleatoria">
                    Colección{" "}
                    <spam className="is-no-small lead-collection-aleatoria">
                      {" "}
                      / Conocé otras integrantes{" "}
                    </spam>
                  </h1>
                  <spam className="is-small lead-collection-aleatoria mb-5">
                    {" "}
                    / Conocé otras integrantes{" "}
                  </spam>
                </div>
                <div
                  className="col-12 pt-4"
                  style={{ margin: "0 auto !important" }}
                >
                  <Swiper
                    noSwiping={true}
                    noSwipingClass={"swiper-slide"}
                    grabCursor={false}
                    effect={"creative"}
                    centeredSlides={true}
                    creativeEffect={{
                      prev: {
                        translate: ["-35%", 0, -500],
                      },
                      next: {
                        translate: ["35%", 0, -500],
                      },
                    }}
                    pagination={{
                      clickable: false,
                    }}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    modules={[Pagination, EffectCreative, Autoplay]}
                    className="mySwiper2"
                  >
                    {randomArtworks &&
                      randomArtworks?.map(
                        (
                          {
                            compressedImageUrl: randomArtworkImageUrl,
                            number: randomArtworkItemNumber,
                          },
                          index
                        ) => (
                          <SwiperSlide
                            className="d-flex justify-content-center"
                            key={index}
                          >
                            <a
                              href={`/items/${randomArtworkItemNumber}#start`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="card card-mi-coleccion">
                                <img
                                  className="item-image card-img-mi-coleccion"
                                  src={randomArtworkImageUrl}
                                  alt="card"
                                />
                                <div className="card-img-overlay ">
                                  <h5 className="card-title text-start "></h5>
                                  <p className="card-text text-end item-text-mi-coleccion">
                                    <span className="item-badge text-bg-light px-3 rounded-pill badge">
                                      #{randomArtworkItemNumber}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </a>
                          </SwiperSlide>
                        )
                      )}
                  </Swiper>

                  <Swiper
                    pagination={{
                      clickable: true,
                    }}
                    className="mySwiper-collection"
                    modules={[Pagination]}
                    breakpoints={{
                      360: {
                        slidesPerView: 1.1,
                      },
                      660: {
                        slidesPerView: 1.1,
                      },
                      760: {
                        slidesPerView: 1.2,
                      },
                      860: {
                        slidesPerView: 1.4,
                      },
                      960: {
                        slidesPerView: 1.5,
                      },
                      1060: {
                        slidesPerView: 1.7,
                      },
                      1160: {
                        slidesPerView: 1.9,
                      },
                      1260: {
                        slidesPerView: 2,
                      },
                      1360: {
                        slidesPerView: 2.1,
                      },
                      1460: {
                        slidesPerView: 2.2,
                      },
                      1660: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {randomArtworks &&
                      randomArtworks.map(
                        (
                          {
                            compressedImageUrl: randomArtworkImageUrl,
                            number: randomArtworkItemNumber,
                          },
                          index
                        ) => {
                          return (
                            <SwiperSlide key={index}>
                              <a
                                href={`/items/${randomArtworkItemNumber}#start`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div
                                  role="button"
                                  className="card card-mi-coleccion img-fluid shadow"
                                >
                                  <img
                                    className="item-image rounded card-img-mi-coleccion img-fluid"
                                    src={randomArtworkImageUrl}
                                    alt="card"
                                  />
                                  <div className="card-img-overlay img-fluid">
                                    <h5 className="card-title text-start "></h5>
                                    <p className="card-text text-end item-text-mi-coleccion">
                                      <span className="item-badge text-bg-light px-3 rounded-pill badge">
                                        #{randomArtworkItemNumber}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </SwiperSlide>
                          );
                        }
                      )}
                  </Swiper>
                  <div
                    id="botonmona"
                    className="col-12 d-flex justify-content-center mt-3"
                  >
                    <a
                      className="button-checkout-dos"
                      onClick={() => setTermsModal(true)}
                    >
                      {" "}
                      Comprar Fancy Monas
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <MainFooter />
    </Fragment>
  );
}
