import React from "react";

function User({ width, height, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83366 12.3334H13.167C15.4682 12.3334 17.3337 14.1988 17.3337 16.5C17.3337 16.9603 16.9606 17.3334 16.5003 17.3334C16.073 17.3334 15.7207 17.0117 15.6726 16.5972L15.6627 16.3531C15.5895 15.0894 14.5776 14.0775 13.3139 14.0043L13.167 14H4.83366C3.45295 14 2.33366 15.1193 2.33366 16.5C2.33366 16.9603 1.96056 17.3334 1.50033 17.3334C1.04009 17.3334 0.666992 16.9603 0.666992 16.5C0.666992 14.2594 2.43558 12.4318 4.65292 12.3372L4.83366 12.3334H13.167H4.83366ZM9.00033 0.666687C11.7617 0.666687 14.0003 2.90526 14.0003 5.66669C14.0003 8.42811 11.7617 10.6667 9.00033 10.6667C6.2389 10.6667 4.00033 8.42811 4.00033 5.66669C4.00033 2.90526 6.2389 0.666687 9.00033 0.666687ZM9.00033 2.33335C7.15938 2.33335 5.66699 3.82574 5.66699 5.66669C5.66699 7.50764 7.15938 9.00002 9.00033 9.00002C10.8413 9.00002 12.3337 7.50764 12.3337 5.66669C12.3337 3.82574 10.8413 2.33335 9.00033 2.33335Z"
        fill={color}
      />
    </svg>
  );
}

export default User;
