import React from "react";
import styled from "styled-components";

const StyledBadge = styled.div`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  span {
    color: #1d2734;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const BadgeCheckout = ({ children, icon }) => {
  return (
    <StyledBadge>
      {children}
      {icon}
    </StyledBadge>
  );
};

export { BadgeCheckout };
