import { useRef, useState, useEffect } from 'react';

const FilterDropdowns = () => {

    const [filterActive, setFilterActive] = useState(null);

    const filterHandle = (filterId) => {
      document.body.classList.toggle('no-scroll');

      if (filterActive === filterId) {
        setFilterActive(null);
      } else {
        setFilterActive(filterId);
      }
    };

    const dropdownRef = useRef(null);
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setFilterActive(null);
        }
      };

      document.addEventListener('click', handleOutsideClick);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);


    return {
        filterActive,
        dropdownRef,
        filterHandle
    };
}

export default FilterDropdowns;