import styled from "styled-components";

const StyledBtn = styled.button`
  display: flex;
  width: auto;
  min-width: 170px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  border: 0px;
`;

const RevealButton = ({ children, ...props }) => {
  return <StyledBtn {...props}>{children}</StyledBtn>;
};

export default RevealButton;
