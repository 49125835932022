import React from "react";

function OpenMona(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M11.5899 2.30981C16.6058 2.30981 20.6719 6.37599 20.6719 11.3919C20.6719 13.536 19.9289 15.5066 18.6863 17.0603L22.3946 20.7695C22.7887 21.1636 22.7887 21.8025 22.3946 22.1966C22.0308 22.5604 21.4584 22.5884 21.0626 22.2806L20.9675 22.1966L17.2583 18.4883C15.7046 19.7309 13.734 20.4739 11.5899 20.4739C6.57399 20.4739 2.50781 16.4078 2.50781 11.3919C2.50781 6.37599 6.57399 2.30981 11.5899 2.30981ZM11.5899 4.32805C7.68863 4.32805 4.52605 7.49064 4.52605 11.3919C4.52605 15.2931 7.68863 18.4557 11.5899 18.4557C15.4911 18.4557 18.6537 15.2931 18.6537 11.3919C18.6537 7.49064 15.4911 4.32805 11.5899 4.32805ZM11.5899 7.35541C12.1472 7.35541 12.599 7.80721 12.599 8.36453V10.3828H14.6172C15.1746 10.3828 15.6264 10.8346 15.6264 11.3919C15.6264 11.9492 15.1746 12.401 14.6172 12.401H12.599V14.4192C12.599 14.9766 12.1472 15.4284 11.5899 15.4284C11.0326 15.4284 10.5808 14.9766 10.5808 14.4192V12.401H8.56252C8.0052 12.401 7.55341 11.9492 7.55341 11.3919C7.55341 10.8346 8.0052 10.3828 8.56252 10.3828H10.5808V8.36453C10.5808 7.80721 11.0326 7.35541 11.5899 7.35541Z"
        fill="white"
      />
    </svg>
  );
}

export default OpenMona;
