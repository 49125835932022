import CheckoutHeader from "../../components/fancy-checkout/CheckoutHeader";
import BasicInfo from "../../components/fancy-checkout/BasicInfo";
import PersonalInfo from "../../components/fancy-checkout/PersonalInfo";
import { useContext, useState } from "react";
import Details from "./Details";
import { Counter } from "./Counter";
import { CartContext } from "../../context/Cart";
import { DescriptionCheckout } from "./Title";
import PenIcon from "../icons/PenIcon";
import { Button } from "../shared/Button";
import { Input } from "../shared/Input";
import DetailMona from "./DetailMona";
import "../../styles/fancy-checkout.scss";
import CheckIcon from "../icons/CheckIcon";

const Step3 = () => {
  const [editable, setEditable] = useState(true);
  const [editable2, setEditable2] = useState(true);
  const [qtyDisplay, setQtyDisplay] = useState(true);
  const [qtyMona, setQtyMona] = useState(true);
  const {
    addDisplay,
    removeDisplay,
    countDisplay,
    count,
    addItem,
    removeItem,
    price,
    priceDisplay,
  } = useContext(CartContext);
  const handleEdit1 = () => {
    setEditable(!editable);
  };
  const handleEdit2 = () => {
    setEditable2(!editable2);
  };
  const editQty = () => {
    setQtyMona(!qtyMona);
  };

  const editQtyDisplay = () => {
    setQtyDisplay(!qtyDisplay);
  };

  return (
    <div id="step3" className="checkout__form">
      <div className="checkout__form-text">
        <div className="info">
          <CheckoutHeader
            title={"Estas a un paso de tener tu Fancy Mona"}
            subtitle={
              "Este es un detallado de lo que vas a comprar y los datos que ingresaste"
            }
            isEditable={false}
          />
          <hr />
        </div>
        <div className="resume-details mt-4">
          <DetailMona title={"Fancy Mona"}>
            <h5>
              <strong>{price} USD</strong>
            </h5>
            <div className="d-flex align-items-center details-counter">
              <Counter
                addItem={addItem}
                removeItem={removeItem}
                count={count}
                disabledAdd={qtyMona}
                disabledRemove={qtyMona}
              />
              <Button
                color={qtyMona ? "text" : "primary"}
                variant={"outlined"}
                onClick={editQty}
                type={"button"}
              >
                {qtyMona ? <PenIcon /> : <CheckIcon />}
              </Button>
            </div>
            <DescriptionCheckout>
              Obra de arte digital creadas por Edgardo Giménez quien utilizó un
              algoritmo diseñado especialmente para combinar cada accesorio,
              expresión y vestimenta que hacen única cada Fancy Mona.
            </DescriptionCheckout>
          </DetailMona>
          <Details
            title="Print Fine Art"
            src={"/images/fancy_checkout/print-mona.png"}
          >
            <DescriptionCheckout>
              Impresión de original de 60 x 60 cm. + 10 cm. de margen blanco
              perimetral, sobre papel Hahnemühle Photo Rag UltraSmooth, 300 gr.
              (100% Cotton Linters - Archival, acid & lignin free). *Marco no
              incluido.
            </DescriptionCheckout>
            <div className="d-flex align-items-center details-counter">
              <div>
                <Input
                  className="text-center p-0 w-25"
                  type="text"
                  name="no-name"
                  value={count}
                  readOnly
                />
              </div>
            </div>
          </Details>
          <div className="detail-mona-display">
            <Details
              title="Display de 10''"
              src={"/images/fancy_checkout/display.png"}
            >
              <h5>
                <strong>{priceDisplay} USD</strong>
              </h5>
              <div className="d-flex align-items-center details-counter">
                <Counter
                  addItem={addDisplay}
                  removeItem={removeDisplay}
                  count={countDisplay}
                  disabledAdd={qtyDisplay}
                  disabledRemove={qtyDisplay}
                />
                <Button
                  color={qtyDisplay ? "text" : "primary"}
                  variant={"outlined"}
                  onClick={editQtyDisplay}
                >
                  {qtyDisplay ? <PenIcon /> : <CheckIcon />}
                </Button>
              </div>
              <DescriptionCheckout>
                Pantalla con Tecnología IPS / TFT LCD. Resolución 1024 x 600.
                Batería 1500 MAH - 2 h de autonomía -. Posibilidad de utilizarla
                conectada. 256 MB de memoria. Botón on / off. Archivos mp4 o JPG
                - Tamaño: 24 cm x 15 cm
              </DescriptionCheckout>
            </Details>
          </div>
        </div>
        <div className="info mt-5">
          <CheckoutHeader
            title={"Resumen de tus datos"}
            subtitle={"Chequea los datos ingresados antes de comprar"}
            btnText={"Editar datos"}
            isEditable={true}
            modeEdit={editable}
            onClick={handleEdit1}
          />
          <hr />
        </div>
        <BasicInfo edit={editable} />
        <div className="info mt-5">
          <CheckoutHeader
            title={"Dirección de envío"}
            subtitle={
              "Los envíos son gratis dentro de Argentina. Los envíos al exterior tienen un costo que queda a cargo del comprador"
            }
            btnText={"Editar datos"}
            isEditable={true}
            modeEdit={editable2}
            onClick={handleEdit2}
          />
          <hr />
        </div>
        <PersonalInfo edit={editable2} />
      </div>
    </div>
  );
};

export default Step3;
