import React from "react";

function HistoryIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 16 16"
    >
      <g fill={color} clipPath="url(#clip0_2341_7611)">
        <path d="M16 8.557c-.04.267-.065.536-.12.8-.577 2.778-2.197 4.672-4.876 5.6-2.997 1.04-6.426-.13-8.215-2.745-.222-.325-.162-.721.142-.942.307-.222.713-.15.947.177.86 1.204 1.999 2.001 3.433 2.368 3.213.822 6.537-1.251 7.22-4.5.653-3.111-1.166-6.133-4.232-6.984-3.206-.89-6.466 1.032-7.306 4.186-.008.03-.013.059-.023.11.35-.235.68-.462 1.015-.68a.645.645 0 01.747.01c.235.17.32.403.268.685a.615.615 0 01-.27.405c-.737.499-1.473 1-2.215 1.49-.346.23-.732.134-.973-.23C1.026 7.527.514 6.744 0 5.963v-.406c.24-.618.992-.626 1.287-.063.114.22.27.416.418.638.02-.056.034-.09.043-.124.427-1.521 1.249-2.788 2.492-3.761C5.892.952 7.769.439 9.849.754a7.022 7.022 0 013.708 1.766c1.24 1.117 2.02 2.498 2.32 4.143.05.275.083.554.123.831v1.063z"></path>
        <path d="M9.336 6.129c0 .468.005.937-.003 1.405a.411.411 0 00.137.326c.557.554 1.108 1.114 1.66 1.674a.641.641 0 01.169.69.631.631 0 01-.519.441.622.622 0 01-.574-.176c-.677-.678-1.35-1.36-2.022-2.042A.661.661 0 018 7.963v-3.31c.002-.393.283-.687.655-.692.382-.005.673.282.68.684.006.495 0 .99.001 1.484z"></path>
      </g>
      <defs>
        <clipPath id="clip0_2341_7611">
          <path
            fill="#fff"
            d="M0 0H16V14.671H0z"
            transform="translate(0 .664)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default HistoryIcon;
