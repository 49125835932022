import ImageDetail from "./ImageDetail";

const DetailMona = ({ title, children }) => {
  return (
    <div className="details">
      <ImageDetail src={"/images/fancy_checkout/digital-art-checkout.svg"} />
      <div className="details__info">
        <h3>{title}</h3>
        {children}
      </div>
    </div>
  );
};

export default DetailMona;
