import React from "react";

function Logo(props) {

  const { fillColor = 'white' } = props;

  return (
    <svg width="90" height="67" viewBox="0 0 90 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2013_34208)">
        <path d="M5.13021 10.9358H10.4728V14.9835H5.13021V24.681H0.150391V0.363831H12.0699V4.62872H5.13021V10.9358Z" fill={fillColor}/>
        <path d="M23.8838 0.36377L29.3725 24.6782H24.3562L23.3748 19.4289H18.5778L17.5964 24.6782H13.1621L18.7212 0.36377H23.881H23.8838ZM21.4853 9.00356C21.1591 7.54526 21.086 6.78085 20.9763 4.92201C20.757 6.52699 20.6136 7.6186 20.3942 8.78637L19.0867 15.494H22.8293L21.4853 9.00638V9.00356Z" fill={fillColor}/>
        <path d="M33.0391 0.363831H37.9092L41.8712 11.5197C42.1973 12.5041 42.4898 13.7057 43.0353 16.2951C42.889 14.2896 42.6725 10.5719 42.6725 8.93028V0.363831H46.7076V24.681H42.6725L38.092 12.2502C37.583 10.9019 37.1838 9.44365 36.6748 7.25479C36.8576 9.58751 37.0741 13.4885 37.0741 14.4363V24.681H33.0391V0.363831Z" fill={fillColor}/>
        <path d="M61.7481 9.73422C61.6019 5.79653 60.8765 4.33823 58.9869 4.33823C56.9145 4.33823 56.2987 6.30708 56.2987 12.9414C56.2987 18.9579 56.8808 20.7067 58.9166 20.7067C60.7696 20.7067 61.4248 19.6123 61.751 15.858L65.966 16.1852C65.7832 18.8451 65.3839 20.45 64.5488 21.8717C63.2413 24.0577 61.4248 25.0421 58.7704 25.0421C53.6106 25.0421 51.0293 20.8873 51.0293 12.5747C51.0293 4.26207 53.7877 0 58.9138 0C61.8578 0 64.0005 1.34829 65.0915 3.82768C65.6735 5.17598 65.9631 6.70762 66.1459 9.37035L61.7481 9.73422Z" fill={fillColor}/>
        <path d="M73.3727 14.6901L68.5391 0.36377H73.7354L75.8809 8.09248C76.1705 9.15024 76.2801 10.2418 76.3167 11.9568C76.4264 10.2785 76.6063 9.26025 76.9353 8.09248L79.2607 0.36377H83.7316L78.3525 14.6901V24.6782H73.3727V14.6901Z" fill={fillColor}/>
        <path d="M0 29.0981H6.83004L8.68306 39.34C9.04579 41.3822 9.30167 43.7149 9.44508 46.3015C9.59129 44.1127 9.99058 41.1622 10.463 38.8295L12.5325 29.0953H19.2191V53.4069H14.4586V39.5572C14.4586 37.8451 14.4952 37.4417 14.7145 32.1952L14.4249 34.2346C14.3152 35.1823 14.169 36.0934 14.0256 37.044C13.8794 38.0989 13.6966 39.12 13.6601 39.34L10.7189 53.4097H7.59486L4.7605 39.1228C4.47088 37.6645 4.2881 36.2429 3.81571 32.198C3.81571 32.6324 3.88882 35.1852 3.96193 38.9762V53.4097H0V29.0981Z" fill={fillColor}/>
        <path d="M38.9032 41.4557C38.9032 49.9122 36.5046 53.7765 31.2718 53.7765C26.0389 53.7765 23.75 49.695 23.75 41.1285C23.75 32.5621 26.1851 28.7344 31.4911 28.7344C36.5046 28.7344 38.9032 32.8526 38.9032 41.4557ZM29.0194 41.309C29.0194 47.7261 29.5284 49.4383 31.3449 49.4383C33.271 49.4383 33.6337 48.0533 33.6337 40.9452C33.6337 34.8214 33.1248 33.0726 31.3814 33.0726C29.489 33.0726 29.0194 34.7848 29.0194 41.3119V41.309Z" fill={fillColor}/>
        <path d="M43.2363 29.0981H48.1037L52.0628 40.2512C52.3918 41.2356 52.6814 42.44 53.2269 45.0266C53.0835 43.0211 52.8642 39.3034 52.8642 37.6618V29.0981H56.8964V53.4097H52.8642L48.2865 40.9789C47.7775 39.6306 47.3782 38.1723 46.8693 35.9863C47.052 38.319 47.2714 42.2172 47.2714 43.1678V53.4097H43.2391V29.0981H43.2363Z" fill={fillColor}/>
        <path d="M70.1681 29.0981L75.654 53.4097H71.439L69.6591 48.1632H64.8621L63.8807 53.4097H59.4492L65.0083 29.0981H70.1681ZM67.7695 37.7351C67.4406 36.2768 67.3703 35.5124 67.2606 33.6536C67.0441 35.2585 66.8979 36.3502 66.6814 37.5179L65.3738 44.2255H69.1164L67.7724 37.7351H67.7695Z" fill={fillColor}/>
        <path d="M80.6874 59.8862C80.4934 59.8862 80.2966 59.8806 80.1025 59.8665C72.5133 59.3616 70.2919 52.3183 69.125 45.4979L73.669 44.6968C73.7955 45.4302 74.0177 46.1128 74.1554 46.7446C75.7807 54.1913 78.2214 55.0121 80.4147 55.1588C82.0006 55.28 83.4655 54.4761 84.3232 53.0517C85.2933 51.4383 85.2258 49.4186 84.1404 47.6472C83.1787 46.0761 81.5366 44.6686 80.0885 43.4303C78.9131 42.4205 77.8952 41.5517 77.2401 40.6068C75.702 38.3897 75.4939 35.3349 76.7142 32.8301C77.8924 30.4071 80.2122 28.8755 82.9172 28.7288C85.9765 28.5286 87.9983 30.1674 89.1287 32.1052C89.9778 33.5635 89.0274 35.4195 87.3544 35.6057C86.4377 35.7072 85.5519 35.2559 85.0908 34.452C84.7506 33.8597 84.1657 33.3548 83.1731 33.4422C81.8825 33.5099 81.2498 34.2743 80.9405 34.9005C80.4653 35.8793 80.53 37.0894 81.1008 37.913C81.4101 38.3587 82.2902 39.1118 83.1422 39.8424C84.7731 41.2358 86.8004 42.9733 88.1473 45.1791C90.169 48.4822 90.245 52.3381 88.347 55.4888C86.6936 58.239 83.7973 59.8834 80.6846 59.8834L80.6874 59.8862Z" fill={fillColor}/>
      </g>
      <defs>
        <clipPath id="clip0_2013_34208">
          <rect width="89.7184" height="67" fill={fillColor}/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;