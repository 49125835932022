import styled from "styled-components";
import { Breadcrumb as BootstrapBreadcrumb } from "react-bootstrap";
import { useParams } from "react-router-dom";

const StyledBreadcrumb = styled(BootstrapBreadcrumb)`
  li {
    color: #5a5a5a;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    cursor: text;
    text-decoration: none;
  }
  li.active {
    color: #000;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    text-transform: uppercase;
    cursor: text;
    text-decoration: none;
  }
  li a {
    color: #000;
    text-decoration: none;
    cursor: text;
    text-decoration: none;
  }
  /* .breadcrumb-item + .breadcrumb-item::before {
    content: "/";
    padding-right: 0.5rem;
  } */
`;

const Breadcrumb = (props) => {
  const { slug } = useParams();

  return (
    <StyledBreadcrumb as={"div"}>
      <div>
        {props.crumbs.map((crumb, ci) => {
          return (
            <BootstrapBreadcrumb.Item
              key={ci}
              className={`btn btn-link ${
                slug === crumb && "fw-bold"
              } breadcrumb-item align-items-center ${
                slug !== crumb && "disabled"
              }`}
            >
              {crumb}
            </BootstrapBreadcrumb.Item>
          );
        })}
      </div>
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
