import "./revealTemplate.scss";

const RevealTemplate = ({ heading, footer, children }) => {
  return (
    <div id="reveal-template">
      <div className="reveal-wrapper">
        <div className="area1">{heading}</div>
        <div className="area2">
          <div className="reveal-content">{children}</div>
        </div>
        <div className="area3">
          <div className="reveal-footer">{footer}</div>
        </div>
      </div>
    </div>
  );
};

export default RevealTemplate;
