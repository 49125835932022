import { Form, Formik } from "formik";
import "../../styles/sellersLogin.scss";
import { useContext, useState } from "react";
import { Input } from "../../components/shared/Input";
import { Label } from "../../components/shared/Label";
import { sellersLogin } from "../../services/axiosService";
import routes from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/shared/Spinner";
import { CartContext } from "../../context/Cart";

const SellersLogin = () => {
  const [loader, setLoader] = useState(false);
  const [msgError, setMsgErr] = useState(false);
  const { pushUser } = useContext(CartContext);
  const navigate = useNavigate();
  const [initialValues] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (values) => {
    try {
      setLoader(true);
      const response = await sellersLogin(values);
      localStorage.setItem("token", response.data.accessToken);
      pushUser(response.data);
      setTimeout(() => {
        navigate(routes.comercial_checkout);
      }, 1000);
    } catch (error) {
      setLoader(false);
      setMsgErr(true);
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <div id="sellers-login">
      <div className="wrapper-sellers-login">
        <div className="login-col1 container">
          <div className="headline">
            <h6>Login</h6>
            <hr />
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ isValid }) => (
              <>
                <Form>
                  <div className="row">
                    <span className="login-title">
                      Ingresa los datos de inicio de sesión
                    </span>
                    <div className="col-12">
                      <Label htmlFor="email" className="form-label">
                        Email *
                      </Label>
                      <Input type="text" name="email" />
                    </div>
                    <div className="col-12 mt-3">
                      <Label htmlFor="password" className="form-label">
                        Contraseña *
                      </Label>
                      <Input type="password" name="password" />
                    </div>
                    <div className="col-xs-12 mt-4">
                      <button
                        type="submit"
                        className="btn-login"
                        disabled={loader}
                      >
                        {loader && <Spinner />} Iniciar sesión
                      </button>
                    </div>
                    {msgError && (
                      <div className="col-xs-12 mt-4">
                        <span className="text-danger">
                          No hemos podido iniciar sesión. Prueba de nuevo.
                        </span>
                      </div>
                    )}
                  </div>
                </Form>
              </>
            )}
          </Formik>
          <div></div>
        </div>
        <div className="login-col2">
          <img src="/images/sellers-login/login.png" alt="login" />
        </div>
      </div>
    </div>
  );
};

export default SellersLogin;
