import React from "react";

function Blocked(props) {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="44" height="44" rx="22" fill="black" />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M22.5 12C25.1888 12 27.3818 14.1223 27.4954 16.7831L27.5 17V20C29.1569 20 30.5 21.3431 30.5 23V29C30.5 30.6569 29.1569 32 27.5 32H17.5C15.8431 32 14.5 30.6569 14.5 29V23C14.5 21.3431 15.8431 20 17.5 20V17C17.5 14.2386 19.7386 12 22.5 12ZM27.5 22H17.5C16.9477 22 16.5 22.4477 16.5 23V29C16.5 29.5523 16.9477 30 17.5 30H27.5C28.0523 30 28.5 29.5523 28.5 29V23C28.5 22.4477 28.0523 22 27.5 22ZM22.6763 14.0051L22.5 14C20.9023 14 19.5963 15.2489 19.5051 16.8237L19.5 17V20H25.5V17C25.5 15.4023 24.2511 14.0963 22.6763 14.0051L22.5 14L22.6763 14.0051Z"
        fill="white"
      />
    </svg>
  );
}

export default Blocked;
