import Search from "../icons/Search";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Details = ({ title, children, src }) => {
  return (
    <>
      <div className="details">
        <div className="imgPrint">
          <div className="icon-zoom">
            <Search />
          </div>
          <Zoom classDialog="custom-zoom">
            <img className="image-detail" src={src} alt="print" />
          </Zoom>
        </div>
        <div className="details__info">
          <h3>{title}</h3>
          {children}
        </div>
      </div>
    </>
  );
};

export default Details;
