import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import routes from "../../routes/routes";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "./Button";
import { AppContext } from "../../App";
import Logo from "../icons/Logo";
import ArrowDiagonal from "../icons/ArrowDiagonal";
import InputSearchMonas from "./InputSearchMonas";
import ModalContainer from "../modals/ModalContainer";
import Login from "./Login";
import { login } from "../../services/axiosService";
import ModalTerms from "../modals/ModalTerms";

export default function Navbar() {
  const { setToken, loginModal, setLoginModal } = React.useContext(AppContext);

  const navigate = useNavigate();

  const dropdownRef = useRef(null);
  const navbarRef = useRef(null);
  const dropdownHandle = () => {
    const dropdownElement = dropdownRef.current;
    const navbarElement = navbarRef.current;
    document.body.classList.toggle("no-scroll");
    dropdownElement.classList.toggle("show");
    navbarElement.classList.toggle("hidden");
  };

  const closeSession = () => {
    localStorage.removeItem("token");
    navigate(routes.home);
    setToken(null);
    handleCloseMenuFromItem();
    /* sessionStorage.removeItem("token");
    navigate("/landing"); */
  };

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const handleCloseMenuFromItem = () => {
    const dropdownElement = dropdownRef.current;
    const navbarElement = navbarRef.current;
    document.body.classList.toggle("no-scroll");
    dropdownElement.classList.toggle("show");
    navbarElement.classList.toggle("hidden");
  };

  const isUser = localStorage.getItem("token");

  return (
    <>
      <ModalContainer>
        {loginModal ? <Login handleLogin={login} /> : null}
      </ModalContainer>
      <nav>
        <div
          className="navbar-monas d-flex flex-lg-column justify-content-between align-items-center flex-row"
          ref={navbarRef}
          onClick={dropdownHandle}
        >
          <div className="navbar-top" onClick={handleCloseMenuFromItem}>
            <Link to={routes.home}>
              <Logo fillColor="black" />
            </Link>
          </div>
          <div className="navbar-bottom">
            <ArrowDiagonal fillColor={"#000"} />
          </div>
        </div>
        <div
          className="navbar-monas-dropdown d-flex flex-column justify-content-between"
          ref={dropdownRef}
        >
          <div className="navbar-top">
            <div className="navbar-top-wrapper">
              <div className="navbar-inside-top d-flex flex-column">
                <img src="/images/fancy-monas.svg" alt="fancy-monas" />
                <img src="/images/byAuthor.svg" alt="author" />
              </div>
              <div className="d-sm-block d-block d-md-block d-xl-none d-lg-none">
                <button className="btn-navbar-close">
                  <img
                    src={"/images/icons/close-icon.svg"}
                    onClick={dropdownHandle}
                    alt="close"
                  />
                </button>
              </div>
            </div>
            {isUser && (
              <ul className="my-collectionLink d-sm-block d-block d-md-block d-xl-none d-lg-none">
                <Link to={routes.my_coleccion}>
                  <Button fullwidth onClick={handleCloseMenuFromItem}>
                    MI COLECCIÓN
                  </Button>
                </Link>
              </ul>
            )}
            <InputSearchMonas />
            <ul className="links d-flex flex-column mt-5">
              <Link to={routes.coleccion}>
                <li onClick={handleCloseMenuFromItem} className="content">
                  LA COLECCIÓN <ArrowDiagonal fillColor="white" />
                </li>
              </Link>
              <Link to={routes.archive}>
                <li onClick={handleCloseMenuFromItem} className="content">
                  REFERENCIAS HISTÓRICAS <ArrowDiagonal fillColor="white" />
                </li>
              </Link>
              <Link to={routes.algoritmo}>
                <li onClick={handleCloseMenuFromItem} className="content">
                  DESARROLLO ALGORÍTIMICO <ArrowDiagonal fillColor="white" />
                </li>
              </Link>
              <Link to={routes.the_artist} className="content">
                <li onClick={handleCloseMenuFromItem}>
                  SOBRE EL ARTISTA <ArrowDiagonal fillColor="white" />
                </li>
              </Link>
              <li className="content" onClick={() => setLoginModal(true)}>
                INICIAR SESIÓN <ArrowDiagonal fillColor="white" />
              </li>
              <Link to={routes.redeem}>
                <li
                  className="content link-redeem"
                  onClick={handleCloseMenuFromItem}
                >
                  REDIMIR CÓDIGO <ArrowDiagonal fillColor="white" />
                </li>
              </Link>
              {isUser && (
                <li
                  className="content link-redeem text-danger d-sm-block d-block d-md-block d-xl-none d-lg-none"
                  onClick={closeSession}
                >
                  CERRAR SESIÓN
                </li>
              )}
            </ul>
          </div>
          <div className="navbar-bottom">
            <div className="d-flex flex-lg-row flex-column justify-content-between">
              <div>
                <button className="btn-navbar-close">
                  <img
                    src={"/images/icons/close-icon.svg"}
                    onClick={dropdownHandle}
                    alt="close"
                  />
                </button>
              </div>
              <div>
                <button className="mx-2">
                  <a
                    href="https://www.instagram.com/fancymonas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={28}
                      src={"/images/icons/ic24-instagram.svg"}
                      alt="instagram"
                    />
                  </a>
                </button>
                <button>
                  <a
                    href="https://twitter.com/FancyMonas"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={28}
                      src={"/images/icons/x-logo.svg"}
                      alt="twitter"
                    />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
