import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getHistorySales,
  getListToRevelated,
  postSendReveal,
} from "../../services/axiosService";
import { ToastContainer, toast } from "react-toastify";
import routes from "../../routes/routes";
import ModalTerms from "../../components/modals/ModalTerms";
import { Button } from "react-bootstrap";
import Spinner from "../../components/shared/Spinner";
import { CartContext } from "../../context/Cart";
import RevealTemplate from "../../templates/RevealTemplate";
import HeadingHr from "../../components/shared/HeadingHr";
import RevealButton from "../../components/shared/ButtonReveal";
import CustomDropdown from "../../components/shared/Dropdown";
import User from "../../components/icons/User";
import ArrowTop from "../../components/icons/ArrowTop";
import ListIcon from "../../components/icons/ListIcon";
import HistoryIcon from "../../components/icons/HistoryIcon";
import LogOutIcon from "../../components/icons/LogOutIcon";
import "./RevelatedQueue.scss";
import ModalHistorySales from "../../components/modals/ModalHistorySales";

const RevelatedQueue = () => {
  const [loader, setLoader] = useState(true);
  const [list, setList] = useState();
  const [reveal, setReveal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const [selectId, selectedId] = useState();
  const navigate = useNavigate();
  const { saveNumberMonas, user } = useContext(CartContext);
  const [historySalesShowModal, setHistorySalesShowModal] = useState(false);
  const [historySales, setHistorySales] = useState({});

  useEffect(() => {
    const requestHistorySales = async () => {
      await getHistorySales()
        .then((response) => {
          setHistorySales(response.data);
        })
        .catch((error) => {});
    };
    requestHistorySales();
  }, []);

  useEffect(() => {
    setLoader(true);
    const getList = async () => {
      try {
        const response = await getListToRevelated();
        setList(response.data.toRevelated);
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    };
    getList();
  }, []);

  const selectUser = async (saleId, qty) => {
    setSelected(saleId);
    selectedId(saleId);
    saveNumberMonas(qty);
  };

  const startVideoReveal = async () => {
    const notify = (text) => toast.error(text);

    const data = {
      saleId: selectId,
    };
    try {
      const response = await postSendReveal(data);
      if (response.data.toReveal) {
        setReveal(true);
        navigate(`${routes.revelated_list}/reveal`);
      } else {
        setReveal(false);
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
      setTermsModal(true);
      /* notify(error.message); */
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate(routes.sellers_login);
  };

  return (
    <>
      <ModalHistorySales
        modal={historySalesShowModal}
        setModal={setHistorySalesShowModal}
        title={"Historial de ventas"}
        subtitle={"Resumen histórico Fancy Monas vendidas"}
      >
        <div id="table">
          <div class="seller-header">
            <div>Vendedor</div>
          </div>
          <div class="client-header">
            <div>Cliente</div>
          </div>
          {historySales?.sales?.map((item, index) => {
            return (
              <>
                <div class="seller-col">{item.sellerEmail}</div>
                <div class="client-col">
                  <div class="primary-data">
                    <div class="name">{item.name ?? ''}</div>
                    <div class="email">{item.userEmail ?? ''}</div>
                    <div class="phone">{item.phone ?? ''}</div>
                    <div class="artworks">
                      {item.artworks?.map((fancymona, index) => {
                        return (
                          <div class="fancymona">
                            <img
                              src={fancymona.compressedImageUrl}
                              alt="mona"
                            />
                            <span>#{fancymona.number}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div class="secondary-data">

                    { item?.currency?.length > 0 && item?.signal?.length > 0
                      ? <div class="signal">{item.currency ?? ''} {item.signal ?? ''}</div>
                      : <div class="signal">-</div>
                    }
                    
                    <div class="payment_method">Metodo de pago: {item.paymentMethod}</div>

                    { item.additionalIndications.length > 0
                      ? <div class="comment">Comentario: {item.additionalIndications ?? ''}</div>
                      : <div class="comment">-</div>
                    }
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </ModalHistorySales>
      <RevealTemplate
        heading={
          <div className="heading-dropdown">
            <HeadingHr heading={"Pago"} />
            <CustomDropdown
              text={user?.email ?? "Opciones"}
              startIcon={<User width={15} height={15} color={"#89CEF8"} />}
              endIcon={<ArrowTop width={20} height={20} color={"#000"} />}
              items={[
                {
                  name: (
                    <div className="d-flex align-items-center custom-dropdown-gap">
                      <ListIcon width={15} height={15} color={"#000"} /> Cola de
                      revelado
                    </div>
                  ),
                  click: () => navigate(routes.revelated_list),
                },
                {
                  name: (
                    <div className="d-flex align-items-center">
                      <HistoryIcon width={15} height={15} color={"#000"} />{" "}
                      Historial de ventas
                    </div>
                  ),
                  click: () => setHistorySalesShowModal(true),
                },
                {
                  name: (
                    <div className="d-flex align-items-center custom-dropdown-gap">
                      <LogOutIcon width={15} height={15} color={"#dc3545"} />{" "}
                      <span className="text-danger">Desconectar</span>
                    </div>
                  ),
                  click: logout,
                },
              ]}
            />
          </div>
        }
        footer={
          <>
            <RevealButton
              color="seconadry"
              onClick={() => navigate(routes.comercial_checkout)}
            >
              Seguir comprando
            </RevealButton>
            <RevealButton
              color="secondary"
              onClick={startVideoReveal}
              disabled={!selected}
            >
              Revelar Monas
            </RevealButton>
          </>
        }
      >
        {loader ? (
          <Spinner />
        ) : (
          <div className="content-list">
            <div className="content-list-wrapper">
              {list?.length > 0 && (
                <>
                  <h5 className="title">Ventas confirmadas</h5>
                </>
              )}
              <div>
                <span className="text">
                  {list?.length} Compradores de Fancy Monas esperando el
                  revelado
                </span>
                <ul>
                  {list?.length > 0 ? (
                    list.map((m) => (
                      <li
                        key={m.artworkSaleId}
                        className={`ul-list ${
                          selected === m.artworkSaleId && "li-selected"
                        }`}
                        onClick={() => selectUser(m.artworkSaleId, m.quantity)}
                      >
                        <span>{m.userEmail}</span>
                        <span>({m.quantity})</span>
                      </li>
                    ))
                  ) : (
                    <h4 className="text-danger mb-5">
                      Todavía no hay usuarios con monas para revelar.
                    </h4>
                  )}
                </ul>
              </div>
            </div>
            {isError && (
              <ModalTerms
                termsModal={termsModal}
                setTermsModal={setTermsModal}
                title={"¡Hay un revelado en curso!"}
                subtitle={
                  "Una vez finalizado el revelado actual podrás iniciar el tuyo"
                }
                isClosable={() => setTermsModal(!termsModal)}
              >
                <p className="mb-3">
                  Tienes que esperar a que el proceso de revelado actual
                  termine. Cierra este modal y espera unos minutos.
                  <br />
                  Una vez finalizado el revelado actual podrás iniciar el tuyo.
                  <br /> Si hay algún tipo de error prueba a clicar el botón y
                  acabar algún posible proceso que se haya quedado abierto.
                </p>
                <Button
                  variant="danger"
                  onClick={() => navigate(`${routes.revelated_list}/reveal`)}
                >
                  Ir a sesión de revelado
                </Button>
              </ModalTerms>
            )}
          </div>
        )}
      </RevealTemplate>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
};

export default RevelatedQueue;
