import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  checkCode,
  checkArtwork,
  getAvailableArtworks,
} from "../../services/axiosService";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App.js";
import CheckNok from "../icons/CheckNok";
import CheckOk from "../icons/CheckOk";
import MonkeyDiscoverAlt from "../icons/MonkeyDiscoverAlt";
import routes from "../../routes/routes";

export default function RedeemComponent({
  codes,
  setCodes,
  setPrintedEnabled,
  setIsLoading,
  setRedeemScreen,
  setData,
  initialCredentialsPrinted,
  redeemScreen,
  setError,
}) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { token, setToken } = React.useContext(AppContext);

  var navigate = useNavigate();

  const [redeemCode, setRedeemCode] = useState("");
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);
  const [isNftStock, setIsNftStock] = useState(true);

  const handleChange = (e) => {
    e.preventDefault();
    setRedeemCode(e.target.value);
  };

  const setCode = async () => {
    setErrorCode(false);
    setWrongCode(false);
    if (redeemCode !== "") {
      if (codes.find((obj) => obj.id === redeemCode)) {
        setErrorCode(true);
        setRedeemCode("");
      } else {
        setIsCodeLoading(true);
        var arrayCodes = codes;
        await checkCode(redeemCode)
          .then((response) => {
            if (response.data.includesPrinted) {
              setPrintedEnabled(true);
              setData(initialCredentialsPrinted);
            }
            if (response.data.isUsed) {
              setErrorCode(true);
            } else {
              arrayCodes.push({
                id: redeemCode,
                printed: response.data.includesPrinted,
                accepted: true,
              });
            }
            setCodes(arrayCodes);
          })
          .catch((error) => {
            //arrayCodes.push({ id: redeemCode, printed: false, accepted: false })
            //setCodes(arrayCodes);
            setWrongCode(true);
          })
          .finally(() => setIsCodeLoading(false));
        setRedeemCode("");
        setCodes(arrayCodes);
      }
    }
  };

  const nextScreen = async () => {
    var acceptedCodes = [];
    for (var i = 0; i < codes.length; i++) {
      if (codes[i].accepted) acceptedCodes.push(codes[i].id);
    }
    try {
      const response = await checkArtwork(acceptedCodes.length);
      if (response.status === 200) {
        setRedeemScreen(1);
        localStorage.setItem("numMonas", codes.length);
      }
    } catch (error) {
      setIsNftStock(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const checkArtwork = async () => {
      if (redeemScreen === 0 && token) {
        await getAvailableArtworks(token)
          .then((response) => {
            if (response.data.quantity !== 0) {
              navigate(routes.home);
            } else {
              setToken(null);
              localStorage.removeItem("token");
            }
          })
          .catch((error) => {
            setError(true);
          });
      }
    };
    checkArtwork();
  }, []);

  return (
    <section className="redeem d-flex flex-column flex-lg-row justify-content-end justify-content-lg-center align-items-center align-items-lg-start">
      <div
        className={
          isNftStock
            ? "redeem-right d-flex flex-column justify-content-start"
            : "mt-0 redeem-right d-flex flex-column justify-content-center align-items-center"
        }
      >
        {isNftStock ? (
          <>
            <h4>Te damos la bienvenida al mundo de</h4>
            <h2>Edgardo Giménez</h2>
            <h3>
              Para iniciar el revelado introduce el o los códigos que te fueron
              entregados.{" "}
            </h3>
            <div className="redeem-code">
              {isCodeLoading ? (
                <div className="loading-code d-flex flex-row justify-content-center align-items-center">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <button
                  className="d-flex flex-row justify-content-center align-items-center"
                  onClick={() => setCode()}
                >
                  Validar
                </button>
              )}
              <input
                type="text"
                placeholder="Ingresar código"
                value={redeemCode}
                onChange={(e) => handleChange(e)}
              ></input>
              {errorCode ? (
                <div className="redeem-codes-item-error d-flex flex-row align-items-center">
                  <CheckNok />
                  El código ya fue utilizado
                </div>
              ) : wrongCode ? (
                <div className="redeem-codes-item-error d-flex flex-row align-items-center">
                  <CheckNok />
                  El código no es valido. Por favor contactarse a
                  hello@artbag.io
                </div>
              ) : null}
            </div>
            <div className="redeem-codes-container">
              {codes.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className="redeem-codes-item d-flex flex-column"
                  >
                    {index ? <hr></hr> : null}
                    <div className="redeem-codes-item-box d-flex flex-row align-items-center">
                      {item.accepted ? <CheckOk /> : <CheckNok />}
                      <h3>
                        {item.id}
                        {item.printed
                          ? " - Incluye print de regalo + 1 Display 10”"
                          : " - Incluye print de regalo"}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>
            {codes.find((obj) => obj.accepted === true) ? (
              <button
                className="d-flex flex-row justify-content-center align-items-center"
                onClick={() => nextScreen()}
              >
                Revelar códigos validados
              </button>
            ) : null}
          </>
        ) : (
          <>
            <h3>No hay stock disponible</h3>
            <h3>Inténtelo en otro momento</h3>
            <p>
              Si tienes problemas al ingresar o validar el código escríbenos a{" "}
              <a href="mailto:hello@fancymonas.io">hello@fancymonas.io</a>
            </p>
          </>
        )}
      </div>
      <div className="redeem-left d-flex flex-column justify-content-center align-items-center">
        <MonkeyDiscoverAlt />
        <p>
          Si tienes problemas al ingresar o validar el código escríbenos a{" "}
          <a href="mailto:hello@fancymonas.io">hello@fancymonas.io</a>
        </p>
      </div>
    </section>
  );
}
