import React from "react";

function HelpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g>
        <path
          fill="#C1C1C1"
          d="M10 18.333a8.343 8.343 0 01-8.333-8.334v-.166a8.333 8.333 0 118.333 8.5zm-.013-1.667H10a6.667 6.667 0 10-.013 0zm.846-1.667H9.167v-1.666h1.666v1.666zm0-2.5H9.167a2.986 2.986 0 011.475-2.648c.55-.422 1.025-.785 1.025-1.518a1.667 1.667 0 10-3.334 0H6.667v-.075a3.334 3.334 0 016.666.075c-.06.899-.542 1.717-1.3 2.204a2.583 2.583 0 00-1.2 1.962z"
        ></path>
      </g>
    </svg>
  );
}

export default HelpIcon;
