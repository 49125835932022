import React from "react";

function NftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      fill="none"
      viewBox="0 0 21 22"
    >
      <g clipPath="url(#clip0_1924_21598)">
        <path
          fill="#CFE6F4"
          d="M2.674 12.504c.7-.47 1.564-.757 2.39-.6.482.089.925.321 1.352.564.932.533 1.82 1.138 2.658 1.808.846-1.338 2.396-2.01 3.696-2.918.443-.308.865-.654 1.356-.875.96-.435 2.15-.33 3 .297.35.26.637.594.918.927.424.497.845.994 1.27 1.49.064.08.135.16.156.26.033.135-.027.276-.083.403-.254.564.026 1.035-.227 1.597a6.165 6.165 0 01-.862 1.345c-.3.338-1.054.508-1.44.738-1.964 1.15-4.023 2.131-6.076 3.11-.535.253-1.103.515-1.692.456-.316-.033-.613-.154-.9-.292-1.623-.78-2.941-2.07-4.468-3.029-.702-.44-1.462-.821-2.048-1.407-.48-.481-1.043-1.405-.778-2.121.24-.654 1.224-1.378 1.783-1.75l-.005-.003z"
        ></path>
        <path
          stroke="#86CAF4"
          strokeLinejoin="round"
          strokeWidth="1.34"
          d="M.68 5.837V16.16l9.331 5.163 9.33-5.163V5.837L10.01.674.68 5.837z"
        ></path>
        <path
          stroke="#86CAF4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.34"
          d="M.941 14.244s2.554-2.137 4.002-2.345c1.448-.208 6.208 3.69 6.208 3.69"
        ></path>
        <path
          stroke="#86CAF4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.34"
          d="M9.262 14.173s4.587-4.037 5.827-4.07c1.24-.032 3.931 2.243 3.931 2.243"
        ></path>
        <path
          stroke="#86CAF4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.34"
          d="M9.187 9.281a1.691 1.691 0 100-3.383 1.691 1.691 0 000 3.383z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1924_21598">
          <path fill="#fff" d="M0 0H20.012V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default NftIcon;
