import React, { useState } from "react";
import Breadcrumb from "../components/shared/Breadcumbs";
import Navbar from "../components/shared/Navbar";
import "./CheckoutTemplate.scss";
import routes from "../routes/routes";
import { Outlet } from "react-router-dom";
import MainFooter from "../components/shared/MainFooter";

const CheckoutTemplate = ({ children, items }) => {
  const [crumbs] = useState(["inicio", "data", "resume", "pay"]);

  const selected = (crumbs) => {
    console.log(crumbs);
  };
  return (
    <>
      <div id="fancy-checkout" className="wrapper-checkout-template">
        <Navbar />
        <main className="container main-wrapper">
          <Breadcrumb
            crumbs={crumbs}
            selected={selected}
            baseRoute={`${routes.fancy_checkout}/inicio`}
          />
          <hr />
          <Outlet />
        </main>
      </div>
      <MainFooter />
    </>
  );
};

export default CheckoutTemplate;
