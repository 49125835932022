import { SubTitleCheckout, Title2Checkout } from "./Title";
import { Button } from "../shared/Button";
import PenIcon from "../icons/PenIcon";
import CheckIcon from "../icons/CheckIcon";

const CheckoutHeader = ({
  title,
  subtitle,
  btnText,
  onClick,
  isEditable = false,
  modeEdit = false,
}) => {
  return (
    <div className="info-edit">
      <div className="grow-title">
        <Title2Checkout>{title}</Title2Checkout>
        <SubTitleCheckout>{subtitle}</SubTitleCheckout>
      </div>
      {isEditable &&
        (modeEdit ? (
          <Button onClick={onClick} color="text">
            {btnText} <PenIcon />
          </Button>
        ) : (
          <Button onClick={onClick} color="primary">
            Confirmar <CheckIcon />
          </Button>
        ))}
    </div>
  );
};

export default CheckoutHeader;
