import { useRef } from 'react';

const FilterMenuMovil = () => {

    const filtersContainerRef = useRef(null);

    const filtersContainerHandle = () => {
      const filtersContainerElement = filtersContainerRef.current;
      filtersContainerElement.classList.toggle('show');
    }

    return {
        filtersContainerRef,
        filtersContainerHandle
    }
}

export default FilterMenuMovil;