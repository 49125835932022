import { Label } from "../shared/Label";
import { Input } from "../shared/Input";
import { useFormikContext } from "formik";

const BasicInfo = ({ edit }) => {
  const { values, handleChange } = useFormikContext();

  return (
    <div className="row gy-3 gx-3 mt-3 form-width">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div className="p-1">
          <Label htmlFor="name" className="form-label">
            Nombre *
          </Label>
          <Input
            type="text"
            name="name"
            value={values?.name}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div className="p-1">
          <Label htmlFor="lastName" className="form-label">
            Apellidos *
          </Label>
          <Input
            type="text"
            name="lastName"
            value={values?.lastName}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div className="p-1">
          <Label htmlFor="email" className="form-label">
            Dirección de email *
          </Label>
          <Input
            type="text"
            name="email"
            value={values?.email}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <div className="p-1">
          <Label htmlFor="phone" className="form-label">
            Teléfono *
          </Label>
          <Input
            type="number"
            name="phone"
            aria-label="phone"
            aria-describedby="phone"
            value={values?.phone}
            disabled={edit}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
