import React from "react";
import styled from "styled-components";
import { Button as BootstrapButton } from "react-bootstrap";

const StyledButton = styled(BootstrapButton)`
  display: flex;
  width: ${(props) => (props.fullwidth ? "100%" : "auto")};
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: ${(props) =>
    props.variant === "outlined" ? "1px solid black" : "0px"};
  color: ${(props) => {
    switch (props.color) {
      case "primary":
        return props.theme.colors.white;
      case "secondary":
        return props.theme.colors.black;
      case "text":
        return props.theme.colors.black;
      default:
        return props.theme.colors.black;
    }
  }};
  background: ${(props) => {
    switch (props.color) {
      case "primary":
        return props.disabled ? "#b2b2b2" : "#000";
      case "secondary":
        return props.disabled ? "#b2b2b2" : "#fff";
      case "text":
        return props.disabled ? "#b2b2b2" : "transparent";
      default:
        return "transparent";
    }
  }};
  box-shadow: ${(props) =>
    props.color === "secondary" && "0px 0px 24px 0px rgba(0, 0, 0, 0.12)"};
  &:hover {
    background: ${(props) => {
      switch (props.color) {
        case "primary":
          return "#000";
        case "secondary":
          return "#fff";
        case "text":
          return "transparent";
        default:
          return "transparent";
      }
    }};
    color: ${(props) => {
      switch (props.color) {
        case "primary":
          return props.theme.colors.white;
        case "secondary":
          return props.theme.colors.black;
        case "text":
          return props.theme.colors.black;
        default:
          return props.theme.colors.black;
      }
    }};
    border: ${(props) =>
      props.variant === "outlined" ? "1px solid black" : "0px"};
  }
  &:disabled {
    background-color: #b2b2b2;
  }
`;

const Button = ({
  children,
  variant,
  color = "primary",
  fullwidth,
  className,
  type,
  ...rest
}) => {
  return (
    <StyledButton
      type={type}
      className={className}
      variant={variant}
      color={color}
      fullwidth={fullwidth}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export { Button };
