import React from "react";

function DownloadImage(props) {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3221_35716)">
        <rect
          x="20"
          y="20"
          width="44"
          height="44"
          rx="22"
          fill="black"
          shape-rendering="crispEdges"
        />
        <path
          fill-rule="evenodd"
          clipRule="evenodd"
          d="M51 50C51.5523 50 52 50.4477 52 51C52 51.5523 51.5523 52 51 52H33C32.4477 52 32 51.5523 32 51C32 50.4477 32.4477 50 33 50H51ZM42 48L41.918 47.996L41.7993 47.9798L41.6879 47.9503L41.5768 47.9063L41.4793 47.854L41.4048 47.8037L41.2929 47.7071L37.2929 43.7071C36.9024 43.3166 36.9024 42.6834 37.2929 42.2929C37.6534 41.9324 38.2206 41.9047 38.6129 42.2097L38.7071 42.2929L41 44.585V33C41 32.4477 41.4477 32 42 32C42.5523 32 43 32.4477 43 33V44.585L45.2929 42.2929C45.6534 41.9324 46.2206 41.9047 46.6129 42.2097L46.7071 42.2929C47.0676 42.6534 47.0953 43.2206 46.7903 43.6129L46.7071 43.7071L42.7071 47.7071L42.6631 47.7485L42.5953 47.8037L42.4841 47.8753L42.4231 47.9063L42.3417 47.9401L42.266 47.9642L42.1175 47.9932L42 48Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3221_35716"
          x="0"
          y="0"
          width="84"
          height="84"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3221_35716"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3221_35716"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default DownloadImage;
