import '../../styles/BuyButtonSticky.scss';
import CartIcon from '../icons/Cart';

const BuyButtonSticky = ({link, button, author, icon}) => {
  return (
    <div id='buyButtonSticky' className='fixed-bottom'>
      <div className='container w-100'>
        <a href={link} className='btn btn-buyButtonSticky w-100'>
          {icon && (
            <CartIcon width={25} height={25} className="button-icon" />
          )}
          {button}</a>
        <h6 className='author'>{author}</h6>
      </div>
    </div>
  )
}

export default BuyButtonSticky