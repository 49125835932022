import { useContext } from "react";
import { ResumeCard } from "./ResumeCard";
import { Title2Checkout } from "./Title";
import { CartContext } from "../../context/Cart";

const Resume = ({ type, next, disabled, cancel, nextLabel, cancelLabel }) => {
  const { price, priceDisplay, total, isPrinted, countDisplay, count } =
    useContext(CartContext);

  return (
    <ResumeCard
      next={next}
      cancel={cancel}
      type={type}
      disabled={disabled}
      nextLabel={nextLabel}
      cancelLabel={cancelLabel}
    >
      <Title2Checkout>Tu Compra</Title2Checkout>
      <hr />
      <div className="table">
        <div className="table__item">
          <span>{count} Fancy Mona</span>
          <strong>
            <p>{price} USD</p>
          </strong>
        </div>
        {isPrinted && (
          <div className="table__item">
            <span>
              {count} {count > 1 ? "Impresiones" : "Impresión"} fine art
            </span>
            <strong>
              <p>incluída{count > 1 && "s"}</p>
            </strong>
          </div>
        )}
        <div className="table__item">
          <span>{countDisplay} Display 10”</span>
          <strong>
            <p>{priceDisplay} USD</p>
          </strong>
        </div>
      </div>
      <div className="total">
        <Title2Checkout>
          Total <span className="text-primary fw-normal">{total} USD</span>
        </Title2Checkout>
      </div>
    </ResumeCard>
  );
};

export default Resume;
