import React, { useEffect } from "react";
import styled from "styled-components";
import HelpIcon from "../icons/HelpIcon";

const StyledTooltip = styled.div`
  .custom-tooltip {
    /* Styles... */
  }
`;

const Tooltip = ({ placement = "right", text, className }) => {
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
  }, []);

  return (
    <StyledTooltip>
      <span
        type="button"
        data-bs-toggle="tooltip"
        data-bs-placement={placement}
        data-bs-custom-class="custom-tooltip"
        data-bs-title={text}
        className={className}
      >
        <HelpIcon />
      </span>
    </StyledTooltip>
  );
};

export { Tooltip };
