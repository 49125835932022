import React from "react";
import { useTranslation } from "react-i18next";
import CloseModal from "../icons/CloseModal.jsx";
import { Button } from "../shared/Button.jsx";

const ModalHistorySales = ({
  modal,
  setModal,
  title,
  subtitle,
  isClosable,
  children,
}) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  document.addEventListener("keyup", (e) => {
    // if we press the ESC
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setModal(false);
    }
  });

  document.addEventListener("click", (e) => {
    if (e.target === document.querySelector(".modal-background")) {
      setModal(false);
    }
  });

  return (
    <div
      id="modal-term-section"
      className={modal ? "modal-background" : "d-none"}
    >
      <div className="modal-historysales-container">
        <div className="modal-header">
          <div className="modal-close-icon">
            {isClosable && (
              <Button color="text" onClick={isClosable}>
                <CloseModal width={24} height={24} />
              </Button>
            )}
          </div>
          <div className="modal-title">{title}</div>
          <div className="modal-subtitle">{subtitle}</div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ModalHistorySales;
