import mona1 from "../assets/monas/1.png";
import mona2 from "../assets/monas/2.png";
import mona3 from "../assets/monas/3.png";
import mona4 from "../assets/monas/4.png";
import mona5 from "../assets/monas/5.png";
import mona6 from "../assets/monas/6.png";
import mona7 from "../assets/monas/7.png";
import mona8 from "../assets/monas/8.png";
import mona9 from "../assets/monas/9.png";
import mona10 from "../assets/monas/10.png";
import mona11 from "../assets/monas/11.png";
import mona12 from "../assets/monas/12.png";
import mona13 from "../assets/monas/13.png";
import mona14 from "../assets/monas/14.png";
import mona15 from "../assets/monas/15.png";
import mona16 from "../assets/monas/16.png";
import mona17 from "../assets/monas/17.png";
import mona18 from "../assets/monas/18.png";

const gradients = {
  grey: "linear-gradient(180deg, #8A8A8A 0%, #BDBDBD 100%)",
  green: "linear-gradient(180deg, #3D8B5A 0%, #6CAC85 100%)",
  blue: "linear-gradient(180deg, #2796C2 0%, #7FC2DB 100%)",
  yellow: "linear-gradient(180deg, #BCB851 0%, #E2DE61 100%)",
  pink: "linear-gradient(180deg, #CF60A7 0%, #FFA0DD 100%)",
  purple: "linear-gradient(180deg, #5D32A1 0%, #9F61DE 100%)",
}
const homeMonas = [
  {
    img: mona1,
    color: gradients.yellow
  },
  {
    img: mona2,
    color: gradients.grey
  },
  {
    img: mona3,
    color: gradients.green
  },
  {
    img: mona4,
    color: gradients.grey
  },
  {
    img: mona5,
    color: gradients.green
  },
  {
    img: mona6,
    color: gradients.blue
  },
  {
    img: mona7,
    color: gradients.pink
  },
  {
    img: mona8,
    color: gradients.yellow
  },
  {
    img: mona9,
    color: gradients.grey
  },
  {
    img: mona10,
    color: gradients.pink
  },
  {
    img: mona11,
    color: gradients.blue
  },
  {
    img: mona12,
    color: gradients.yellow
  },
  {
    img: mona13,
    color: gradients.blue
  },
  {
    img: mona14,
    color: gradients.green
  },
  {
    img: mona15,
    color: gradients.grey
  },
  {
    img: mona16,
    color: gradients.purple
  },
  {
    img: mona17,
    color: gradients.pink
  },
  {
    img: mona18,
    color: gradients.purple
  }
];
export const getRandomMona = () => {
  const random = Math.floor(Math.random() * homeMonas.length);
  return homeMonas[random];
}


