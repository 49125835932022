import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { suscribeToWhilest } from "../../services/axiosService.js";
import DownloadImage from "../icons/DownloadImage";
import CloseModal from "../icons/CloseModal";

function ModalZoom({ imageUrl, getExclusiveContent }) {

    // eslint-disable-next-line
    const [t, i18n] = useTranslation("global");
    const [isVisible, setIsVisible] = useState(false);


    const { zoomModal, setZoomModal } = React.useContext(AppContext);

    document.addEventListener("keyup", e => {
        // if we press the ESC
        if (e.key === "Escape" && document.querySelector(".modal-background")) {
            setZoomModal(false);
        }
    });

    document.addEventListener("click", e => {
        if (e.target === document.querySelector(".modal-background")) {
            setZoomModal(false);
        }
    });

    let handleOnClick = () => {
        setZoomModal(false);
    }

    return (
           <div id="modal-term-section" className={zoomModal ? "modal-background" : "d-none"}>
                    <div className="modal-term-container-image d-flex flex-column justify-content-center">
                 <div className="card modal-card">
               <img
                  className="item-image rounded modal-card-img"
                  src={imageUrl}
                  alt="card"
                /> 
                <div className="card-img-overlay">
                  <h5 className="card-title text-end modal-close-text">
                      <span className="modal-close-badge" onClick={handleOnClick}><CloseModal width={25} height={25} /></span>
                  </h5>
                  <p className="card-text text-start modal-text ">
                  <span onClick={getExclusiveContent} className="modal-badge"><DownloadImage /></span>
                  </p>
                </div>
              </div>
                    </div>
                </div>
    );
}

export default ModalZoom;