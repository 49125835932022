import React from "react";

function TwitterDark(props) {

  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6606 28.5C24.4531 28.5 28.1683 22.7286 28.1683 17.7236C28.1683 17.5597 28.1683 17.3965 28.1575 17.234C28.8803 16.6979 29.5042 16.034 30 15.2735C29.326 15.58 28.611 15.7808 27.8789 15.8694C28.6499 15.3961 29.2269 14.6516 29.5025 13.7745C28.7776 14.2158 27.9845 14.5267 27.1574 14.6939C26.6006 14.0867 25.8642 13.6846 25.0621 13.5499C24.2601 13.4151 23.4371 13.5553 22.7205 13.9486C22.0039 14.3419 21.4337 14.9664 21.0981 15.7256C20.7624 16.4848 20.6802 17.3362 20.8639 18.1482C19.3957 18.0726 17.9593 17.6813 16.6481 16.9996C15.3369 16.3178 14.1801 15.3609 13.2528 14.191C12.7805 15.0248 12.6359 16.0118 12.8483 16.9512C13.0607 17.8905 13.6143 18.7115 14.3962 19.2469C13.8084 19.2293 13.2335 19.0667 12.72 18.7729V18.8209C12.7202 19.6953 13.0154 20.5428 13.5554 21.2195C14.0954 21.8962 14.847 22.3605 15.6828 22.5336C15.1391 22.6857 14.5687 22.7079 14.0153 22.5986C14.2514 23.3511 14.7108 24.0091 15.3294 24.4807C15.948 24.9523 16.6949 25.2139 17.4655 25.2288C16.6998 25.8461 15.823 26.3024 14.8852 26.5718C13.9473 26.8412 12.9669 26.9183 12 26.7987C13.6889 27.9102 15.6539 28.4997 17.6606 28.497" fill="black"/>
    <rect x="0.75" y="0.75" width="40.5" height="40.5" rx="20.25" stroke="black" stroke-width="1.5"/>
    </svg>
  );
}

export default TwitterDark;