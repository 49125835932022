import React from "react";

export default function CheckoutError({ setError }) {

  return (
    <div className="checkout-error-container d-flex flex-column justify-content-center align-items-center">
      <h2>UPS! 🙉</h2>
      <h3>Hubo un error inesperado, por favor vuelva a intentarlo</h3>
      <button className="error-button d-flex flex-row justify-content-center align-items-center" type="button" onClick={ () => setError(false) }>Intentar nuevamente</button>
    </div>
  )

}
