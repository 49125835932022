import React from "react";

function TCIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 25 17"
    >
      <g fill={color} clipPath="url(#clip0_2413_7703)">
        <path d="M0 2.367c.038-.146.072-.294.115-.439A2.74 2.74 0 012.653.005c.35-.009.7-.003 1.05-.003h18.429c1.32 0 2.352.712 2.736 1.886.093.285.132.579.132.879 0 3.822.003 7.644-.002 11.467-.002 1.584-1.192 2.763-2.792 2.764-6.47.003-12.937.002-19.406 0-1.418 0-2.497-.9-2.76-2.28-.007-.029-.026-.057-.04-.085V2.367zm12.525 3.429c-3.57 0-7.142.002-10.713-.005-.201 0-.255.047-.254.25.007 2.711.004 5.422.005 8.133 0 .814.476 1.28 1.308 1.28h19.256c.844 0 1.31-.463 1.31-1.302 0-2.695-.002-5.39.006-8.085 0-.217-.05-.277-.277-.277-3.546.008-7.093.006-10.64.006zm-.034-1.545h9.615c.382 0 .765-.008 1.146.003.156.005.191-.048.188-.192-.008-.442 0-.885-.004-1.327-.007-.691-.504-1.187-1.204-1.187-6.49-.002-12.982-.002-19.473 0-.695 0-1.19.502-1.196 1.195-.003.418.015.837-.007 1.255-.012.224.067.258.271.258 3.554-.007 7.11-.005 10.664-.005z"></path>
        <path d="M5.092 10.822c.87-.071 1.182.272 1.18 1.16 0 .877-.302 1.173-1.182 1.173-.892 0-1.196-.292-1.192-1.188.005-.844.276-1.218 1.195-1.146z"></path>
      </g>
      <defs>
        <clipPath id="clip0_2413_7703">
          <path fill={color} d="M0 0H25V17H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TCIcon;
