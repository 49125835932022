import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../../context/Cart";
import { QRCodeSVG } from "qrcode.react";
import {
  getHistorySales,
  postSellerProcessCreditCard,
} from "../../services/axiosService";
import routes from "../../routes/routes";
import TotalDetail from "../../components/comercial-checkout/TotalDetail";
import TCenPasos from "./TCenPasos";
import CashMethod from "./CashMethod";
import RevealTemplate from "../../templates/RevealTemplate";
import RevealButton from "../../components/shared/ButtonReveal";
import HeadingHr from "../../components/shared/HeadingHr";
import CustomDropdown from "../../components/shared/Dropdown";
import User from "../../components/icons/User";
import ArrowTop from "../../components/icons/ArrowTop";
import ListIcon from "../../components/icons/ListIcon";
import HistoryIcon from "../../components/icons/HistoryIcon";
import LogOutIcon from "../../components/icons/LogOutIcon";
import CryptoPayment from "./CryptoPayment";
import "./PaymentMethod.scss";
import TransferMethod from "./TransferMethod";
import ModalHistorySales from "../../components/modals/ModalHistorySales";

const PaymentMethod = () => {
  const navigate = useNavigate();
  const [historySalesShowModal, setHistorySalesShowModal] = useState(false);
  const [historySales, setHistorySales] = useState({});
  const { paymentMethod: urlPaymentMethod } = useParams();
  const {
    paymentMethod: payment,
    count,
    countDisplay,
    priceDisplay,
    user,
  } = useContext(CartContext);
  const [newTotal, setNewTotal] = useState(0);
  const [generalTotal, setNewGeneralTotal] = useState(0);

  useEffect(() => {
    const requestHistorySales = async () => {
      await getHistorySales()
        .then((response) => {
          setHistorySales(response.data);
        })
        .catch((error) => {});
    };
    requestHistorySales();
  }, []);

  useEffect(() => {
    switch (count) {
      case 1:
        setNewTotal(1500);
        break;
      case 2:
        setNewTotal(2800);
        break;
      case 3:
        setNewTotal(4200);
        break;
      case 4:
        setNewTotal(5200);
        break;
      case 5:
        setNewTotal(6500);
        break;
      case 6:
        setNewTotal(7800);
        break;
      case 7:
        setNewTotal(8400);
        break;
      case 8:
        setNewTotal(9000);
        break;
      case 9:
        setNewTotal(9600);
        break;
      case 10:
        setNewTotal(10000);
        break;
      default:
        break;
    }
  }, [count]);

  useEffect(() => {
    const calculateTotalPrice = newTotal + priceDisplay;
    setNewGeneralTotal(calculateTotalPrice);
  }, [newTotal, countDisplay]);

  const logout = () => {
    localStorage.removeItem("token");
    navigate(routes.sellers_login);
  };

  const getComponentByPaymentMethod = (key) => {
    switch (key) {
      case "tc-en-pasos":
        return <TCenPasos />;
      case "efectivo":
        return <CashMethod />;
      case "transferencia":
        return <TransferMethod />;
      case "crypto":
        return <CryptoPayment />;
      default:
        return (
          <h5 className="text-danger">
            No se ha encontrado un método de pago seleccionado
          </h5>
        );
    }
  };

  const confirmPurchase = async () => {
    const data = {
      saleId: payment?.artworkSaleId,
    };
    try {
      const response = await postSellerProcessCreditCard(data);
      if (response.data.approved) {
        navigate(routes.revelated_list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalHistorySales
        modal={historySalesShowModal}
        setModal={setHistorySalesShowModal}
        title={"Historial de ventas"}
        subtitle={"Resumen histórico Fancy Monas vendidas"}
      >
        <div id="table">
          <div class="seller-header">
            <div>Vendedor</div>
          </div>
          <div class="client-header">
            <div>Cliente</div>
          </div>
          {historySales?.sales?.map((item, index) => {
            return (
              <>
                <div class="seller-col">{item.sellerEmail}</div>
                <div class="client-col">
                  <div class="primary-data">
                    <div class="name">{item.name ?? ''}</div>
                    <div class="email">{item.userEmail ?? ''}</div>
                    <div class="phone">{item.phone ?? ''}</div>
                    <div class="artworks">
                      {item.artworks?.map((fancymona, index) => {
                        return (
                          <div class="fancymona">
                            <img
                              src={fancymona.compressedImageUrl}
                              alt="mona"
                            />
                            <span>#{fancymona.number}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div class="secondary-data">

                    { item?.currency?.length > 0 && item?.signal?.length > 0
                      ? <div class="signal">{item.currency ?? ''} {item.signal ?? ''}</div>
                      : <div class="signal">-</div>
                    }
                    
                    <div class="payment_method">Metodo de pago: {item.paymentMethod}</div>

                    { item.additionalIndications.length > 0
                      ? <div class="comment">Comentario: {item.additionalIndications ?? ''}</div>
                      : <div class="comment">-</div>
                    }
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </ModalHistorySales>
      <RevealTemplate
        heading={
          <div className="heading-dropdown">
            <HeadingHr heading={"Pago"} />
            <CustomDropdown
              text={user?.email ?? "Opciones"}
              startIcon={<User width={15} height={15} color={"#89CEF8"} />}
              endIcon={<ArrowTop width={20} height={20} color={"#000"} />}
              items={[
                {
                  name: (
                    <div className="d-flex align-items-center custom-dropdown-gap">
                      <ListIcon width={15} height={15} color={"#000"} /> Cola de
                      revelado
                    </div>
                  ),
                  click: () => navigate(routes.revelated_list),
                },
                {
                  name: (
                    <div className="d-flex align-items-center">
                      <HistoryIcon width={15} height={15} color={"#000"} />{" "}
                      Historial de ventas
                    </div>
                  ),
                  click: () => setHistorySalesShowModal(true),
                },
                {
                  name: (
                    <div className="d-flex align-items-center custom-dropdown-gap">
                      <LogOutIcon width={15} height={15} color={"#dc3545"} />{" "}
                      <span className="text-danger">Desconectar</span>
                    </div>
                  ),
                  click: logout,
                },
              ]}
            />
          </div>
        }
        footer={
          <>
            <RevealButton onClick={() => navigate(routes.comercial_checkout)}>
              Volver
            </RevealButton>
            <RevealButton onClick={() => confirmPurchase()} disabled={!payment}>
              Confirmar compra
            </RevealButton>
          </>
        }
      >
        <div className="payment-method">
          {payment && (
            <TotalDetail
              qtyMona={count ?? ""}
              totalMona={newTotal ?? ""}
              qtyDisplay={countDisplay ?? ""}
              totalDisplay={priceDisplay ?? ""}
              paymentMethod={urlPaymentMethod}
              total={generalTotal ?? ""}
            />
          )}
          {payment &&
            !payment.url &&
            getComponentByPaymentMethod(urlPaymentMethod)}
          {payment && payment.url && (
            <div className="method-qr">
              <div className="qr-wrapper">
                <QRCodeSVG size={200} value={payment.url} />
              </div>
              <span className="method-qr-span">
                Link de pago{" "}
                {urlPaymentMethod === "CARD" ? "Paypal" : urlPaymentMethod}
              </span>
            </div>
          )}
          {!payment && (
            <h1 className="title-param">Ningún metodo de pago seleccionado</h1>
          )}
        </div>
      </RevealTemplate>
    </>
  );
};

export default PaymentMethod;
