import React from "react";

function LogOutIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 14 14"
    >
      <g fill={color}>
        <path d="M6.19 4.803V6.23l.001.14c.03.365.328.649.694.66h.028a.71.71 0 00.5-.196.78.78 0 00.225-.57V4.658c0-1.26 0-2.521.004-3.782C7.644.43 7.467.149 7.098.01L7.066 0H6.76l-.032.011c-.37.138-.546.42-.544.866.007 1.307.005 2.616.005 3.924v.002z"></path>
        <path d="M12.333 2.803a6.751 6.751 0 00-1.944-1.684c-.367-.215-.802-.108-1.01.244-.206.347-.1.765.255.992.035.023.07.043.104.066l.026.015a5.471 5.471 0 012.531 5.584c-.52 2.964-3.348 4.96-6.298 4.448a5.464 5.464 0 01-3.805-2.644 5.437 5.437 0 01-.405-4.593c.436-1.203 1.222-2.152 2.33-2.823.134-.08.283-.183.369-.368a.717.717 0 00-.168-.845c-.248-.225-.593-.25-.901-.06C1.252 2.472.101 4.47 0 7.077v.011a6.934 6.934 0 008.184 6.799c3.044-.558 5.41-3.188 5.622-6.256.125-1.789-.371-3.411-1.471-4.823l-.002-.004z"></path>
      </g>
    </svg>
  );
}

export default LogOutIcon;
