import styled from "styled-components";

const StyledTitle = styled.h2`
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 164.286% */
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Title = ({ children, ...props }) => {
  return <StyledTitle {...props}>{children}</StyledTitle>;
};

const StyledText = styled.span`
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 23px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Text = ({ children, ...props }) => {
  return <StyledText {...props}>{children}</StyledText>;
};

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 8px;
`;

const Ul = ({ children, ...props }) => {
  return <StyledUl {...props}>{children}</StyledUl>;
};

const StyledLi = styled.li`
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 23px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Li = ({ children, ...props }) => {
  return <StyledLi {...props}>{children}</StyledLi>;
};

const ContentTermsConditions = () => {
  return (
    <div className="d-flex flex-column">
      <Title>TÉRMINOS GENERALES DEL SITIO WEB</Title>
      <Text>
        1. Introducción. Esta plataforma es propiedad de y operada por BAG LAB
        Ltd. (en adelante, “BAG”, “Compañía”, “nosotros”, “nos” o “nuestro”, de
        forma indistinta), una compañía constituida bajo las leyes de Islas
        Vírgenes Británicas, con domicilio social en Nerine Chambers, P.O. Box
        905, Road Town, Tortola VG1110, Islas Vírgenes Británicas, y de sus
        compañías vinculadas y/o asociadas.2
      </Text>
      <Text>
        2. Aceptación. Al utilizar el sitio web www.fancymonas.io y sus
        subdominios o dominios asociados (en adelante, el “Sitio Web”) usted
        confirma que comprende y acepta cumplir con estos Términos, junto con
        cualquier documento al que se pueda hacer referencia expresa ya sea en
        estos términos como en cualquier otro que se incluya en secciones
        generales o particulares del Sitio Web (en adelante, los “Términos”).
      </Text>
      <Text>
        3. Acuerdo legal. Estos Términos constituyen un acuerdo legal entre
        usted y BAG, y rigen su acceso y uso del Sitio Web, de las Fancy Monas y
        de los Fancy Monas NFTs (todo según términos definidos en este
        documento).
      </Text>
      <Text>
        4. Modificaciones a estos Términos. BAG se reserva el derecho de cambiar
        o modificar estos Términos en cualquier momento y a su exclusivo
        criterio, mediante la publicación de una notificación en el Sitio Web.
      </Text>
      <Text>
        5. Mayoría de edad. Al utilizar el Sitio Web y/o al transaccionar un
        Fancy Mona NFT, Ud. afirma que es mayor de edad para aceptar tales fines
        según la jurisdicción aplicable, y acepta y está obligado por estos
        Términos. Usted afirma que si está utilizando el Sitio Web o
        transacciona un Fancy Mona NFT en nombre de una organización o empresa,
        tiene la autoridad legal para vincular a dicha organización o empresa a
        estos Términos.
      </Text>
      <Text>
        6. Prohibiciones generales. Queda terminantemente prohibido utilizar el
        Sitio Web y/o transaccionar un Fancy Mona NFT si: (i) Ud. no está de
        acuerdo con estos Términos; (ii) Ud. no es mayor de edad en su
        jurisdicción de residencia; o (iii) Ud. tiene prohibido acceder o
        utilizar el Sitio Web y/o los Fancy Monas NFT o cualquiera de sus
        contenidos, productos o servicios por la ley aplicable o por
        disposiciones de estos Términos.
      </Text>
      <Text>
        7. Elegibilidad. BAG se reserva a su única y absoluta discreción
        permitir o no permitir el acceso al Sitio Web. Al aceptar estos
        Términos, Ud. declara y garantiza que:
        <Ul>
          <Li>
            No está sujeto a sanciones financieras, embargos u otras medidas
            restrictivas impuestas por las Naciones Unidas, la Unión Europea,
            cualquier país integrante de la Unión Europea, el Tesoro del Reino
            Unido, la Oficina de Control de Activos Extranjeros de los Estados
            Unidos (OFAC), o cualquier autoridad gubernamental en cualquier
            jurisdicción en la que está disponible la Plataforma BAG.
          </Li>
          <Li>
            No es ciudadano ni residente de ninguno de los siguientes países o
            jurisdicciones: Albania, Barbados, Balcanes, Bielorrusia, Birmania,
            Burkina Faso, Camboya, República Centroafricana, Cuba, República
            Democrática Del Congo, Filipinas, Etiopía, Haití, Hong Kong, Irán,
            Irak, Islas Caimán, Jamaica, Jordania, Líbano, Libia, Malí, Malta,
            Marruecos, Myanmar, Nicaragua, Pakistán, Corea Del Norte, Rusia,
            Senegal, Somalia, Sudán y Darfur, Sudán Del Sur, Siria, Turquía,
            Ucrania, Uganda, Yemen y/o Zimbabwe.
          </Li>
          <Li>
            No posee como actividad económica principal o se desempeña en las
            siguientes industrias: producción, comercialización y distribución
            de armas; sociedades y bancos offshore ubicados en paraísos
            fiscales; sitios de apuestas online; gobiernos locales y/o
            internacionales; sindicatos; partidos políticos; sitios de
            entretenimiento adulto; y/o dispensarios de marihuana o de otras
            sustancias ilegales, inclusive medicinal o de uso recreativo.
          </Li>
          <Li>No se está haciendo pasar por otra persona.</Li>
          <Li>
            Tiene pleno derecho, poder y autoridad para aceptar estos Términos.
          </Li>
        </Ul>
      </Text>
      <Text>
        8. Licencia del Sitio Web. Todos los contenidos de BAG y del Sitio Web,
        incluyendo, sin carácter limitativo, los textos (incluyendo los
        comentarios, disertaciones, exposiciones y reproducciones), gráficos,
        logos, iconos, imágenes, archivos de audio y video, software y todas y
        cada una de las características que se encuentran en el Sitio Web son
        propiedad exclusiva del Titular y/o del Artista y/o de sus proveedores
        de contenidos, y los mismos están protegidos por las leyes nacionales e
        internacionales de propiedad intelectual. A fin de utilizar el Sitio
        Web, BAG le otorga a Ud. una licencia personal, mundial, sin derecho a
        regalías, no transferible, no exclusiva, revocable y no sublicenciable
        para acceder al Sitio Web. La licencia es otorgada con el sólo propósito
        de permitirle a Ud. utilizar el Sitio Web y los servicios allí
        contenidas, todo ello en el marco dispuesto por estos Términos.
      </Text>
      <Title>USO DE LA BLOCKCHAIN Y WALLETS</Title>
      <Text>
        9. Wallets. Para utilizar ciertos servicios y funcionalidades del Sitio
        Web y/o para adquirir un Fancy Mona NFT, Ud. deberá contar con una
        billetera virtual de las plataforma digitales ajenas al Sitio Web y a
        BAG (en adelante, las “Wallets”).
      </Text>
      <Text>
        10. Manifestaciones en relación a las Wallets. Al utilizar su Wallet en
        relación al Sitio Web y/o a los Fancy Monas NFT Ud. manifiesta, en
        carácter de declaración jurada, que es único titular y beneficiario
        final de la Wallet, que conoce y da cumplimiento con la totalidad de la
        normativa aplicable en las jurisdicciones competentes para la
        titularidad y uso de la Wallet, y que conoce y entiende que las
        plataformas digitales que brindan los servicios son empresas distintas y
        completamente independientes de BAG y en las que BAG no tiene injerencia
        ni relación alguna, por lo que Ud. libera y renuncia a reclamar a BAG en
        forma total y absoluta por cualquier impedimento, mal funcionamiento o
        por cualquier otra cuestión ajena a BAG y que pudiera afectar el
        funcionamiento de su Wallet y/o el contenido asociado a la misma. Ud.
        asume en forma total y absoluta la responsabilidad por la titularidad de
        su Wallet y los movimientos que se hicieran con la misma, siendo todo
        ello materia completamente ajena a BAG.
      </Text>
      <Text>
        11. Responsabilidad de BAG en relación a las Wallets. BAG no opera ni
        mantiene Wallet alguna, ni está afiliado en forma alguna a proveedores
        de Wallets. BAG no tiene custodia ni control sobre el contenido de las
        Wallets y no tiene capacidad para recuperar o transferir su contenido,
        por lo que BAG no acepta ninguna responsabilidad u obligación hacia Ud.
        y/o terceros en relación con el uso de una Wallet, y no ofrece ninguna
        declaración ni garantía sobre el funcionamiento de los Fancy Monas NFT
        con una Wallet específica.
      </Text>
      <p>
        Al utilizar el Sitio Web Ud. declara comprender y aceptar que BAG no es
        un proveedor de Wallets ni cumple roles de arbitraje (o “Exchange”, por
        su nombre en idioma inglés), corredor, distribuidor, institución
        financiera, procesador de pagos o de negocio de servicios monetarios.
        Usted es el único responsable de mantener su Wallet segura y nunca debe
        compartir las credenciales de la misma con nadie. Si descubre algún
        problema relacionado con su Wallet, póngase en contacto con el proveedor
        de la misma.
      </p>
      <Text>
        12. Disponibilidad. Al utilizar el Sitio Web y transaccionar Fancy Monas
        NFTs, Ud. declara entender y aceptar que tales actividades son
        realizadas bajo su propio riesgo y que tanto el Sitio Web como los Fancy
        Monas NFTs son proporcionados “como están” y “según disponibilidad”, sin
        garantías ni condiciones de ningún tipo, ya sean expresas o implícitas.
      </Text>
      <Text>
        13. Seguridad de internet. Usted acepta los riesgos de seguridad
        inherentes de proporcionar información y operar en línea a través de
        internet, y acepta que BAG no tiene responsabilidad por cualquier
        incumplimiento de seguridad a menos que se deba a nuestra negligencia
        grave.
      </Text>
      <Text>
        14. Seguridad de las Wallets. BAG no será responsable ante usted por
        ninguna pérdida de ningún tipo en la que usted incurra como resultado
        del uso de su Wallet, incluyendo pero no limitado a cualquier pérdida o
        daño reclamos que surjan de:
        <Ul>
          <Li>
            Error de usuario, como contraseñas olvidadas o contratos
            inteligentes u otras transacciones incorrectamente construidas.
          </Li>
          <Li>Falla del servidor o pérdida de datos.</Li>
          <Li>Archivos de Wallets dañados.</Li>
          <Li>
            Acceso no autorizado o actividades de terceros, incluyendo, pero no
            limitado a, el uso de virus, phishing, brute-forcing u otros medios
            de ataque contra el Sitio Web, las Blockchains, o cualquier Wallet.
            BAG no será responsable ante usted por ninguna pérdida de ningún
            tipo de cualquier acción que resulte de que usted proporcione una
            dirección de Wallet.
          </Li>
        </Ul>
      </Text>
      <Text>
        15. Funcionamiento de elementos ajenos a BAG. Los NFT son activos
        digitales intangibles que existen únicamente por virtud del registro de
        propiedad mantenido en las Blockchains asociadas a dichos NFTs (por
        ejemplo, Ethereum). Todas las transacciones de NFTs se producen en
        Blockchains asociadas a cada a NFT. BAG y/o sus empresas vinculadas no
        pueden efectuar o controlar en modo alguno las transacciones sobre
        cualquier NFT o los contenidos digitales vinculados a los mismos, o
        cualquier otro contenido o elementos subyacentes o asociados. Por lo
        expuesto, al utilizar el Sitio Web y/o los Fancy Monas NFTs, Ud.
        expresamente declara saber, comprender y aceptar que ni BAG ni sus
        empresas vinculadas o asociadas son responsables por cualquier pérdida o
        lesiones que Ud. pudiera sufrir relacionadas con la vulnerabilidad o
        cualquier tipo de fallo, comportamiento anormal del software (por
        ejemplo, Wallets, Smart Contract), Blockchains, cualquier otra
        característica de los NFTs, informes tardíos por parte de
        desarrolladores o representantes (o a la ausencia de informes) de
        cualquier problema con las Blockchains, incluyendo bifurcaciones,
        problemas técnicos de nodos o cualquier otro problema que tenga como
        resultado pérdidas o lesiones de fondos o NFTs.
      </Text>
      <Text>
        16. Seguridad del contenido digital. BAG no es responsable por las
        Wallets ni por la Blockchain que pueda contener las transacciones de
        cada Fancy Mona NFT, por lo que si cualquier Fancy Monas vinculada a un
        Fancy Mona NFT se vuelve inaccesible para el propietario de dicho Fancy
        Mona NFT, por el motivo que fuese, o bien por cualquier modificación o
        cambio en la Fancy Mona que alguien que no sea BAG pueda hacer, incluida
        la eliminación de la Fancy Mona, BAG no se responsabilizará por tal
        extremo. Al adquirir un Fancy Mona NFT, Ud. expresamente manifiesta que
        conoce los riesgos asociados a dicha tecnología y renuncia de forma
        total y expresa a realizar reclamo alguno a BAG en tal sentido. Ni BAG
        ni el Artista son responsables si la obra de arte digital vinculada a
        cada NFT se vuelve inaccesible para usted por cualquier motivo, o por
        cualquier modificación o cambio en la obra de arte digital que alguien
        que no sea nosotros pueda hacer, incluida la eliminación de la obra de
        arte digital.
      </Text>
      <Text>
        17. Seguridad de la información. BAG no puede y no asegura ni garantiza
        que el Sitio Web y los Fancy Monas NFTs o las Fancy Monas vinculados a
        los mismos o los servidores de nuestros proveedores de servicios estén
        libres de virus u otros componentes dañinos. No podemos garantizar la
        seguridad de los datos que Ud. revele en línea. Ningún consejo o
        información obtenido utilizando o derivado de los servicios, creará
        ninguna garantía o representación que no se haya hecho expresamente en
        estos Términos. Usted acepta los riesgos de seguridad inherentes al
        suministro de información y al comercio en línea a través de internet y
        no responsabilizará a BAG de ninguna violación de la seguridad.
      </Text>
      <Text>
        18. Eximición. En la máxima medida permitida por la ley aplicable y en
        virtud de todas las consideraciones referidos en estos Términos al uso
        de la tecnología Blockchain, las Wallets, los NFTs y el contenido
        digital vinculado a éstos, Ud. acepta eximir de responsabilidad al
        Artista, a BAG y a sus empresas vinculadas, y a nuestros y sus
        respectivos empleados, funcionarios, directores, contratistas,
        consultores, accionistas, proveedores, agentes, representantes,
        predecesores, sucesores y cesionarios, de y contra todos los reclamos,
        daños reales o supuestos, premios, sentencias, pérdidas,
        responsabilidades, obligaciones, multas, intereses, honorarios, gastos
        (incluidos, entre otros, honorarios de abogados, costas judiciales,
        costas de liquidación y costas de obtener indemnizaciones y seguros), de
        todo tipo y naturaleza, ya sea conocidos o desconocidos, previstos o
        imprevistos, en derecho o equidad, ya sea en agravio, contrato o de otro
        modo, que incluyen, entre otros, daños a la propiedad o lesiones
        personales, que son causados por, surgen o están relacionados con: (a)
        su uso o mal uso del Sitio Web, las Fancy Monas, y los Fancy Monas NFTs,
        (b) su incumplimiento de estos Términos, (c) el uso de NFTs y de
        cualquier Blockchain, y (d) su incumplimiento o violación de los
        derechos de un tercero, incluido otro usuario o proveedor de servicios
        externo. Usted acepta notificar de inmediato a BAG sobre dichos reclamos
        y cooperar con BAG en la defensa de los mismos.
      </Text>
      <Title>SOBRE LAS FANCY MONAS</Title>
      <Text>
        19. Fancy Monas. “Fancy Monas” (la “Colección”) es una colección de arte
        digital compuesta de mil novecientas cuarenta y dos (1942) obras
        digitales únicas e irrepetibles coleccionables (cada una de ellas, una
        “Fancy Mona”), de autoría del artista argentino Edgardo Giménez (el
        “Artista”). Todas las Fancy Monas se encuentran vinculadas a un contrato
        inteligente basado en la red Ethereum (en adelante, el “Smart Contract”)
        y cada una de ellas se vinculará de forma total y permanente a tokens no
        fungibles (los “NFTs”) también basados en la red Ethereum (cada uno de
        los NFTs asociados a una Fancy Mona, un “Fancy Mona NFT”). No existen
        otras Fancy Monas fuera de las vinculadas en el Smart Contract de la
        Colección.
      </Text>
      <Text>
        20. Aceptación. Al adquirir un Fancy Mona NFT a través del Sitio Web o
        al adquirir posteriormente cualquier Fancy Mona NFT de cualquier
        propietario a través del Sitio Web y/o a través de cualquier otra
        plataforma digital que permita transacciones de los mismos, usted acepta
        estar obligado por estos Términos y por aquellos dispuestos en el Smart
        Contract de las Fancy Mona NFT.
      </Text>
      <Text>
        21. Autoría de las Fancy Monas. Cada una de las Fancy Monas asociadas a
        sus respectivos Fancy Monas NFTs son obras digitales de autoría del
        Artista, y están protegidas por las normas nacionales e internacionales
        de derechos de autor. Las Fancy Monas son independientes de sus
        respectivos Fancy Monas NFTs asociados.
      </Text>
      <Text>
        22. Licencia. Sujeto a su cumplimiento de estas Condiciones y mientras
        Ud. sea propietario de un Fancy Mona NFT, se le concede una licencia
        limitada, personal, no exclusiva, no sublicenciable y de ámbito mundial
        bajo cualquier derecho de autor para uso o reproducción personal no
        comercial de la Fancy Mona asociada al Fancy Mona NFT de su propiedad, y
        para exponerla o usarla solamente para vender o transferir los derechos
        sobre dicho Fancy Mona NFT. La adquisición de un Fancy Mona NFT no
        implica la transferencia de ningún derecho de propiedad intelectual
        sobre la Fancy Mona asociada, permaneciendo todos ellos en cabeza del
        Artista. En caso de que Ud. transaccione el Fancy Mona NFT con terceros
        en una plataforma distinta al Sitio Web, deberá reproducir las presente
        condiciones y referir a su nuevo adquirente a estos Términos, los que
        deberán ser respetados por cualquier propietario de un Fancy Mona NFT.
      </Text>
      <Text>
        23. Alcance. El artículo precedente establece todos los derechos que Ud.
        -y cualquier adquirente de un Fancy Mona NFT- puede obtener al adquirir
        un Fancy Mona NFT. No hay otros derechos de licencia, ya sean expresos o
        implícitos, con respecto a las Fancy Monas. Se prohíbe modificar o
        generar obras derivadas de las Fancy Monas. No se conceden derechos de
        licencia bajo ninguna patente, marca registrada, secreto comercial u
        otra propiedad intelectual o derecho de propiedad. La licencia concedida
        al adquirir un Fancy Mona NFT no le otorga el derecho a, y usted no
        autorizará, permitirá o ayudará a terceros a:
        <Ul>
          <Li>
            Ejercer cualquiera de los derechos de licencia concedidos en este
            documento de cualquier manera que resulte en una compensación
            directa o indirecta, beneficio financiero o ganancia comercial de
            cualquier tipo para usted o cualquier tercero, ya sea actualmente
            realizada o que se proporcione en el futuro y con independencia de
            la naturaleza de la misma (ya sea monetaria, transmisión de activos,
            productos o servicios en especie u otro artículo o servicio de
            valor).
          </Li>
          <Li>
            Conceder cualquier sublicencia de cualquiera de los derechos de
            licencia aquí contenidos.
          </Li>
          <Li>
            Eliminar, suprimir u ocultar cualquier aviso de marca registrada,
            aviso de derechos de autor u otro aviso de propiedad intelectual en
            cualquier en el Fancy Mona NFT.
          </Li>
          <Li>
            Ejercer cualquier derecho de licencia referido en estos Términos
            para crear, respaldar, apoyar, promover o aprobar cualquier
            contenido, material o discurso que sea difamatorio, obsceno,
            pornográfico, indecente, abusivo, ofensivo, acosador, violento,
            odioso, racista, discriminatorio, inflamatorio o de otra manera
            objetable o inapropiado según lo determinado por BAG y/o por el
            Artista a su discreción.
          </Li>
          <Li>
            Realizar ingeniería inversa, descompilar o intentar descubrir el
            código fuente de cualquier Fancy Mona NFT o contenido propiedad de
            BAG, excepto en la medida en que la ley aplicable le permita hacerlo
            a pesar de esta prohibición.
          </Li>
          <Li>
            Utilizar las Fancy Monas o los Fancy Monas NFT de cualquier manera
            no autorizada expresamente en este documento o ejercer cualquier
            derecho de licencia en este documento de cualquier manera que viole
            la ley aplicable.
          </Li>
        </Ul>
      </Text>
      <Text>
        24. Limitación. BAG y/o el Artista, según corresponda, conservan todos
        los derechos, títulos e intereses sobre el Sitio Web y las Fancy Monas y
        todos los derechos de autor u otros derechos de propiedad intelectual
        sobre los mismos. A excepción de los derechos de licencia expresamente
        concedidos a los adquirentes de un Fancy Mona NFT, Ud. no tiene ni
        tendrá ningún derecho, título o interés sobre el Sitio Web ni las Fancy
        Monas.
      </Text>
      <Text>
        25. Prohibiciones. Ni Uds. ni cualquier adquirente de un Fancy Mona NFT
        podrán:
        <Ul>
          <Li>
            Separar, desvincular o disociar las Fancy Monas de sus respectivos
            Fancy Monas NFT, o
          </Li>
          <Li>
            Utilizar una Fancy Monas para crear, vender o intentar crear o
            vender cualquier token criptográfico nuevo.
          </Li>
        </Ul>
      </Text>
      <Text>
        26. Condiciones para transmitir un Fancy Mona NFT. En la medida en que
        no se le prohíba hacerlo por cualesquiera otros términos o condiciones
        aplicables a un NFT, Ud. podrá transferir un Fancy Mona NFT a un
        tercero, siempre que se cumplan las siguientes condiciones:
        <Ul>
          <Li>
            Dicha transferencia se realice a través de un mercado u otra
            plataforma que verifique criptográficamente que usted es el
            propietario real del NFT aplicable.
          </Li>
          <Li>
            Dicha transferencia debe cumplir:
            <ol>
              <Li>
                Todas las condiciones aplicables del mercado u otra plataforma
                en la que tenga lugar dicha venta u otra transferencia.
              </Li>
              <Li>
                Todas las leyes, reglamentos, directrices normativas y normas
                aplicables.
              </Li>
              <Li>Estos Términos.</Li>
            </ol>
            <Li>
              Antes de dicha venta u otra transferencia, usted deberá:
              <ol>
                <Li>
                  Notificar por escrito al posible cesionario que el ejercicio
                  por parte de éste de los derechos de licencia incluidos como
                  parte del Fancy Mona NFT estará condicionado a que dicho
                  cesionario acepte quedar vinculado por los presentes Términos.
                </Li>
                <Li>
                  Garantizar que dicho cesionario tenga la oportunidad de
                  revisar estos Términos.
                </Li>
              </ol>
            </Li>
            <Li>
              Después de transferido el Fancy Mona NFT, su derecho a mostrar y
              ejecutar la Fancy Mona asociada a su Fancy Mona NFT y todos los
              demás derechos de licencia en virtud de estos Términos terminarán
              inmediatamente (sin necesidad de notificación).
            </Li>
          </Li>
        </Ul>
      </Text>
      <Text>
        27. Regalías. En caso de vender su Fancy Mona NFT, al momento de la
        transacción del mismo Ud. deberá abonar el dos coma cinco por ciento
        (2,5%) del monto de transacción en concepto de regalías y como
        contraprestación por la transferencia de los derechos de licencia
        establecidos en estos Términos (en adelante, las “Regalías”) a BAG. Si
        la plataforma o servicio utilizado para facilitar la transferencia de un
        Fancy Mona NFT capta y abona el importe íntegro de las Regalías
        directamente a BAG, Ud. no tendrá obligación de abonar ningún importe
        adicional. Usted será responsable y abonará -en caso de que corresponda-
        todos y cada uno de los honorarios de transacción, honorarios de
        transferencia de pago e impuestos asociados con la transferencia de un
        Fancy Mona NFT. Las Regalías deberán ser abonadas en dólares
        estadounidenses o en una criptomoneda aceptable para BAG y se abonarán
        en la cuenta o cartera digital designada por BAG en cada Fancy Mona NFT.
      </Text>
      <Text>
        28. Uso. Los Fancy Monas NFT y la Colección en general están destinados
        únicamente al disfrute, uso y consumo de los usuarios. Éstos no son un
        “valor” en sí mismos.
      </Text>
      <Text>
        29. Beneficios Adicionales. De vez en cuando y a su discreción, BAG, el
        Artista o terceros asociados a éstos podrán poner a disposición de los
        titulares de los Fancy Monas NFTs ciertos contenidos adicionales,
        artículos físicos u otros beneficios (cualquiera de los anteriores, un
        “Beneficio Adicional”). Sin perjuicio de eso, ni BAG, ni el Artista, ni
        ningún tercero tienen obligación alguna de informarle o proporcionarle
        ningún Beneficio Adicional, por lo que no debe esperar ningún Beneficio
        Adicional al adquirir un Fancy Mona NFT. Será su responsabilidad
        mantenerse informado de la disponibilidad de cualquier Beneficio
        Adicional y tomar las medidas necesarias para solicitarlo, en caso de
        corresponder. Los términos y condiciones aplicables a cualquier
        Beneficio Adicional se establecerán en la información o materiales
        proporcionados junto al mismo.
      </Text>
      <Text>
        30. Eximiciones. Ni BAG ni el Artista tendrán responsabilidad alguna, y
        por la presente Ud. renuncia y los exime de toda reclamación, derivada
        de o relacionada con:
        <Ul>
          <Li>Cualquier blockchain o tecnología relacionada.</Li>
          <Li>
            Cualquier monedero digital (a modo ejemplificativo, MetaMask, Trust,
            Rainbow, y/o cualquier otra plataforma similar), o tecnologías,
            plataformas o servicios similares o relacionados.
          </Li>
          <Li>
            La transferencia o pérdida de cualquier Fancy Mona NFT o la
            imposibilidad de demostrar la propiedad o el control de cualquier
            Fancy Mona NFT.
          </Li>
          <Li>
            Cualquier mercado u otra plataforma de compra, venta o transferencia
            en la que se intente transaccionar cualquier Fancy Mona NFT distinta
            del Sitio Web.
          </Li>
          <Li>
            Cualquier ordenador, servidor, dispositivo de red u otro hardware o
            cualquier proveedor de servicios de Internet u otro servicio o
            hardware de telecomunicaciones.
          </Li>
          <Li>
            Cualquier otro servicio, equipo, tecnología o infraestructura de red
            o de transferencia de datos que no sea propiedad de BAG y/o el
            Artista, ni esté bajo su control.
          </Li>
        </Ul>
      </Text>
      <Text>
        31. Indemnidad. Usted indemnizará y mantendrá indemne a BAG y/o el
        Artista, y los defenderá de y contra cualquier reclamación, demanda,
        responsabilidad, daños, sanciones, multas, impuestos, costes y gastos
        (incluyendo, sin limitación, honorarios razonables de abogados y costas
        judiciales) que surjan de o en relación con:
        <Ul>
          <Li>
            Cualquier incumplimiento de estos Términos o uso no autorizado de
            las Fancy Monas y/o de los Fancy Monas NFTs.
          </Li>
          <Li>La propiedad o transferencia de cualquier Fancy Mona NFT.</Li>
        </Ul>
      </Text>
      <Text>
        32. Terminación. Sin perjuicio de cualquier acción, remedio legal o
        indemnización que pudiera corresponder, si Ud. incumple cualquiera de
        sus obligaciones bajo estos Términos, sus derechos sobre los Fancy Monas
        NFTs y las Fancy Monas asociadas a éstos y cualquier otro derecho de
        licencia que pueda tener bajo estos Términos terminará inmediatamente
        sin necesidad de notificación. Tras la terminación de sus derechos de
        licencia, Ud. cesará inmediatamente todo uso de cualquier Fancy Mona NFT
        y sus Fancy Monas asociadas. BAG también podrá determinar la suspensión
        del Sitio Web y los servicios allí contenidos en caso de que se
        presenten condiciones de razonabilidad que justifiquen tal decisión, a
        su exclusivo criterio y sin que ello implique derecho indemnizatorio
        alguno a ninguno de sus usuarios.
      </Text>
      <Title>DISPOSICIONES FINALES</Title>
      <Text>
        33. Impuestos. Usted acepta que es el único responsable de (i)
        determinar qué impuestos, si corresponde, se aplican a sus transacciones
        Fancy Monas NFT, y (ii) pagar todas y cada una de las ventas, uso, valor
        agregado y otros impuestos, aranceles y evaluaciones (excepto impuestos
        sobre nuestros ingresos netos) ahora o en el futuro reclamadas o
        impuestas por cualquier autoridad gubernamental asociados con su uso y
        transacciones de Fancy Monas NFTs. Ni BAG ni sus afiliados o empleados
        son responsables de determinar, retener, recaudar, declarar o remitir
        ningún impuesto que pudieran ser de aplicación a sus transacciones de
        Fancy Monas NFT y/o a las transacciones que Ud. realice.
      </Text>
      <Text>
        34. Fees. Al transaccionar Fancy Monas NFTs, Ud. acepta pagar todos los
        fees aplicables, incluidas las tarifas de transacción, uso de gas o
        similares asociados a la Blockhain o cualquier otro que corresponda,
        según lo estipulado en la pantalla de pago en el momento de su
        transacción. En caso de corresponder, Usted nos autoriza a deducir
        automáticamente dichos fees directamente de sus pagos y/o agregar fees a
        sus pagos.
      </Text>
      <Text>
        35. Sitios de terceros. El Sitio Web puede contener enlaces o
        funcionalidades para acceder o utilizar sitios web o aplicaciones de
        terceros, o mostrar, incluir o poner a disposición contenidos, datos,
        información, servicios, aplicaciones o materiales de terceros. Al hacer
        clic en un enlace a un sitio web o aplicación de terceros, o al acceder
        a ellos y utilizarlos, incluso aunque no se le avise de que ha
        abandonado el Sitio Web o que los mismos estén integrados en el Sitio
        Web, Ud. estará sujeto a los términos y condiciones de tales otros
        sitios web o aplicaciones, incluyendo sus respectivas políticas de
        privacidad. Dichos sitios web y aplicaciones de terceros no están bajo
        el control de BAG. BAG no es responsable de los mismos y el uso que Ud.
        pueda darles. BAG puede proporcionar enlaces a estos sitios web y
        aplicaciones de terceros únicamente para su comodidad y no revisa,
        aprueba, supervisa, respalda, garantiza ni hace declaración alguna con
        respecto a los mismos o sus productos o servicios asociados. Usted
        utiliza todos los enlaces de sitios web y aplicaciones de terceros por
        su cuenta y riesgo.
      </Text>
      <Text>
        36. Relación. Estos Términos no crean, ni pueden ser interpretados para
        crear cualquier asociación, empresa conjunta o agencia entre usted y BAG
        y/o el Artista.
      </Text>
      <Text>
        37. Separabilidad. Si alguna disposición de estos Términos se considera
        ilegal, nula o por cualquier razón inaplicable, dicha disposición se
        aplicará en la máxima medida permitida por la ley y se considerará
        separable de estos Términos y no afectará a la validez y aplicabilidad
        de las disposiciones restantes. El hecho de que BAG no insista o exija
        el estricto cumplimiento de cualquiera de las disposiciones de estos
        Términos o no ejerza cualquier derecho o recurso en virtud de estos
        Términos no se interpretará como una renuncia o abandono en cualquier
        medida del derecho de BAG a hacer valer o confiar en cualquiera de
        dichas disposiciones, derechos o recursos en esa o cualquier otra
        instancia; por el contrario, los mismos estarán y permanecerán en pleno
        vigor y efecto. Usted no puede ceder, transferir o disponer de otro modo
        de estos Términos (incluyendo cualquier derecho u obligación en virtud
        del presente), y cualquier pretendida cesión, transferencia u otra
        disposición será nula y sin efecto.
      </Text>
      <Text>
        38. Ley aplicable y jurisdicción. Estos Términos se rigen y se
        interpretarán de acuerdo con las leyes de Islas Vírgenes Británicas sin
        tener en cuenta ninguna elección o conflicto de reglas de leyes.
        Cualquier disputa, controversia o reclamo, ya sea contractual o no
        contractual, que surja de o en conexión con estos Términos, o el
        incumplimiento, terminación o invalidez de los mismos, o cualquier otro
        asunto que surja en virtud de estos Términos, será referido a y
        finalmente resuelto mediante arbitraje administrado por el Centro de
        Arbitraje Internacional de las Islas Vírgenes Británicas bajo las Reglas
        de Arbitraje vigentes cuando se envía la Notificación de Arbitraje. La
        ley de esta cláusula de arbitraje será la ley de las Islas Vírgenes
        Británicas. La sede del arbitraje estará en las Islas Vírgenes
        Británicas y los procedimientos de arbitraje se llevarán a cabo en
        idioma inglés.
      </Text>
    </div>
  );
};

export default ContentTermsConditions;
