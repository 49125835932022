import React from "react";

function PaypalIcon({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 37 44"
    >
      <path
        fill="#2368AC"
        d="M11.158 44c.253-1.595.51-3.188.759-4.783.624-4.01 1.245-8.02 1.858-12.031.042-.274.178-.302.401-.302 1.545 0 3.09.025 4.633-.053 3.24-.163 6.334-.87 9.148-2.586 2.216-1.35 3.94-3.2 5.219-5.482 1.003-1.79 1.665-3.711 2.117-5.77.481.517.758 1.101.991 1.707.568 1.475.64 3.014.477 4.564-.232 2.222-.792 4.353-1.852 6.326-1.43 2.663-3.596 4.414-6.41 5.356-1.888.633-3.838.819-5.811.84-.295.003-.59-.005-.885.003-.912.022-1.55.545-1.704 1.466-.382 2.311-.737 4.628-1.103 6.942-.1.635-.23 1.268-.295 1.906-.093.93-.516 1.576-1.39 1.896L11.158 44z"
      ></path>
      <path
        fill="#2368AC"
        d="M15.883.01c2.131.047 4.733-.1 7.328.091 2.128.156 4.211.536 6.102 1.61 2.437 1.384 3.855 3.51 4.003 6.348.203 3.904-.813 7.513-3.002 10.732-1.573 2.313-3.773 3.8-6.38 4.681-2.198.744-4.466.97-6.766.967a947.713 947.713 0 00-3.456 0c-1.343.003-2.022.583-2.235 1.938-.647 4.109-1.288 8.219-1.92 12.33-.049.319-.168.41-.482.408-2.584-.014-5.17-.005-7.755-.009-.963 0-1.44-.534-1.294-1.496.486-3.194.99-6.385 1.486-9.578.63-4.054 1.262-8.107 1.894-12.161L5.294 3.75c.1-.635.204-1.27.3-1.906C5.767.68 6.506.014 7.661.011 10.246.005 12.831.01 15.883.01z"
      ></path>
    </svg>
  );
}

export default PaypalIcon;
