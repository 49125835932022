import React from "react";

function SliderBullet(props) {

  return (
    <svg width="100" height="8" viewBox="0 0 100 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="6" width="4" height="100" rx="2" transform="rotate(-90 0 6)" fill="#D9D9D9"/>
    <rect y="8" width="8" height="38" rx="4" transform="rotate(-90 0 8)" fill="#040202"/>
    </svg>
  );
}

export default SliderBullet;