import React from "react";

function Search() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#fff"
        d="M12.67 11.851l-2.163-2.147a5.253 5.253 0 00-.591-7.196 5.245 5.245 0 00-8.747 3.767 5.253 5.253 0 004.815 5.377 5.244 5.244 0 003.713-1.137l2.145 2.147a.585.585 0 00.828 0 .584.584 0 000-.81zm-6.244-1.336a4.079 4.079 0 01-3.77-2.521A4.087 4.087 0 015.63 2.425a4.078 4.078 0 014.19 1.737 4.086 4.086 0 01-3.394 6.353z"
      ></path>
    </svg>
  );
}

export default Search;
