import { useRef } from "react";
import BtnScroll from "../../assets/algoritmo/first-section-btn.png";
import "./algoritmo.scss";

const Algoritmo = () => {
  const secondSectionRef = useRef(null);
  const btnScrollHandle = () => {
    const secondSectionElement = secondSectionRef.current;
    secondSectionElement.scrollIntoView();
  };
  return (
    <div id="algoritmo-page-v2">
      <section className="hero">
        <div className="hero__video">
          <video muted autoPlay playsInline loop>
            <source src="/images/algoritmo/herovideo.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="hero__wrapper container">
          <h1 className="title">¿POR QUÉ SON TAN GENIALES?</h1>
          <div className="btn-scroll">
            <button onClick={btnScrollHandle}>
              <img src={BtnScroll} alt="scroll" />
            </button>
          </div>
        </div>
      </section>
      <section className="section-mona" ref={secondSectionRef}>
        <div className="mona__video d-none d-lg-block">
          <video muted autoPlay playsInline loop>
            <source
              src="/images/algoritmo/sectionmona-video.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div>
          <img
            width={"100%"}
            src="/images/algoritmo/sectionmona-video-gift.gif"
            alt="gif"
          />
        </div>
      </section>
      <section className="section-info">
        <div className="info-wrapper container">
          <div className="info-wrapper-text">
            <h2 className="title">¿POR QUÉ SON TAN GENIALES?</h2>
            <p className="text">
              Cada Fancy Mona está generada por una{" "}
              <strong>combinación de rasgos única.</strong> No hay dos Fancy
              Monas iguales.
            </p>
            <p className="text">
              El ranking de cada Fancy Mona se determina por la{" "}
              <strong>
                cantidad de veces que cada uno de sus rasgos aparece en la
                colección. Cuanto más únicos sean sus rasgos, mejor será su
                ranking.
              </strong>
            </p>
            <p className="text">
              Para calcular el ranking de cada Fancy Mona utilizamos un
              algoritmo que las analizó y jerarquizo según su composición.
            </p>
          </div>

          <div className="info-wrapper-table">
            <h2 className="title-mobile">¿POR QUÉ SON TAN GENIALES?</h2>
            <div className="flex-table-containar">
              <div>
                <div className="grid-table-image">
                  <table>
                    <tr>
                      <td colspan="2" className="p-0 first-td">
                        <div className="table-header">
                          <div className="d-flex flex-column justify-content-center">
                            <p>RANKING #1047</p>
                            <p>FANCY MONA #1313</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Rosa
                        <span>Fondo</span>
                      </td>
                      <td>
                        23.45%
                        <span>Ocurrencia</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Verde
                        <span>Pelaje</span>
                      </td>
                      <td>
                        43.52%
                        <span>Ocurrencia</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Camisa Mosca
                        <span>Traje</span>
                      </td>
                      <td>
                        17.93%
                        <span>Ocurrencia</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Tocado de serpiente
                        <span>Sombrero</span>
                      </td>
                      <td>
                        10.47%
                        <span>Ocurrencia</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Anteojos Dorados
                        <span>Ojos</span>
                      </td>
                      <td>
                        1.54%
                        <span>Ocurrencia</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Sonrisa
                        <span>Boca</span>
                      </td>
                      <td>
                        65.19%
                        <span>Puntuación</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Ninguno
                        <span>Aros</span>
                      </td>
                      <td>
                        185.66%
                        <span>Ocurrencia</span>
                      </td>
                    </tr>
                  </table>
                  <div className="table-image">
                    <img src="/images/mona-algoritmic.png" alt="mona-table" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-call">
        <div className="call-wrapper container">
          <span className="title">
            Cada una de las 1942 Fancy Monas, fue generada algorítmicamente y
            supervisada por Edgardo Giménez
          </span>
          <p className="text">
            El algoritmo diseñado para producir las Fancy Monas se desarrolló
            por un equipo interdisciplinario que, bajo la dirección de Edgardo
            Giménez, analizó más de 200 piezas de archivo y editó la colección
            minuciosamente sobre más de 53.000.000 posibles combinaciones.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Algoritmo;
