import React from "react";

function ArrowTop({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 20 20"
    >
      <g>
        <path
          fill={color}
          fillRule="evenodd"
          d="M6.076 12.256a.833.833 0 001.179 0l2.744-2.744 2.744 2.744a.833.833 0 101.178-1.179l-3.333-3.333a.833.833 0 00-1.179 0l-3.333 3.333a.833.833 0 000 1.179z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default ArrowTop;
