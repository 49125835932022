import React from "react";

function CloseModal({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="17" fill="black" />
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M18.4143 17.0002L23.7072 11.7072C24.0982 11.3162 24.0982 10.6842 23.7072 10.2932C23.3162 9.90225 22.6843 9.90225 22.2933 10.2932L17.0002 15.5862L11.7072 10.2932C11.3162 9.90225 10.6842 9.90225 10.2932 10.2932C9.90225 10.6842 9.90225 11.3162 10.2932 11.7072L15.5862 17.0002L10.2932 22.2933C9.90225 22.6843 9.90225 23.3162 10.2932 23.7072C10.4882 23.9022 10.7442 24.0002 11.0002 24.0002C11.2562 24.0002 11.5123 23.9022 11.7072 23.7072L17.0002 18.4143L22.2933 23.7072C22.4883 23.9022 22.7442 24.0002 23.0002 24.0002C23.2562 24.0002 23.5122 23.9022 23.7072 23.7072C24.0982 23.3162 24.0982 22.6843 23.7072 22.2933L18.4143 17.0002Z"
        fill="white"
      />
    </svg>
  );
}

export default CloseModal;
