import "./VideoRevealTemplate.scss";

const VideoRevealTemplate = ({ children, column1, column2 }) => {
  return (
    <div id="videoRevealTemplate">
      <div className="videoRevealTemplate">
        {column1 && <div className="area1">{column1}</div>}
        <div className="area2">{children}</div>
        {column2 && <div className="area3">{column2}</div>}
      </div>
    </div>
  );
};

export default VideoRevealTemplate;
