import styled from "styled-components";
import { Button } from "../shared/Button";

const StyledCouter = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px;
  max-width: 129px;
  min-width: 129px;
  width: 129px;
  .lessBtn {
    border-radius: 4px;
    background-color: #e3e3e3;
    &:disabled {
      color: #fff;
      background-color: #b2b2b2;
    }
    color: #000;
  }
  .addBtn {
    border-radius: 4px;
    background-color: #000;
    &:disabled {
      color: #fff;
      background-color: #b2b2b2;
    }
  }
`;

const Counter = ({
  count,
  removeItem,
  addItem,
  disabledAdd,
  disabledRemove,
  ...props
}) => {
  return (
    <StyledCouter {...props}>
      <Button
        color="primary"
        className="lessBtn"
        onClick={removeItem}
        disabled={disabledRemove}
      >
        -
      </Button>
      <span>{count}</span>
      <Button
        color="primary"
        className="addBtn"
        onClick={addItem}
        disabled={disabledAdd}
      >
        +
      </Button>
    </StyledCouter>
  );
};

export { Counter };
