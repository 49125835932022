import axios from "axios";

const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;

export const findMonasByNumber = async (number) => {
  var config = {
    method: "get",
    url: `${DOMAIN}/api/artworks/find-by-number/${number}`,
    headers: {
      "Content-Type": "application/json",
    }
  };

  return axios(config);
};

export const sendTransferBank = (
  quantity,
  includesPrinted,
  email,
  phone,
  shippingInfo,
  checkbox
) => {
  var data = JSON.stringify({
    quantity: quantity,
    includesPrinted: includesPrinted,
    email: email,
    phone: phone,
    doesAcceptNotifications: checkbox,
    shippingInfo: shippingInfo,
  });

  var config = {
    method: "post",
    url: `${DOMAIN}/api/users/process-bank-account-buy`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const getAvailableArtworks = (token) => {
  var config = {
    method: "get",
    url: `${DOMAIN}/api/users/check-available-artworks`,
    headers: { Authorization: `Bearer ${token}` },
  };

  return axios(config);
};

export const getRandomArtworks = async () => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  const config = {
    method: "get",
    url: `${DOMAIN}/api/artworks?quantity=5`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
  };
  return axios(config);
};

export const getArtworksWithFilters = async (
  checkboxValues,
  offsetArtworks
) => {
  const url = `${DOMAIN}/api/artworks/find-with-filters`;
  const headers = { "Content-Type": "application/json" };
  checkboxValues["offsetArtworks"] = offsetArtworks;
  const response = await axios.get(url, { params: checkboxValues, headers });
  return response;
};

export const sendCardPayment = (
  quantity,
  includesPrinted,
  email,
  phone,
  shippingInfo,
  checkbox
) => {
  var data = JSON.stringify({
    quantity: quantity,
    includesPrinted: includesPrinted,
    email: email,
    phone: phone,
    doesAcceptNotifications: checkbox,
    shippingInfo: shippingInfo,
  });

  var config = {
    method: "post",
    url: `${DOMAIN}/api/users/start-credit-card-buy`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const checkCode = (code) => {
  var data = JSON.stringify({
    code: code,
  });

  var config = {
    method: "post",
    url: `${DOMAIN}/api/artworks/check-available-codes`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const sendCodes = (codes, email, phone, shippingInfo, checkbox) => {
  var data = JSON.stringify({
    codes: codes,
    email: email,
    phone: phone,
    doesAcceptNotifications: checkbox,
    shippingInfo: shippingInfo,
  });

  var config = {
    method: "post",
    url: `${DOMAIN}/api/users/process-code-buy`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const requestNft = (token) => {
  var config = {
    method: "post",
    url: `${DOMAIN}/api/artworks/revealed-artworks`,
    headers: { Authorization: `Bearer ${token}` },
  };

  return axios(config);
};
export const requestNftMona = (token) => {
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};

  var config = {
    method: "post",
    url: `${DOMAIN}/api/artworks/revealed-artworks`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
  };

  return axios(config);
};

export const checkArtwork = (quantity) => {
  var data = JSON.stringify({
    intentedQuantity: quantity,
  });

  var config = {
    method: "post",
    url: `${DOMAIN}/api/artworks/check-artworks-stock`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const getProfile = async (itemNumber) => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  const config = {
    method: "get",
    url: `${DOMAIN}/api/artworks/${itemNumber}`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
  };

  return axios(config);
};

export const getExclusiveContent = async (itemNumber, type) => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};

  const config = {
    method: "get",
    url: `${DOMAIN}/api/artworks/${itemNumber}/content/${type}`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
    responseType: "blob",
  };
  const response = await axios(config);

  // TODO: Move this to the page
  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", `fancymona-${itemNumber}-${type}.png`);
  fileLink.setAttribute("target", "_blank");
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
};

export const suscribeToWhilest = async (email) => {
  const data = JSON.stringify({
    email,
  });

  const config = {
    method: "post",
    url: `${DOMAIN}/api/users/whitelist`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const getCollection = async (token) => {
  const config = {
    method: "get",
    url: `${DOMAIN}/api/users/collection`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

export const authenticateUser = async (code) => {
  const config = {
    method: "post",
    url: `${DOMAIN}/api/users/authenticate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { code },
  };

  return axios(config);
};

export const login = async (email) => {
  const config = {
    method: "post",
    url: `${DOMAIN}/api/users/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { email },
  };

  return axios(config);
};

export const sellersLogin = (data) => {
  var config = {
    method: "post",
    url: `${DOMAIN}/api/sellers/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

export const getRefHistoricas = (itemImage) => {
  const data = {
    imageUrl:
      "https://artbag-monas.s3.amazonaws.com/d8a27e31-d3d3-4487-b85b-88179c8b4686.png",
    metadata: [
      {
        key: "Fondo",
        value: "Azul",
      },
      {
        key: "Pelaje",
        value: "Azul",
      },
      {
        key: "Ojos",
        value: "Azul",
      },
      {
        key: "Boca",
        value: "Laugh",
      },
      {
        key: "Accesorios",
        value: "Magic",
      },
      {
        key: "Traje",
        value: "Panda",
      },
      {
        key: "Sombrero",
        value: "Afrika",
      },
    ],
  };

  return data;
};

export const postStartCreditCard = async (data) => {
  var reqdata = JSON.stringify(data);
  const config = {
    method: "post",
    url: `${DOMAIN}/api/users/start-credit-card-buy`,
    headers: {
      "Content-Type": "application/json",
    },
    data: reqdata,
  };

  return axios(config);
};

export const postProcessCreditCard = async (data) => {
  var reqdata = JSON.stringify(data);
  const config = {
    method: "post",
    url: `${DOMAIN}/api/users/process-credit-card-buy`,
    headers: {
      "Content-Type": "application/json",
    },
    data: reqdata,
  };

  return axios(config);
};

export const postSellerStartCreditCard = async (data) => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  var reqdata = JSON.stringify(data);
  const config = {
    method: "post",
    url: `${DOMAIN}/api/users/start-credit-card-buy`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
    data: reqdata,
  };

  return axios(config);
};

export const postSellerProcessCreditCard = async (data) => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  var reqdata = JSON.stringify(data);
  const config = {
    method: "post",
    url: `${DOMAIN}/api/sellers/process-sale`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
    data: reqdata,
  };

  return axios(config);
};

export const postSendReveal = async (data) => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  var reqdata = JSON.stringify(data);
  const config = {
    method: "post",
    url: `${DOMAIN}/api/sellers/send-reveal`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
    data: reqdata,
  };

  return axios(config);
};

export const getListToRevelated = async () => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  const config = {
    method: "get",
    url: `${DOMAIN}/api/sellers/list-to-revelated`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
  };

  return axios(config);
};

export const getRevealArtwork = async () => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};
  const config = {
    method: "get",
    url: `${DOMAIN}/api/sellers/reveal-artwork`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
  };

  return axios(config);
};

export const postMonitorFinalized = async () => {
  const data = { finalized: true };
  var reqdata = JSON.stringify(data);
  const config = {
    method: "post",
    url: `${DOMAIN}/api/sellers/monitor-finalized`,
    headers: {
      "Content-Type": "application/json",
    },
    data: reqdata,
  };

  return axios(config);
};

export const getArchiveDetail = (
  id
) => {
  var config = {
    method: "get",
    url: `${DOMAIN}/api/artworks/get-archive-detail/${id}`,
    headers: {
      "Content-Type": "application/json",
    }
  };

  return axios(config);
};

export const getHistorySales = () => {
  const token = localStorage.getItem("token");
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};

  var config = {
    method: 'get',
    url: `${DOMAIN}/api/sellers/sales`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    }
  };

  return axios(config);
};

