import React from "react";
import styled from "styled-components";

const StyledTitle = styled.h1`
  color: #000;
  font-family: ${(props) => props.theme.font.roboto};
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TitleCheckout = ({ children }) => {
  return <StyledTitle>{children}</StyledTitle>;
};

const StyledSubTitle = styled.p`
  color: #000;
  font-family: ${(props) => props.theme.font.roboto};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
`;

const SubTitleCheckout = ({ children }) => {
  return <StyledSubTitle>{children}</StyledSubTitle>;
};

const StyledSTitle2 = styled.h2`
  color: #000;
  font-family: ${(props) => props.theme.font.roboto};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Title2Checkout = ({ children }) => {
  return <StyledSTitle2>{children}</StyledSTitle2>;
};

const StyledDescription = styled.p`
  color: #7e7e7e;
  font-family: ${(props) => props.theme.font.roboto};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
`;

const DescriptionCheckout = ({ children }) => {
  return <StyledDescription>{children}</StyledDescription>;
};

export { TitleCheckout, SubTitleCheckout, Title2Checkout, DescriptionCheckout };
