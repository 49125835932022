import "./PaymentMethod.scss";

const CryptoPayment = () => {
  return (
    <div className="method-qr qr-crypto">
      <div className="qr-wrapper">
        <img width={"100%"} src="/images/qr-code-crypto.svg" alt="qr" />
      </div>
      <span className="method-qr-span">USDT red Tron</span>
    </div>
  );
};

export default CryptoPayment;
