import React from "react";

function TransferIcon({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 45 51"
    >
      <g fill="#2368AC">
        <path d="M23.96 6.412c-1.398-1.306-2.609-2.461-3.852-3.582-.348-.314-.36-.493-.017-.813a29.774 29.774 0 001.75-1.8c.275-.303.45-.273.733-.006 2.57 2.415 5.15 4.825 7.742 7.22.308.285.259.446-.006.718-2.469 2.516-4.929 5.04-7.378 7.575-.282.292-.448.276-.722.002a28.69 28.69 0 00-1.837-1.713c-.385-.322-.309-.509.006-.823 1.128-1.125 2.226-2.28 3.498-3.592-1.001 0-1.805-.038-2.604.006-9.31.505-17.01 7.774-17.9 16.887-1.001 10.254 6.38 19.285 16.817 20.57 10.695 1.319 20.585-6.55 21.388-17.076.493-6.474-1.85-11.84-6.868-16.072-.397-.336-.39-.543-.076-.894.355-.394.667-.827.998-1.241.648-.814.668-.838 1.466-.121 6.905 6.217 9.49 13.854 6.938 22.695-2.56 8.872-8.84 14.21-18.12 15.747C13.8 52.105 2.298 43.868.308 32.026-1.739 19.846 6.624 8.51 19.066 6.6c1.541-.237 3.099-.257 4.893-.187v-.002z"></path>
        <path d="M22.934 18.324h.154c1.513 0 1.532 0 1.542 1.447.004.43.13.598.575.693 1.751.37 3.167 1.225 4.082 2.797.255.44.185.632-.286.813-.641.248-1.262.551-1.88.851-.33.16-.532.186-.826-.15-1.279-1.466-4.419-1.892-6.115-.884-.548.326-.846.783-.801 1.43.045.657.5.957 1.056 1.15 1.39.485 2.871.479 4.302.745.756.14 1.502.302 2.224.567 1.84.678 2.986 1.908 3.156 3.872.169 1.92-.503 3.494-2.129 4.633-.849.596-1.806.94-2.83 1.097-.411.063-.547.218-.53.626.024.545.185 1.284-.103 1.594-.378.409-1.157.129-1.76.137-1.59.024-1.617.008-1.61-1.54.002-.533-.218-.66-.7-.742-2.284-.399-4.105-1.472-5.221-3.549-.255-.473-.191-.682.339-.86.748-.255 1.465-.593 2.195-.897.215-.09.402-.207.571.1.77 1.398 2.115 1.893 3.603 2.124 1.031.161 2.045.027 3.02-.326.954-.346 1.483-1.022 1.45-1.831-.037-.877-.693-1.24-1.408-1.505-1.406-.522-2.912-.526-4.371-.781a10.929 10.929 0 01-2.28-.642c-3.253-1.337-3.222-5.61-1.11-7.356.969-.801 2.083-1.272 3.31-1.488.435-.076.595-.225.604-.672.024-1.45.043-1.45 1.551-1.45h.23l-.004-.003z"></path>
      </g>
    </svg>
  );
}

export default TransferIcon;
