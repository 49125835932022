import "./RevealArtworkTemplate.scss";

const RevealArtworkTemplate = ({ children, column1, column2 }) => {
  return (
    <div id="revealArtworkTemplate">
      <div className="revealArtworkTemplate">
        {column1 && <div className="area1">{column1}</div>}
        <div className="area2">{children}</div>
        {column2 && <div className="area3">{column2}</div>}
      </div>
    </div>
  );
};

export default RevealArtworkTemplate;
