import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getHistorySales,
  getRevealArtwork,
  postMonitorFinalized,
} from "../../services/axiosService";
import { Button } from "../../components/shared/Button";
import { CartContext } from "../../context/Cart";
import NftIcon from "../../components/icons/nftIcon";
import routes from "../../routes/routes";
import Spinner from "../../components/shared/Spinner";
import { ToastContainer, toast } from "react-toastify";
import VideoRevealTemplate from "../../templates/VideoRevealTemplate";
import RevealTemplate from "../../templates/RevealTemplate";
import RevealArtworkTemplate from "../../templates/RevealArtworkTemplate";
import RevealButton from "../../components/shared/ButtonReveal";
import HeadingHr from "../../components/shared/HeadingHr";
import CustomDropdown from "../../components/shared/Dropdown";
import User from "../../components/icons/User";
import ArrowTop from "../../components/icons/ArrowTop";
import ListIcon from "../../components/icons/ListIcon";
import HistoryIcon from "../../components/icons/HistoryIcon";
import LogOutIcon from "../../components/icons/LogOutIcon";
import ModalHistorySales from "../../components/modals/ModalHistorySales";

const RemoteReveal = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { resetCart, saveNumberMonas, numberMonas, user } =
    useContext(CartContext);
  const [artwork, setArtwork] = useState(false);
  const [left, setLeft] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [playImg, setPlayImg] = useState(true);
  const [historySalesShowModal, setHistorySalesShowModal] = useState(false);
  const [historySales, setHistorySales] = useState({});

  useEffect(() => {
    const requestHistorySales = async () => {
      await getHistorySales()
        .then((response) => {
          setHistorySales(response.data);
        })
        .catch((error) => {});
    };
    requestHistorySales();
  }, []);

  const clickOnPlayImage = async () => {
    setLoader(false);
    setPlayImg(false);
    setShowVideo(true);
    startVideoReveal();
  };

  const startVideoReveal = () => {
    const videoElement = ref.current;
    if (videoElement) {
      videoElement.play();
    }
  };

  const getMona = async () => {
    const notify = (text) => toast.error(text);
    /* setLoader(true); */
    setPlayImg(false);
    try {
      const response = await getRevealArtwork();
      setArtwork(response.data.artwork);
      setLeft(response.data.left);
    } catch (error) {
      notify(error.message);
      setLoader(false);
    }
  };

  const reFetchMona = () => {
    setShowVideo(false);
    setArtwork(false);
    saveNumberMonas(numberMonas - 1);
    setPlayImg(true);
  };

  const printMona = async () => {
    setShowVideo(false);
  };

  const redirectToForm = async () => {
    const notify = (text) => toast.error(text);
    resetCart();
    try {
      const response = await postMonitorFinalized();
      if (response.status === 200) {
        saveNumberMonas(0);
        navigate(routes.comercial_checkout);
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate(routes.sellers_login);
  };

  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <>
          <ModalHistorySales
            modal={historySalesShowModal}
            setModal={setHistorySalesShowModal}
            title={"Historial de ventas"}
            subtitle={"Resumen histórico Fancy Monas vendidas"}
          >
            <div id="table">
              <div class="seller-header">
                <div>Vendedor</div>
              </div>
              <div class="client-header">
                <div>Cliente</div>
              </div>
              {historySales?.sales?.map((item, index) => {
                return (
                  <>
                    <div class="seller-col">{item.sellerEmail}</div>
                    <div class="client-col">
                      <div class="primary-data">
                        <div class="name">{item.name ?? ''}</div>
                        <div class="email">{item.userEmail ?? ''}</div>
                        <div class="phone">{item.phone ?? ''}</div>
                        <div class="artworks">
                          {item.artworks?.map((fancymona, index) => {
                            return (
                              <div class="fancymona">
                                <img
                                  src={fancymona.compressedImageUrl}
                                  alt="mona"
                                />
                                <span>#{fancymona.number}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div class="secondary-data">

                        { item?.currency?.length > 0 && item?.signal?.length > 0
                          ? <div class="signal">{item.currency ?? ''} {item.signal ?? ''}</div>
                          : <div class="signal">-</div>
                        }
                        
                        <div class="payment_method">Metodo de pago: {item.paymentMethod}</div>

                        { item.additionalIndications.length > 0
                          ? <div class="comment">Comentario: {item.additionalIndications ?? ''}</div>
                          : <div class="comment">-</div>
                        }
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </ModalHistorySales>
          {showVideo ? (
            <VideoRevealTemplate>
              <video
                onPlay={getMona}
                onEnded={printMona}
                ref={ref}
                width="100%"
                height="100%"
                poster=""
                aria-labelledby="videodesc"
                preload="metadata"
                role="img"
                muted={true}
                playsInline
                autoPlay
              >
                <source
                  src="https://artbag-monas.s3.amazonaws.com/videos/reveal-countdown.mp4"
                  type="video/mp4"
                />
              </video>
              <div />
            </VideoRevealTemplate>
          ) : playImg ? (
            <RevealTemplate
              heading={
                <div className="heading-dropdown">
                  <HeadingHr heading={"Cantidad de monas"} />
                  <CustomDropdown
                    text={user?.email ?? "Opciones"}
                    startIcon={
                      <User width={15} height={15} color={"#89CEF8"} />
                    }
                    endIcon={<ArrowTop width={20} height={20} color={"#000"} />}
                    items={[
                      {
                        name: (
                          <div className="d-flex align-items-cente custom-dropdown-gap">
                            <ListIcon width={15} height={15} color={"#000"} />{" "}
                            Cola de revelado
                          </div>
                        ),
                        click: () => navigate(routes.revelated_list),
                      },
                      {
                        name: (
                          <div className="d-flex align-items-center custom-dropdown-gap">
                            <HistoryIcon
                              width={15}
                              height={15}
                              color={"#000"}
                            />{" "}
                            Historial de ventas
                          </div>
                        ),
                        click: () => setHistorySalesShowModal(true),
                      },
                      {
                        name: (
                          <div className="d-flex align-items-center custom-dropdown-gap">
                            <LogOutIcon
                              width={15}
                              height={15}
                              color={"#dc3545"}
                            />{" "}
                            <span className="text-danger">Desconectar</span>
                          </div>
                        ),
                        click: logout,
                      },
                    ]}
                  />
                </div>
              }
              footer={
                <>
                  <RevealButton onClick={() => navigate(routes.revelated_list)}>
                    Volver
                  </RevealButton>
                </>
              }
            >
              <div className="play-image-wrapper">
                <h1 className="title">
                  Tienes {numberMonas} Fancy Mona lista para ser revelada
                </h1>
                <p className="text">Clica en el botón para comenzar</p>
                <img
                  className="play-image"
                  onClick={clickOnPlayImage}
                  src="/images/boton-reveal.svg"
                  alt="play-btn"
                />
              </div>
            </RevealTemplate>
          ) : (
            <RevealArtworkTemplate
              column1={<div />}
              column2={
                <>
                  {artwork ? (
                    <div className="reveal-mona">
                      <div className="box-reveal-artwork">
                        <NftIcon />
                        <span>OBRA REVELADA</span>
                      </div>
                      <div className="message">
                        <h1 className="title">
                          ¡Felicidades! <br /> Tu Fancy Mona es la
                        </h1>
                        <h2 className="artwork-number">#{artwork.number}</h2>
                      </div>
                      <RevealButton
                        onClick={left === 0 ? redirectToForm : reFetchMona}
                      >
                        {left === 0 ? "Salir" : <>Revelar {left} Monas</>}
                      </RevealButton>
                    </div>
                  ) : null}
                </>
              }
            >
              {!artwork ? (
                <>
                  <h4 className="text-danger mb-5">
                    ¡Vaya ! No hemos encontrado ninguna mona para revelar.
                  </h4>
                  <Button
                    color="secondary"
                    onClick={() => navigate(routes.revelated_list)}
                  >
                    Volver a la lista de revelado
                  </Button>
                </>
              ) : (
                <img src={artwork?.compressedImageUrl} alt={artwork?.number} />
              )}
            </RevealArtworkTemplate>
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
};

export default RemoteReveal;
