import React, { useState } from "react";
import { AppContext } from "../../App.js";

function Login({ handleLogin, isClosabel=true }) {
  const [email, setEmail] = useState("");
  const { setLoginModal, setSuccesEmail } = React.useContext(AppContext);
  const [isVisible, setIsVisible] = useState(true);

  document.addEventListener("keyup", (e) => {
    // if we press the ESC
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setLoginModal(false);
    }
  });

  document.addEventListener("click", (e) => {
    if (e.target === document.querySelector(".modal-background")) {
      setLoginModal(false);
    }
  });

  let handleOnClick = () => {
    setLoginModal(false);
  };

  let handleOnClickBtn = () => {
    setLoginModal(false);
    setSuccesEmail(true);
  };

  let onLogin = (e) => {
    e.preventDefault();
    handleLogin(email);
    setIsVisible(false);
  };

  let handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  return isVisible ? (
    <div id="modal-term-section" className={"modal-background"}>
      <div className="modal-term-container d-flex flex-column justify-content-center">
        {isClosabel && (
        <div className="Close-Container position-relative mb-4">
          <div
            className="IconClose position-absolute end-0"
            onClick={handleOnClick}
          ></div>
        </div>
        )}
        <div className="LoginIcon mx-auto mb-5"></div>
        <div className="px-4">
          <h5 className="text-center modal-title mb-3">Enviar link mágico</h5>
          <p className="mb-4 modal-text">
            ¡Obtenga un magic link enviado a su correo electrónico que lo hará
            loguearse instantáneamente!
          </p>
        </div>
        <form onSubmit={(e) => onLogin(e)}>
          <div className="wrapper">
            <input
              onChange={(e) => {
                handleChange(e);
              }}
              name="email"
              type="text"
              className="form-control modal-input mb-4"
              placeholder="Email"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
            />
          </div>

          <button
            className="btn btn-dark modal-button"
            type="submit"
            id="button-addon2"
          >
            ✉️ Enviar magic link
          </button>
        </form>
      </div>
    </div>
  ) : (
    <div id="modal-term-section" className={"modal-background"}>
      <div className="modal-term-container d-flex flex-column justify-content-center">
        <div className="Close-Container position-relative mb-4">
          <div
            className="modalClose position-absolute end-0"
            onClick={handleOnClick}
          ></div>
        </div>
        <div className="modalIconSuccess mx-auto mb-5"></div>
        <div className="px-4">
          <h5 className="text-center modal-title mb-3">¡Correo enviado!</h5>
          <p className="mb-4 modal-text">
            Si existe tu correo registrado, enviaremos un link a tu correo para
            que inicies sesión
          </p>
        </div>

        <button
          className="btn btn-dark modal-button"
          type="submit"
          id="button-addon2"
          onClick={handleOnClickBtn}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
}

export default Login;
