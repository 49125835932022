import React from "react";

const CheckIcon = ({ width = "18", height = "14", color = "white" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.68887 12.4409C6.54818 12.4411 6.40884 12.4135 6.27886 12.3596C6.14889 12.3057 6.03084 12.2267 5.9315 12.1271L1.81808 8.01368C1.71768 7.91442 1.63789 7.7963 1.58331 7.66611C1.52872 7.53591 1.50041 7.39621 1.5 7.25503C1.4996 7.11385 1.5271 6.97399 1.58094 6.84348C1.63478 6.71297 1.71388 6.59439 1.81371 6.49456C1.91353 6.39473 2.03211 6.31562 2.16261 6.26178C2.29312 6.20794 2.43298 6.18042 2.57416 6.18082C2.71533 6.18122 2.85504 6.20952 2.98524 6.2641C3.11544 6.31869 3.23356 6.39847 3.33282 6.49886L6.68887 9.85487L14.6731 1.87073C14.8741 1.67055 15.1463 1.55829 15.43 1.55859C15.7137 1.5589 15.9857 1.67174 16.1863 1.87235C16.3869 2.07296 16.4997 2.34496 16.5 2.62866C16.5003 2.91236 16.388 3.18459 16.1878 3.3856L7.44626 12.1272C7.34691 12.2268 7.22885 12.3058 7.09888 12.3596C6.9689 12.4135 6.82956 12.4411 6.68887 12.4409Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;
