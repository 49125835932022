import React from "react";
import styled from "styled-components";
import { useField, useFormikContext } from "formik";

const StyledInput = styled.input`
  border-radius: 6px;
  border: ${(props) =>
    props.isError
      ? "1px solid #E20000"
      : props.disabled
      ? "0px"
      : "1px solid #000"};
  background-color: ${(props) => props.disabled && "#EAEAEA"};
  height: 48px;
  padding-left: 10px;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  width: 100%;
`;

const Input = ({ disabled, onChange, handleBlur, isError, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledInput
        {...field}
        {...props}
        isError={meta.touched && !!meta.error}
        disabled={disabled}
        onError={meta.touched && !!meta.error}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const StyledInputDropdown = styled.input`
  border-radius: 6px;
  border: 1px solid #000;
  height: 40px;
  border-left: 0px;
`;

const InputDropdown = ({ ariaLabel, describedby, text, type }) => {
  return (
    <StyledInputDropdown
      type={type}
      className="form-control"
      placeholder
      aria-label={ariaLabel}
      aria-describedby={describedby}
    />
  );
};

const StyledTextArea = styled.textarea`
  border-radius: 6px;
  border: ${(props) =>
    props.isError
      ? "1px solid #E20000"
      : props.disabled
      ? "0px"
      : "1px solid #000"};
  background-color: ${(props) => props.disabled && "#EAEAEA"};
  min-height: 68px;
  height: auto;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  width: 100%;
`;

const TextArea = ({ disabled, onChange, handleBlur, isError, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledTextArea
        {...field}
        {...props}
        isError={meta.touched && !!meta.error}
        disabled={disabled}
      />

      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const StyledErrorMessage = styled.p`
  color: #e20000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 128.571% */
  margin-top: 6px;
`;

const InputErrorMessage = ({ children }) => {
  return <StyledErrorMessage>{children}</StyledErrorMessage>;
};

const StyledSelect = styled.select`
  border-radius: 6px;
  border: ${(props) =>
    props.isError
      ? "1px solid #E20000"
      : props.disabled
      ? "0px"
      : "1px solid #000"};
  background-color: ${(props) => props.disabled && "#EAEAEA"};
  height: 48px;
  padding-left: 10px;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  width: 100%;
`;

const Select = ({
  disabled,
  onChange,
  handleBlur,
  isError,
  children,
  ...props
}) => {
  const { handleChange } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <>
      <StyledSelect
        className="form-select"
        aria-label="select"
        type="select"
        disabled={disabled}
        onChange={handleChange}
        {...field}
        {...props}
      >
        {children}
      </StyledSelect>
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const StyledInputRadio = styled.input`
  height: 14px;
  border: 1px solid grey;
`;

const InputRadio = ({ ...props }) => {
  return <StyledInputRadio type="radio" {...props} />;
};

export {
  Input,
  InputDropdown,
  TextArea,
  InputErrorMessage,
  Select,
  InputRadio,
};
