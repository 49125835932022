import { useContext, useEffect, useRef, useState } from "react";
import {
  postProcessCreditCard,
  requestNftMona,
} from "../../services/axiosService";
import { CartContext } from "../../context/Cart";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/shared/Button";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../components/shared/Spinner";
import NftIcon from "../../components/icons/nftIcon";
import routes from "../../routes/routes";
import "../../styles/revelated.scss";

const ThanksPage = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { resetCart } = useContext(CartContext);
  const [numberMonas, seetNumberMonas] = useState();
  const [loader, setLoader] = useState(false);
  const [approved, setApproved] = useState(false);
  const [artwork, setArtwork] = useState(false);
  const [left, setLeft] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [playImg, setPlayImg] = useState(true);
  const [loadVideo, setLoadVideo] = useState(false);
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const paymentID = params.get("paymentId");
  const payerID = params.get("PayerID");

  useEffect(() => {
    const getNumberMonaFromStorage = localStorage.getItem("numMonas");
    seetNumberMonas(getNumberMonaFromStorage);
    setPlayImg(true);
    proccessCreditCard();
  }, []);

  const proccessCreditCard = async () => {
    const x = paymentID;
    const y = payerID;
    try {
      const response = await postProcessCreditCard({
        paymentId: x,
        payerId: y,
      });
      setApproved(response.data.approved);
    } catch (error) {
      console.log(error);
    }
  };

  const clickOnPlayImage = async () => {
    setLoader(false);
    setPlayImg(false);
    if (approved) {
      setLoadVideo(true);
      setShowVideo(true);
      startVideoReveal();
    }
  };

  const startVideoReveal = () => {
    const videoElement = ref.current;
    if (videoElement) {
      videoElement.play();
    }
  };

  const getMona = async () => {
    const notify = (text) => toast.error(text);
    const storedToken = localStorage.getItem("token");
    setLoader(true);
    setPlayImg(false);
    try {
      const response = await requestNftMona(storedToken);
      setArtwork(response.data.artwork);
      setLeft(response.data.left);
      setLoader(false);
    } catch (error) {
      notify(error.message);
      setLoader(false);
    }
  };

  const reFetchMona = () => {
    setShowVideo(false);
    setArtwork(false);
    localStorage.setItem("numMonas", numberMonas - 1);
    seetNumberMonas(numberMonas - 1);
    setPlayImg(true);
    /* startVideoReveal(); */
  };

  const printMona = async () => {
    setShowVideo(false);
    getMona();
  };

  const redirectToForm = () => {
    localStorage.removeItem("numMonas");
    resetCart();
    navigate(`${routes.my_coleccion}`);
  };

  return (
    <div id="revelated">
      <div className="wrapper-revelated">
        {loader ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : showVideo || loadVideo ? (
          <div className="overflow-hidden wrapper-video-reveal show-video-reveal">
            {loadVideo && <Spinner />}
            {showVideo && (
              <>
                <div />
                <div className="mona">
                  <video
                    onLoadedData={() => {
                      setLoadVideo(false);
                    }}
                    onEnded={printMona}
                    ref={ref}
                    className="discover-video"
                    width="100%"
                    height="100%"
                    poster=""
                    aria-labelledby="videodesc"
                    preload="metadata"
                    role="img"
                    muted={true}
                    playsInline
                    autoPlay
                  >
                    <source
                      src="https://artbag-monas.s3.amazonaws.com/videos/reveal-countdown.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div />
              </>
            )}
          </div>
        ) : (
          <>
            {artwork ? (
              <div className="overflow-hidden wrapper-video-reveal on-artwork">
                <div />
                <div className="mona show-mona">
                  <img
                    src={artwork?.compressedImageUrl}
                    alt={artwork?.number}
                  />
                </div>
                <div className="grid-btn-reveal">
                  {artwork?.compressedImageUrl && (
                    <>
                      <div className="box-reveal-artwork">
                        <NftIcon />
                        <span>OBRA REVELADA</span>
                      </div>
                      <div>
                        <h1 className="title">
                          ¡Felicidades! <br /> Tu Fancy Mona es la
                        </h1>
                        <h2 className="artwork-number">#{artwork.number}</h2>
                      </div>
                      <Button
                        color="secondary"
                        fullwidth
                        onClick={left === 0 ? redirectToForm : reFetchMona}
                      >
                        {left === 0 ? (
                          "Ver mi colección"
                        ) : (
                          <>Revelar {left} Monas</>
                        )}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ) : (
              playImg && (
                <div className="overflow-hidden wrapper-video-reveal">
                  <div />
                  <div className="mona start-btn">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <h1 className="play-title">
                        Tienes {numberMonas} Fancy Mona lista para ser revelada
                      </h1>
                      <span>Clica en el botón para comenzar</span>
                      <img
                        className="play-btn"
                        onClick={clickOnPlayImage}
                        src="/images/boton-reveal.svg"
                        alt="play-btn"
                      />
                    </div>
                  </div>
                  <div />
                </div>
              )
            )}
          </>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </div>
  );
};

export default ThanksPage;
