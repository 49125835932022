import React from "react";
import CheckIcon from "../../components/icons/CheckIcon";

const PaypalSuccess = () => {
  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <div>
        <CheckIcon width="100" height="auto" color="#bbf7b8" />
      </div>
      <br />
      <h3>Pago realizado correctamente</h3>
    </div>
  );
};

export default PaypalSuccess;
