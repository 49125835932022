const routes = {
  // Roots
  home: "/",
  algoritmo: "/algoritmo",
  landing: "/landing",
  /* checkout: "/checkout", */
  redeem: "/redeem",
  discover: "/discover",
  items: "/items",
  coleccion: "/collection",
  my_coleccion: "/my-collection",
  authentication: "/auth",
  the_artist: "/the-artist",
  archive: "/archive",
  archive_detail: "/archive-detail",
  fancy_checkout: "/fancy-checkout",
  sellers_login: "/sellers-login",
  comercial_checkout: "/comercial-checkout",
  revelated_list: "/revelated-list",
  paypal_success: "/paypal-success",
  paypal_error: "/paypal-payment-error",
  ok_redeem: "/ok-redeem",
  // Nested Dashboard pages
};

export default routes;
