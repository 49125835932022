import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App.js";
import { sendCodes } from "../../services/axiosService";
import CheckoutBuyerForm from "../checkout/CheckoutBuyerForm";
import CheckoutCheckboxes from "../checkout/CheckoutCheckboxes";
import CheckoutPrintedForm from "../checkout/CheckoutPrintedForm";
import Chevron from "../icons/Chevron";
import Asterisk from "../icons/Asterisk";
import CheckOk from "../icons/CheckOk";
import CheckNok from "../icons/CheckNok";
import { Input } from "../shared/Input.jsx";
import { Label } from "../shared/Label.jsx";

export default function RedeemForm({
  codes,
  printedEnabled,
  setIsLoading,
  setRedeemScreen,
  data,
  setData,
  loginSchema,
  loginSchemaPrinted,
  termsCheckbox,
  setTermsCheckbox,
  newsCheckbox,
  setNewsCheckbox,
  validatedNumber1,
  setValidatedNumber1,
  validatedNumber2,
  setValidatedNumber2,
  initialCredentials,
  initialCredentialsPrinted,
  setError,
  setCodes,
}) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { setToken } = React.useContext(AppContext);

  var navigate = useNavigate();

  const backScreen = () => {
    setIsLoading(true);
    setTimeout(() => {
      setRedeemScreen(0);
      setData(initialCredentials);
      setCodes([]);
      //setTermsCheckbox(false);
      setNewsCheckbox(false);
      setIsLoading(false);
    }, 1500);
  };

  const clickCodes = async (values, actions) => {
    setData({ ...data, email: values.email, phone: values.phone });
    setIsLoading(true);
    setError(false);
    var acceptedCodes = [];
    for (var i = 0; i < codes.length; i++) {
      if (codes[i].accepted) acceptedCodes.push(codes[i].id);
    }
    var shippingInfo = {
      name: values.name + " " + values.surname,
      country: values.country,
      postalCode: values.zip,
      state: values.province,
      neighborhood: values.city,
      address: values.address,
      deptNumber: values.number ? values.number : "",
      addressNumber: values.number ? values.number : "",
      contactPhone: values.phone,
      additionalIndications: values.other ? values.other : "",
    };
    await sendCodes(
      acceptedCodes,
      values.email,
      values.phone,
      shippingInfo,
      newsCheckbox
    )
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        //setToken(response.data.token);
        setTimeout(() => {
          navigate(`/ok-redeem?redeem_token=${response.data.token}`);
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        setError(true);
        setIsLoading(false);
      });
  };

  return (
    <section className="redeem-checkout checkout d-flex flex-column justify-content-center align-items-center">
      <div className="title-redeem-checkout d-flex flex-column align-items-start">
        <h4>Revelado</h4>
        <hr></hr>
      </div>
      <Formik
        // *** Initial values that the form will take
        enableReinitialize={true}
        initialValues={{
          email: "",
          phone: "",
          name: "",
          surname: "",
          country: "",
          zip: "",
          province: "",
          city: "",
          address: "",
          number: "",
          other: "",
          isSubmitting: false,
          errorMessage: null,
        }}
        // *** Yup Validation Schema ***
        validationSchema={loginSchema}
        // ** onSubmit Event
        onSubmit={(values, actions) => {
          clickCodes(values, actions);
        }}
      >
        {({
          touched,
          errors,
          isValid,
          values,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <Form className="w-100 checkout-form redeem-form d-flex flex-column align-items-center">
            <div className="checkout-bottom d-flex flex-column flex-lg-row justify-content-start justify-content-lg-center align-items-center align-items-lg-start">
              <div className="checkout-bottom-left d-flex flex-column">
                <div className="title-redeem-checkout d-flex flex-column align-items-start">
                  <div className="w-100 d-flex flex-column align-items-start">
                    <h3>Completa tus datos</h3>
                    <h5>Añade tu información personal y de contacto</h5>
                    <hr></hr>
                  </div>
                  <div className="buyer-form-container d-flex flex-column flex-lg-row">
                    <div className="buyer-form-container-field d-flex flex-column">
                      <p>Nombre</p>
                      {errors?.name && values?.name !== "" ? (
                        <CheckNok />
                      ) : !errors.name && values?.name !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="name"
                        className={
                          errors.name && values?.name !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.name && values?.name !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="name"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.name && touched.name ? (
                        <ErrorMessage
                          name="name"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                    <div className="buyer-form-container-field d-flex flex-column">
                      <p>Apellido</p>
                      {errors?.surname && values?.surname !== "" ? (
                        <CheckNok />
                      ) : !errors.surname && values?.surname !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="surname"
                        className={
                          errors.surname && values?.surname !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.surname && values?.surname !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="surname"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.surname && touched.surname ? (
                        <ErrorMessage
                          name="surname"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="buyer-form-container d-flex flex-column flex-lg-row">
                    <div className="buyer-form-container-field d-flex flex-column">
                      <p>Email</p>
                      {errors?.email && values?.email !== "" ? (
                        <CheckNok />
                      ) : !errors.email && values?.email !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="email"
                        className={
                          errors.email && values?.email !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.email && values?.email !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="email"
                        name="email"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.email && touched.email ? (
                        <ErrorMessage
                          name="email"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                    <div className="buyer-form-container-field d-flex flex-column">
                      <p>Teléfono</p>
                      {errors?.phone && values?.phone !== "" ? (
                        <CheckNok />
                      ) : !errors.phone && values?.phone !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="phone"
                        className={
                          errors?.phone && values?.phone !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.phone && values?.phone !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="phone"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.phone && touched.phone ? (
                        <ErrorMessage
                          name="phone"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="title-redeem-checkout d-flex flex-column align-items-start">
                  <div className="w-100 d-flex flex-column align-items-start">
                    <h3>Dirección de envío</h3>
                    <h5>Los envíos son gratis dentro de Argentina</h5>
                    <h5>
                      Los envíos al exterior tienen un costo que queda a cargo
                      del comprador
                    </h5>
                    <hr></hr>
                  </div>
                  <div className="buyer-form-container d-flex flex-column flex-lg-row">
                    <div className="buyer-form-container-field buyer-form-container-field-one d-flex flex-column">
                      <p>Dirección</p>
                      {errors.address && values?.address !== "" ? (
                        <CheckNok />
                      ) : !errors.address && values?.address !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="address"
                        className={
                          errors.address && values?.address !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.address && values?.address !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="address"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.address && touched.address ? (
                        <ErrorMessage
                          name="address"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="buyer-form-container d-flex flex-column flex-lg-row">
                    <div className="buyer-form-container-field d-flex flex-column">
                      <p>Número</p>
                      {errors.number && values?.number !== "" ? (
                        <CheckNok />
                      ) : !errors.number && values?.number !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="number"
                        className={
                          errors.number && values?.number !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.number && values?.number !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="number"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.number && touched.number ? (
                        <ErrorMessage
                          name="number"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                    <div className="buyer-form-container-field d-flex flex-column">
                      <p>Código postal</p>
                      {errors.zip && values?.zip !== "" ? (
                        <CheckNok />
                      ) : !errors.zip && values?.zip !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="zip"
                        className={
                          errors?.zip && values?.zip !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.zip && values?.zip !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="zip"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.zip && touched.zip ? (
                        <ErrorMessage name="zip" component="div"></ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="buyer-form-container d-flex flex-column flex-lg-row">
                    <div className="buyer-form-container-field d-flex flex-column">
                      <p>Ciudad</p>
                      {errors?.city && values?.city !== "" ? (
                        <CheckNok />
                      ) : !errors.city && values?.city !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="city"
                        className={
                          errors?.city && values?.city !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.city && values?.city !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="city"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.city && touched.city ? (
                        <ErrorMessage
                          name="city"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                    <div className="buyer-form-container-field d-flex flex-column">
                      <p>Provincia</p>
                      {errors?.province && values?.province !== "" ? (
                        <CheckNok />
                      ) : !errors.province && values?.province !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="province"
                        className={
                          errors?.province && values?.province !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.province && values?.province !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="province"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.province && touched.province ? (
                        <ErrorMessage
                          name="province"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="buyer-form-container d-flex flex-column flex-lg-row">
                    <div className="buyer-form-container-field buyer-form-container-field-one d-flex flex-column">
                      <p>País</p>
                      {errors?.country && values?.country !== "" ? (
                        <CheckNok />
                      ) : !errors.country && values?.country !== "" ? (
                        <CheckOk />
                      ) : (
                        <Asterisk />
                      )}
                      <Field
                        id="country"
                        className={
                          errors?.country && values?.country !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.country && values?.country !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="country"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.country && touched.country ? (
                        <ErrorMessage
                          name="country"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="buyer-form-container d-flex flex-column flex-lg-row">
                    <div className="buyer-form-container-field buyer-form-container-field-one d-flex flex-column">
                      <p>Comentarios adicionales</p>
                      {errors?.other && values?.other !== "" ? (
                        <CheckNok />
                      ) : !errors.other && values?.other !== "" ? (
                        <CheckOk />
                      ) : null}
                      <Field
                        id="other"
                        className={
                          errors.other && values?.other !== ""
                            ? "buyer-form buyer-form-error"
                            : !errors.other && values?.other !== ""
                            ? "buyer-form buyer-form-ok"
                            : "buyer-form"
                        }
                        type="text"
                        name="other"
                        placeholder=""
                      />
                      {/* Email Errors */}
                      {errors?.other && touched.other ? (
                        <ErrorMessage
                          name="other"
                          component="div"
                        ></ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                </div>
                <CheckoutCheckboxes
                  termsCheckbox={termsCheckbox}
                  setTermsCheckbox={setTermsCheckbox}
                />
              </div>
              <div className="checkout-bottom-right d-flex flex-column">
                <div className="checkout-bottom-right-box d-none d-lg-flex flex-column">
                  <h3>Vas a revelar</h3>
                  <hr></hr>
                  <h4>{codes?.length + " Fancy Monas"}</h4>
                  <h4>{codes?.length + " Impresión fine art"}</h4>
                  <h4>
                    {codes?.filter((item) => item.printed === true)?.length
                      ? "1 Display 10”"
                      : "0 Display 10”"}
                  </h4>
                </div>
                <div className="checkout-bottom-right-buttons">
                  {isValid &&
                  values?.name &&
                  values?.surname &&
                  values?.email &&
                  values?.phone &&
                  values?.address &&
                  values?.number &&
                  values?.zip &&
                  values?.province &&
                  values?.zip &&
                  values?.city &&
                  values?.country &&
                  termsCheckbox ? (
                    <button className="d-flex flex-row justify-content-center align-items-center">
                      Comenzar revelado
                    </button>
                  ) : (
                    <button
                      disabled
                      className="d-flex flex-row justify-content-center align-items-center"
                    >
                      Comenzar revelado
                    </button>
                  )}
                  <button
                    className="d-flex flex-row justify-content-center align-items-center"
                    onClick={() => backScreen()}
                  >
                    Volver
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}
