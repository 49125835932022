import PaypalIcon from "../icons/PaypalIcon";
import TCIcon from "../icons/TcIcon";
import TransferIcon from "../icons/TransferIcon";
import CriptoIcon from "../icons/CriptoIcon";
import CashIcon from "../icons/CashIcon";
import "../../styles/comercialCheckout.scss";

const TotalDetail = ({
  qtyMona,
  qtyDisplay,
  totalMona,
  totalDisplay,
  total,
  paymentMethod,
}) => {
  const getPaymentMethod = (key) => {
    switch (key) {
      case "CARD":
        return (
          <>
            <PaypalIcon width={25} height={25} />
            <span className="name-method">PayPal</span>
          </>
        );
      case "efectivo":
        return (
          <>
            <CashIcon width={25} height={25} color={"#fff"} />
            <span className="name-method">Efectivo</span>
          </>
        );
      case "transferencia":
        return (
          <>
            <TransferIcon width={25} height={25} color={"#fff"} />
            <span className="name-method">Transferencia</span>
          </>
        );
      case "tc-en-pasos":
        return (
          <>
            <TCIcon width={25} height={25} color={"#fff"} />
            <span className="name-method">TC en pasos</span>
          </>
        );
      case "crypto":
        return (
          <>
            <CriptoIcon width={25} height={25} color={"#fff"} />
            <span className="name-method">Crypto USDT</span>
          </>
        );

      default:
        break;
    }
  };

  return (
    <div id="totalDetail">
      <div className="total-footer">
        {paymentMethod && (
          <div className="total-footer-payment-method">
            {getPaymentMethod(paymentMethod)}
          </div>
        )}
        <span className="total-footer-col1">
          <strong>Resumen de la compra </strong>
          <br />
          {qtyMona} FANCY MONA x<strong> USD {totalMona}</strong> / {qtyDisplay}{" "}
          DISPLAY x <strong>USD {totalDisplay}</strong>
        </span>
        <span className="total-footer-col2">
          TOTAL <strong>{total} USD</strong>
        </span>
      </div>
    </div>
  );
};

export default TotalDetail;
