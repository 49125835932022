import "./TransferMethod.scss";
import ZelleIcon from "../../components/icons/ZelleIcon";

const TransferMethod = () => {
  return (
    <div className="transfer-method">
      <div className="transfer-rows">
        <div className="content">
          <div className="info">
            <img width={45} src="/images/argentina.png" alt="argentina" />

            <h6 className="country-account">CUENTA ARGENTINA</h6>
            <div>
              <h6>Banco Galicia</h6>
              <h6>Razón Social IGMAPA SAS</h6>
              <h6>
                CUIT: <strong>30-71757498-9</strong>
              </h6>
              <h6>
                Cuenta Corriente en Pesos N°:<strong> 0001901-5 360-8</strong>
              </h6>
              <h6>
                CBU Nro: <strong>0070360220000001901580</strong>
              </h6>
              <hr />
              <h6>Cuenta Galicia USD</h6>
              <h6>
                Razón Social: <strong>IGMAPA SAS</strong>
              </h6>
              <h6>
                CUIT: <strong>30-71757498-9</strong>
              </h6>
              <h6>
                Número de cuenta: <strong>9750320-2 360-4</strong>
              </h6>
              <h6>
                CBU:<strong> 0070360231009750320246</strong>
              </h6>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="info">
            <img width={45} src="/images/usa.png" alt="usa" />

            <h6 className="country-account">CUENTA ESTADOS UNIDOS</h6>
            <div>
              <h6>JPMorgan Chase Bank, N.A.,</h6>
              <h6>
                Número de cuenta <strong>786165107</strong>
              </h6>
              <h6>
                Número de Ruta <strong>267084131</strong>
              </h6>
              <h6>
                Swift Code for Chase Bank:<strong> Chasus33</strong>
              </h6>
              <h6>
                ABA/Routing: <strong>021000021</strong>
              </h6>
              <hr />
              <div className="d-flex align-items-center justify-content-center">
                <ZelleIcon width={70} height={"auto"} color={"#fff"} />
                <h5 style={{ marginBottom: 0, marginLeft: "1rem" }}>
                  hello@artbag.io
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferMethod;
