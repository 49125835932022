import CheckoutHeader from "../../components/fancy-checkout/CheckoutHeader";
import BasicInfo from "../../components/fancy-checkout/BasicInfo";
import PersonalInfo from "../../components/fancy-checkout/PersonalInfo";
import "../../styles/fancy-checkout.scss";

const Step2 = () => {
  return (
    <div className="checkout__form">
      <div className="checkout__form-text">
        <div className="info">
          <CheckoutHeader
            title={"Completa tus datos"}
            subtitle={"Añade tu información personal y de contacto"}
            isEditable={false}
          />
          <hr />
        </div>
        <BasicInfo />
        <div className="info mt-5">
          <CheckoutHeader
            title={"Dirección de envío"}
            subtitle={
              <>
                <strong>Los envíos son gratis dentro de Argentina.</strong>
                <br />
                Los envíos al exterior tienen un costo que queda a cargo del
                comprador
              </>
            }
            isEditable={false}
          />
          <hr />
        </div>
        <PersonalInfo />
      </div>
    </div>
  );
};

export default Step2;
