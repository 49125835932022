import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { authenticateUser } from "../../services/axiosService";

export default function AuthenticationPage() {
  const { setToken } = React.useContext(AppContext);
  const { code } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const authenticateUserAsync = async () => {
      try {
        const {
          data: { accessToken },
        } = await authenticateUser(code);
        setToken(accessToken);
        localStorage.setItem("token", accessToken);
        navigate("/my-collection");
      } catch (err) {
        console.log("err", err);
      }
    };
    authenticateUserAsync();
  }, []);

  return null;
}
