import * as Yup from "yup";

export const checkoutValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.number().required("Phone number is required"),
  address: Yup.string().required("Address is required"),
  addressNumber: Yup.string().required("Floor is required"),
  deptNumber: Yup.string().required(),
  neighborhood: Yup.string().required("City is required"),
  state: Yup.string().required("Province is required"),
  postalCode: Yup.string().required("Postal Code is required"),
  country: Yup.string().required("Country is required"),
  additionalIndications: Yup.string(),
  multiplePrinted: Yup.bool(),
  quantityDisplays: Yup.number(),
  terms: Yup.bool()
    .oneOf([true], "You need to accept the terms and conditions")
    .required(),
});

export const comercialCheckoutValidation = Yup.object().shape(
  {
    quantity: Yup.number().required("Cantidad requerida"),
    includesPrinted: Yup.bool(),
    email: Yup.string().email("Email no válido").required("Email requerido"),
    phone: Yup.string().required("Teléfono requerido"),
    directSale: Yup.bool(),
    currency: Yup.string()
      .notRequired()
      .when("signal", {
        is: (val) => val !== undefined && val !== null && val !== "",
        then: Yup.string().required("Selecciona una moneda"),
      }),
    signal: Yup.string()
      .notRequired()
      .when("currency", {
        is: (val) => val !== undefined && val !== null && val !== "",
        then: Yup.string().required("Indica una cantidad para la señal"),
      }),
    shippingInfo: Yup.object().shape({
      name: Yup.string().required("Nombre requerido"),
      country: Yup.string(),
      postalCode: Yup.string(),
      state: Yup.string(),
      neighborhood: Yup.string(),
      address: Yup.string(),
      addressNumber: Yup.string(),
      deptNumber: Yup.string(),
      contactPhone: Yup.string(),
      multiplePrinted: Yup.bool(),
      quantityDisplays: Yup.number(),
      additionalIndications: Yup.string(),
    }),
    sellerInfo: Yup.object().shape({
      paymentMethod: Yup.string().required("Método de pago requerido"),
    }),
    terms: Yup.bool()
      .oneOf([true], "You need to accept the terms and conditions")
      .required(),
  },
  [["currency", "signal"]]
);
