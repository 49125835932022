import styled from "styled-components";

const StyledHr = styled.hr`
  margin: 4px 0;
  border: ${(props) =>
    props.color === "black" ? "1px solid black" : "1px solid white"};
`;

const StyledH6 = styled.h6`
  color: ${(props) => (props.color === "black" ? "#000" : "#fff")};
  font-family: ${(props) => props.theme.font.roboto};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
`;

const HeadingHr = ({ heading, color = "white" }) => {
  return (
    <div className="d-flex flex-column w-100">
      <StyledH6 color={color}>{heading}</StyledH6>
      <StyledHr color={color} />
    </div>
  );
};

export default HeadingHr;
