import MasterCardIcon from "../../components/icons/MasterCardIcon";
import VisaIcon from "../../components/icons/VisaIcon";
import AmericanExpressIcon from "../../components/icons/AmericanExpressIcon";
import GaliciaIcon from "../../components/icons/GaliciaIcon";
import "./tc.scss";

const TCenPasos = () => {
  return (
    <div className="tc-en-pasos">
      <span>3 cuotas sin intereses con</span>
      <div className="content">
        <GaliciaIcon width={294} height={"auto"} color={"#fff"} />
        <p className="mt-2">
          Forma de pago presencial a través de terminal de pago{" "}
          <strong>Payway</strong>,<br /> no aplica para compras a través de
          Mercado de Pago.
        </p>
      </div>
      <div className="icons-payments mt-5">
        <AmericanExpressIcon width={137} height={"auto"} color={"#fff"} />
        <VisaIcon width={100} height={"auto"} color={"#fff"} />
        <MasterCardIcon width={208} height={"auto"} color={"#fff"} />
      </div>
    </div>
  );
};

export default TCenPasos;
