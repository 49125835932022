import { useContext, useState } from "react";
import { BadgeCheckout } from "../../components/fancy-checkout/Badge";
import Check from "../../components/icons/Check";
import {
  DescriptionCheckout,
  SubTitleCheckout,
  Title2Checkout,
  TitleCheckout,
} from "../../components/fancy-checkout/Title";
import { Counter } from "../../components/fancy-checkout/Counter";
import { Tooltip } from "../../components/shared/Tooltip";
import { CartContext } from "../../context/Cart";
import ImageDetail from "./ImageDetail";
import Details from "./Details";
import CheckIcon from "../icons/CheckIcon";
import "../../styles/fancy-checkout.scss";

const Step1 = () => {
  const [individualChecked, setIndividualChecked] = useState(false);
  const [multipleChecked, setMultipleChecked] = useState(false);
  const basePrice = "1500";
  const basePriceDisplay = "500";
  const {
    addItem,
    removeItem,
    count,
    addDisplay,
    removeDisplay,
    countDisplay,
    multipleDisplay,
  } = useContext(CartContext);

  /* const handleIncludePrinted = () => {
    setIncludesPrinted(!includesPrinted);
    isPrintedActive();
  }; */

  const handleCheckboxChange = (checkboxName) => {
    if (checkboxName === "individual") {
      setIndividualChecked(true);
      setMultipleChecked(false);
    } else if (checkboxName === "multiple") {
      multipleDisplay();
      setIndividualChecked(false);
      setMultipleChecked(true);
    }
  };

  return (
    <div className="checkout__info">
      <ImageDetail src={"/images/fancy_checkout/digital-art-checkout.svg"} />
      <div className="checkout__info-text">
        <BadgeCheckout icon={<Check />}>
          <span>Incluye print exclusivo</span>
        </BadgeCheckout>
        <div className="info">
          <TitleCheckout>Llévate tu Fancy Mona</TitleCheckout>
          <DescriptionCheckout>
            Adquiere tu mona digital y única, generada por un algoritmo
            construído por Edgardo Giménez. Cada mona generada por el algoritmo
            es única.
          </DescriptionCheckout>
          <div className="counter">
            <Counter removeItem={removeItem} addItem={addItem} count={count} />
            <Title2Checkout>{basePrice} USD</Title2Checkout>
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="d-flex flex-column">
          <Title2Checkout>Vive la experiencia completa</Title2Checkout>
          <SubTitleCheckout>
            Suma extras a tu Fancy Mona y disfruta la colección de múltiples
            maneras.
          </SubTitleCheckout>
          <hr />
          <section className="mt-4">
            <Details
              title="Print Fine Art"
              src={"/images/fancy_checkout/print-mona.png"}
            >
              <div className="details__info_checkbox">
                <div className="checkbox d-flex align-items-center">
                  <div className="checkbox-printed-selected">
                    <CheckIcon width="100%" height="auto" color={"#0EAC00"} />
                  </div>
                  {/* <label>
                    <input
                      type="checkbox"
                      checked={includesPrinted}
                    />
                  </label> */}
                  <strong>
                    <p className="mr-2">
                      <span className="pr-2">0 USD</span>(incluido con la compra
                      de tu Fancy Mona)
                    </p>
                  </strong>
                </div>
                <DescriptionCheckout>
                  Impresión de original de 60 x 60 cm. + 10 cm. de margen blanco
                  perimetral, sobre papel Hahnemühle Photo Rag UltraSmooth, 300
                  gr. (100% Cotton Linters - Archival, acid & lignin free).
                </DescriptionCheckout>
                <small className="details-caption text-nowrap">
                  <strong>* Marco no incluido.</strong>
                </small>
              </div>
            </Details>
          </section>
          <section className="mt-5 display-section">
            <Details
              title="Display de 10''"
              src={"/images/fancy_checkout/display.png"}
            >
              <div className="d-flex align-items-center details-counter">
                <Counter
                  addItem={addDisplay}
                  removeItem={removeDisplay}
                  count={countDisplay}
                />
                <Title2Checkout>{basePriceDisplay} USD</Title2Checkout>
              </div>
              <SubTitleCheckout>Posibilidad de visualización</SubTitleCheckout>
              <div className="details__info-tooltip">
                <label className="display-options">
                  <input
                    type="checkbox"
                    disabled={countDisplay <= 0}
                    checked={
                      (countDisplay >= 1 && !multipleChecked) ||
                      individualChecked
                    }
                    onChange={() => handleCheckboxChange("individual")}
                  />{" "}
                  INDIVIDUAL
                </label>
                {" | "}
                <label className="display-options">
                  <input
                    type="checkbox"
                    disabled={countDisplay <= 0}
                    checked={multipleChecked}
                    onChange={() => handleCheckboxChange("multiple")}
                  />{" "}
                  MÚLTIPLE
                </label>
                <Tooltip
                  className="d-flex"
                  placement="right"
                  text="INDIVIDUAL: Una pantalla por Fancy Mona comprada. MÚLTIPLE: Una única pantalla con todas las Fancy Monas compradas."
                />
              </div>
              <small className="text-nowrap details-caption">
                <strong>
                  * Esto será coordinado posterior a la venta con nuestro equipo
                  comercial.
                </strong>
              </small>
            </Details>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Step1;
