import React from "react";
import { Button } from "../shared/Button";

const ResumeCardMobile = ({
  next,
  disabled,
  cancel,
  type,
  nextLabel,
  cancelLabel,
}) => {
  return (
    <>
      <Button fullwidth onClick={next} type={type} disabled={disabled}>
        {nextLabel}
      </Button>
      <Button fullwidth color="secondary" onClick={cancel}>
        {cancelLabel}
      </Button>
    </>
  );
};

export default ResumeCardMobile;
