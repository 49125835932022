import styled from "styled-components";
import { Button } from "../shared/Button";

const StyledResumeCard = styled.div`
  border-radius: 8px;
  border: 1px solid #aed3ff;
  background: #f9fcff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  flex-shrink: 0;
  padding: 38px 32px 32px 32px;
  height: 100%;
`;

const ResumeCard = ({
  children,
  next,
  cancel,
  type = "button",
  disabled,
  nextLabel,
  cancelLabel,
}) => {
  return (
    <>
      <StyledResumeCard>{children}</StyledResumeCard>
      <div className="mt-3 resume-card-buttons">
        <Button fullwidth onClick={next} type={type} disabled={disabled}>
          {nextLabel}
        </Button>
        <Button fullwidth color="secondary" onClick={cancel}>
          {cancelLabel}
        </Button>
      </div>
    </>
  );
};

export { ResumeCard };
