import React from "react";

export default function NotFoundPage() {

  return (
    <div id="notfound-page">
      <h2>Página no encontrada</h2>
    </div>
  )

}
