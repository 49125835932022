import React, { Fragment, useState } from "react";
import { AppContext } from "../../App.js";
import { suscribeToWhilest } from "../../services/axiosService.js";


function Whitelist() {
    const [email, setEmail] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const { termsModal, setTermsModal } = React.useContext(AppContext);

    document.addEventListener("keyup", e => {
        // if we press the ESC
        if (e.key === "Escape" && document.querySelector(".modal-background")) {
            setTermsModal(false);
        }
    }); 

    document.addEventListener("click", e => {
        if (e.target === document.querySelector(".modal-background")) {
            setTermsModal(false);
        }
    });
    let handleOnClick = () => {
        setTermsModal(false);
    }
    let handlSubscribeWhitelist = async (e) => {
        e.preventDefault();
        await suscribeToWhilest(email)
            .then((response) => setIsVisible(true))
            .catch((error) => console.log(error));
    }


    let handleChange = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    }

    return (
        <Fragment>
            {
                !isVisible ? <div id="modal-term-section" className={termsModal ? "modal-background" : "d-none"}>
                    <div className="modal-term-container d-flex flex-column justify-content-center">

                        <div className="Close-Container position-relative mb-4"><div className="IconClose position-absolute end-0" onClick={handleOnClick}></div></div>
                        <div className="modalIcon mx-auto mb-5"></div>
                        <div className="px-4">
                            <h5 className="text-center modal-title mb-3">¡Hicimos Sold Out! Anotate para el segundo drop</h5>
                            <p className="mb-4 modal-text">Si te interesa comprar en el segundo lanzamiento de Fancy Monas, ingresá tu correo electrónico y te avisaremos cuando salga el nuevo drop.</p>
                        </div>
                        <form onSubmit={(e) => handlSubscribeWhitelist(e)}>
                        <div className="wrapper">
                            <input
                                onChange={(e) => { handleChange(e) }}
                                name="email"
                                type="text"
                                className="form-control modal-input mb-3"
                                placeholder="Email"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                            />
                        </div>
                            <button
                                className="btn btn-dark modal-button"
                                type="submit"
                                id="button-addon2"
                            >
                                ✉️  Enviar
                            </button>

                        </form>
                    </div>
                </div> : <div id="modal-term-section" className={termsModal ? "modal-background" : "d-none"}>
                    <div className="modal-term-container d-flex flex-column justify-content-center">

                        <div className="Close-Container position-relative mb-4"><div className="modalClose position-absolute end-0" onClick={handleOnClick}></div></div>
                        <div className="modalIconSuccess mx-auto mb-5"></div>
                        <div className="px-4">
                            <h5 className="text-center modal-title mb-3">¡Correo registrado!</h5>
                            <p className="mb-4 modal-text">Hemos registrado tu correo para avisarte cuando haya novedades de Fancy Monas. Estate atento a tu casilla para enterarte del próximo drop!</p>
                        </div>



                        <button
                            className="btn btn-dark modal-button"
                            type="submit"
                            id="button-addon2"
                            onClick={handleOnClick}
                        >
                            Cerrar
                        </button>


                    </div>
                </div>
            }
        </Fragment>


    );
}

export default Whitelist;