import React from "react";

function PenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g>
        <path
          fill="#000"
          d="M14.666 4.827a.666.666 0 00-.193-.473l-2.827-2.827a.667.667 0 00-.473-.193.666.666 0 00-.473.193L8.813 3.414 1.527 10.7a.667.667 0 00-.193.473V14a.667.667 0 00.666.666h2.827a.666.666 0 00.507-.193l7.246-7.286 1.893-1.853a.793.793 0 00.146-.22.659.659 0 000-.16.455.455 0 000-.094l.047-.033zM4.554 13.333H2.667v-1.886l6.62-6.62 1.886 1.887-6.62 6.62zm7.56-7.56l-1.888-1.886.947-.94 1.88 1.88-.94.947z"
        ></path>
      </g>
    </svg>
  );
}

export default PenIcon;
