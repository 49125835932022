import * as React from "react";

function Check(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={15}
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#1D2734"
          d="m7.239.823.562.73a.927.927 0 0 0 .998.325l.885-.26a.929.929 0 0 1 1.192.868l.027.921a.93.93 0 0 0 .615.851l.867.31a.93.93 0 0 1 .457 1.404l-.52.761a.937.937 0 0 0 0 1.053l.52.76a.933.933 0 0 1-.457 1.404l-.867.31a.926.926 0 0 0-.615.851l-.027.922a.932.932 0 0 1-.771.892.926.926 0 0 1-.421-.025l-.885-.259a.929.929 0 0 0-.998.325l-.562.73a.932.932 0 0 1-1.148.267.932.932 0 0 1-.326-.267l-.56-.73a.935.935 0 0 0-1-.325l-.885.26a.926.926 0 0 1-1.193-.868l-.024-.921a.935.935 0 0 0-.618-.851l-.867-.31a.93.93 0 0 1-.456-1.405l.52-.76a.93.93 0 0 0 0-1.053l-.52-.76a.933.933 0 0 1 .46-1.4l.867-.31a.933.933 0 0 0 .617-.85l.025-.922a.934.934 0 0 1 .772-.891.929.929 0 0 1 .42.024l.885.259a.929.929 0 0 0 1-.324l.56-.73a.93.93 0 0 1 1.47-.006Z"
        />
        <path
          fill="#F9FCFF"
          d="M9.377 5.224a.504.504 0 0 0-.689.037L5.95 8.005a.322.322 0 0 1-.455 0L4.31 6.82a.495.495 0 0 0-.625-.074.487.487 0 0 0-.074.75l1.884 1.888a.322.322 0 0 0 .455 0l3.444-3.454a.486.486 0 0 0-.017-.705Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width={13} height={13.6} y={0.457} fill="#fff" rx={6.5} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Check;
