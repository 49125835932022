import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";

const StyledDropdown = styled(Dropdown)`
  position: relative;
  display: inline-block;
  color: #000;
`;
const StyledDropdownToggle = styled(Dropdown.Toggle)`
  display: inline-flex;
  height: 34px;
  padding: 4px 10px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  color: #000;
  font-family: ${(props) => props.theme.font.roboto};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1px;
  border: 0;
  text-transform: uppercase;

  &.dropdown-toggle::before {
    content: "";
  }

  &:hover {
    background-color: white;
    color: black;
  }
  &.btn.show {
    color: black;
    background-color: white;
  }
  &.btn:first-child:active {
    background-color: white;
    color: black;
  }
  &.btn:focus-visible {
    background-color: white;
    color: black;
    border: 0;
  }

  .custom-dropdown-gap {
    gap: 10px;
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  height: 197px;
  flex-shrink: 0;
  position: absolute;
  top: 125%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
  padding: 8px 0;
  min-width: 160px;
  z-index: 1;
  border: 0;
  color: #000;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  background-color: #fff;
  padding: 0px 16px;
  cursor: pointer;
  color: #000 !important;
  text-align: right;
  font-family: ${(props) => props.theme.font.roboto};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-bottom: 1px solid black;
  padding: 24px 10px;
  &:last-child {
    border-bottom: 0px;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

// Componente de Dropdown personalizado
const CustomDropdown = ({ items, startIcon, endIcon, text }) => {
  return (
    <StyledDropdown>
      <StyledDropdownToggle id="dropdown-basic">
        {startIcon} {text} {endIcon}
      </StyledDropdownToggle>

      <StyledDropdownMenu>
        {items.map((item, index) => (
          <StyledDropdownItem key={index} onClick={item.click}>
            {item.name}
          </StyledDropdownItem>
        ))}
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

export default CustomDropdown;
