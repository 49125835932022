import { useEffect, useRef, useState } from 'react';

const FilterCarousel = () => {
  const carouselRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const handlePrevButtonClick = () => {
    carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
  };

  const handleNextButtonClick = () => {
    carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
  };

  useEffect(() => {
    const handleScroll = () => {
      const carouselElement = carouselRef.current;
      const isScrollable = carouselElement.scrollWidth > carouselElement.clientWidth;
      setIsScrollable(isScrollable);
    };

    const checkScroll = () => {
      handleScroll();

      const observer = new MutationObserver(handleScroll);
      observer.observe(carouselRef.current, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    };

    carouselRef.current?.addEventListener('scroll', handleScroll);
    checkScroll();

    return () => {
      carouselRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    carouselRef,
    isScrollable,
    handlePrevButtonClick,
    handleNextButtonClick,
  };
};

export default FilterCarousel;
