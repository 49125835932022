import React from "react";

export default function Spinner() {

  return (
    <div className="spinner-container d-flex justify-content-center align-items-center">
      <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )

}
